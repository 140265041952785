import { Component, OnInit } from '@angular/core';
import { Platform, NavController, AlertController, LoadingController, MenuController, Events, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';
@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.page.html',
  styleUrls: ['./select-user.page.scss'],
})
export class SelectUserPage implements OnInit {
    user:any = [];
    users_list:any = [];
    userready: boolean = false;
    userpage: any = 1;
    count:number = -1;

    selected_users:any = [];

  constructor(
    public modalCtrl: ModalController,
    public serviceForAllService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public storage: Storage,
  ) {
   }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.storage.get('user').then((val) => {
        console.log('userData: ', val);
        if (val != null) {
          this.user = val;
          this.getAllUsers();
        }
      });
  }

  dismissModal(){
    this.modalCtrl.dismiss();
  }


  getAllUsers(){
    this.userready = false;
    this.userpage=1;

    this.serviceForAllService.searchAllUser(this.user.token, this.userpage, '', '').subscribe((result) => {
        let r:any = [];
        r = result;
        this.users_list = r.users;
        this.userready = true;
        this.count = this.users_list.length;
      }, (err) => {
        console.log(err);
        this.userready = true;
        this.count = 0;
      });
  }


  loadMore(event) {
    this.userpage++;
    this.serviceForAllService.searchAllUser(this.user.token, this.userpage, '', '').subscribe((result) => {
        console.log(result);
        let r:any = [];
        r = result;

        this.users_list = [...this.users_list, ...r.users];
        if(r.users.length==0){
          this.userpage--;
        }
        event.target.complete();
      }, (err) => {
        console.log(err);
        this.userpage--;
        event.target.complete();
      });
  }

  selectUser(userdata){
      if(userdata){
        this.selected_users.push(userdata);
        this.modalCtrl.dismiss({usersData: this.selected_users});
      }
  }

}

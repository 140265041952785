import { Component, OnInit, NgZone, ViewChildren } from '@angular/core';
import { AlertController, LoadingController, NavController, MenuController, ModalController, ToastController, NavParams, IonInput } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { WelcomePage } from '../welcome/welcome.page';
import * as $ from 'jquery';
import { InAppBrowser, InAppBrowserOptions, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { setFlagsFromString } from 'v8';
import { PrivacyRightsPage } from '../privacy-rights/privacy-rights.page';
import { PasswordValidator } from '../validators/password.validator';
import { CountryListPage } from './../country-list/country-list.page';
import { TermsPage } from '../terms/terms.page';

@Component({
    selector: 'app-signin-signup',
    templateUrl: './signin-signup.page.html',
    styleUrls: ['./signin-signup.page.scss'],
})
export class SigninSignupPage implements OnInit {
    @ViewChildren('ioninput') inputElement: IonInput;
    loginForm: FormGroup;
    signupForm: FormGroup;
    matching_passwords_group: FormGroup;
    response: any;
    loading: any;
    isLoggedIn: boolean = false;
    deviceData: any = [];
    deviceToken: any;
    setting: any;
    //   FB_APP_ID: number = 2313560265416089;

    verified_phone_with_country_code: any = '';
    verified_email: any = '';
    is_phone_verified: boolean = false;

    public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
    // public myColors = ['#FF3636', '#F1CB67', '#59A800', '#000000', '#000000'];
    // public strengthLabels = ['(weak)', '(okay)', '(strong)', '(gggg)', '(ffff)'];
    public strengthLabels = ['(weak)', '(weak)', '(okay)', '(strong)', '(strong)'];
    phone_country_code_label: any = '';

    activeSocial: any = 'gmail';
    // validation_messages = {
    //     'email': [{ type: 'required', message: 'Email is required.'}],
    //     'password': [{ type: 'required', message: 'Password is required.'}]
    // };
    validation_messages = {
        'first_name': [
            { type: 'required', message: 'First Name is required.' },
        ],
        'last_name': [
            { type: 'required', message: 'Last Name is required.' },
        ],
        'phone': [
            { type: 'required', message: 'Mobile is required.' },
        ],
        'email': [
            { type: 'required', message: 'Email is required.' },
            { type: 'pattern', message: 'Enter a valid email.' }
        ],
        'password': [
            { type: 'required', message: 'Password is required.' },
            { type: 'minlength', message: 'Password must be at least 5 characters long.' }
        ],
        'confirm_password': [
            { type: 'required', message: 'Confirm password is required' }
        ],
        'matching_passwords': [
            { type: 'areNotEqual', message: 'Password mismatch' }
        ]
    };

    passwordType: string = 'password';
    passwordIcon: string = 'eye-off';

    //   checkOutPageText = "Place Your Order";
    public headerHexColor = "#51688F";
    fb_access_token: any = [];
    google_access_token: any = [];
    isIOS: boolean = true;

    loginInfo: any = [];
    page_type: string = "signin";

    //
    emailVerified: boolean = false;
    password: any = '';
    strengthVal: any = 0;

    //Email Verification Variables
    sentcode: any = '';
    c1: any;
    c2: any;
    c3: any;
    c4: any;
    c5: any;
    c6: any;

    //Forget Password
    forgot_form: FormGroup;
    email_validation_messages = {
        'user_login': [
            { type: 'required', message: 'Email is required.' },
            { type: 'pattern', message: 'Enter a valid email.' }
        ]
    };

    countries: any = [];
    phone_instructions: any = [];



    constructor(
        public storage: Storage,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        // private googlePlus: GooglePlus,
        public navController: NavController,
        // private fb: Facebook,
        private navParams: NavParams,
        public webServices: ServiceForAllService,
        public events: Events,
        private router: Router,
        private faio: FingerprintAIO,
        public menu: MenuController,
        public modalController: ModalController,
        private InAppBrowser: InAppBrowser,
        public zone: NgZone,
        public toastController: ToastController
    ) {
        this.menu.enable(false);
        this.storage.remove('guestuser');
        this.events.publish('isGuest', 0);
        this.getCountries();

        this.matching_passwords_group = new FormGroup({
            'password': new FormControl('', Validators.compose([Validators.minLength(5), Validators.required])),
            'confirm_password': new FormControl('', Validators.required)
        }, (formGroup: FormGroup) => {
            return PasswordValidator.areNotEqual(formGroup);
        });

        this.signupForm = new FormGroup({
            'first_name': new FormControl('', Validators.compose([Validators.required])),
            'last_name': new FormControl('', Validators.compose([Validators.required])),
            'phone': new FormControl('', Validators.compose([Validators.required])),
            'phone_country_code': new FormControl('', Validators.compose([Validators.required])),
            'email': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])),
            'matching_passwords': this.matching_passwords_group,
        });

        this.forgot_form = new FormGroup({
            'user_login': new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ]))
        });
    }


    getCountries() {
        this.storage.get('countries').then((val) => {
            if (val != null) {
                this.countries = val;
                console.log('Storage->countries: ', this.countries);
            } else {
                let sendData = {}
                this.webServices.get_countries(sendData).subscribe((response) => {
                    console.log("response:", response);
                    if (response['status'] == 'ok') {
                        this.countries = response['countries'];
                        console.log("DB->countries:", this.countries);
                    }
                })
            }
        });
    }

    hideShowPassword() {
        this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
        this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
    }

    GetSetting() {
        this.webServices.GetSetting().subscribe(res => {
            this.setting = res;
            this.webServices.setSetting({
                secret_key: this.setting.secret_key,
                publishable_key: this.setting.publishable_key,
                about: this.setting.about
            });
        })
    }

    ngOnInit() {
        this.storage.remove('guestuser');
        this.events.publish('isGuest', 0);

        this.loginForm = new FormGroup({
            'email': new FormControl('', Validators.compose([
                Validators.required
            ])),
            'password': new FormControl('', Validators.compose([
                Validators.required
            ]))
        });


    }

    //[START]=> Close Model
    closeModel(model_name) {
        this.modalController.dismiss({
            'dismissed': true,
            'open_model': model_name,
        });
    }
    //[END]=> Close Model

    ionViewWillLeave() {
        this.menu.enable(true);

        // this.storage.remove('guestuser');
        // this.events.publish('isGuest',0);
        // this.storage.set('guestuser', 1);
    }

    ionViewWillEnter() {
        console.log("this.navParams.data:", this.navParams.data);
        this.page_type = this.navParams.data.page_type;
        // $(document).ready(function(){
        //     let count=9;
        //     $('.social img').each(function(){
        //         $(this).css({'z-index':count-1,'position':'relative'});
        //         count--;
        //         var self=$(this);
        //         $('.social img').on('click',function(){
        //             $(self).css({'z-index':'','position':'relative'});
        //             $(this).css({'z-index':9,'position':'relative'}); 
        //         })
        //     })
        // })

        this.storage.remove('guestuser');
        this.storage.remove('user');
        this.storage.remove('cid');
        this.storage.remove('user_profile');

        // console.log(this.loginForm.valid);
        // this.loginForm.controls['email'].setValue('');
        this.menu.enable(false);
        this.GetSetting();
    }


    doLogin() {
        let email = this.loginForm.value.email;
        let password = this.loginForm.value.password;
        this.webServices.showLoader('Authenticating...');
        this.webServices.doLogin(email, password).subscribe((result) => {
            this.response = result;
            if (this.response.token) {
                this.webServices.dismissLoading();
                if (this.response) {
                    this.webServices.firebaseAuthToken(email, email);
                    this.setLoginData(this.response);
                }
            }
        }, (err) => {
            this.webServices.dismissLoading();
            let msg = 'Username or password is invalid';
            this.webServices.presentAlert(msg);
        });
    }
    dismissModal() {
        this.modalController.dismiss();
    }
    setLoginData(response) {
        this.dismissModal();
        if (response.twofactor_enabled) {
            this.enterOTP(response);
            console.log('response=================', response)
        }
        else {

            if (response.user_id) {
                this.resetTID(response.user_id);
                console.log('response:::::::::::', response)
            }
            this.storage.set('user', response);
            this.webServices.setOneSignalId(response.user_id);
            this.menu.enable(true);
            this.events.publish('userCheck:created', response);
            this.navController.navigateRoot(['/dashboard']);
        }
    }

    async enterOTP(response) {
        const alert = await this.alertCtrl.create({
            header: 'Enter OTP',
            message: 'We have sent 6 digit OTP on your phone number and email for authentication.',
            inputs: [
                {
                    name: 'otp',
                    placeholder: 'Enter OTP',
                    value: "",
                    type: 'tel'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Go',
                    handler: (data) => {
                        if (response.vcode == data.otp) {
                            if (this.response.user_id) {
                                this.resetTID(response.user_id);
                            }
                            this.storage.set('user', response);
                            this.webServices.setOneSignalId(response.user_id);
                            this.menu.enable(true);
                            this.events.publish('userCheck:created', response);
                            this.navController.navigateRoot(['/dashboard']);
                        }
                        else {
                            this.webServices.presentToast("Incorrect OTP!!");
                        }
                    }
                }
            ]
        });

        await alert.present();
    }

    goToForgotPassword(): void {
        console.log('redirect to forgot-password page');
    }

    async presentLoading(loading) {
        return await loading.present();
    }

    logout() {

    }

    resetTID(user_id) {
        this.storage.get('touch_user_id').then((touchUserId) => {
            if (touchUserId && touchUserId != null && touchUserId != '' && touchUserId != undefined) {
                if (user_id != touchUserId) {
                    this.storage.remove('touch_user_id');
                }
            }
        });
    }
    checkSetup() {
        this.storage.get('touch_user_id').then((touchUserId) => {
            if (touchUserId && touchUserId != null && touchUserId != '' && touchUserId != undefined) {
                this.showFingerprintAuthDlg(touchUserId);
            } else {
                this.webServices.presentAlert("You need to login in your account and setup the touch/face id first!");
            }
        });
    }

    public showFingerprintAuthDlg(touchUserId) {
        this.faio.isAvailable().then((result: any) => {
            console.log(result)
            this.faio.show({
                cancelButtonTitle: 'Cancel',
                description: "Scan your fingerprint please",
                disableBackup: true,
            }).then((result: any) => {
                console.log(result)
                this.getToken(touchUserId);
            }).catch((error: any) => {
                console.log(error)
                this.webServices.presentAlert("Match not found!");
            });
        }).catch((error: any) => {
            console.log(error)
        });
    }

    getToken(touchUserId) {
        this.webServices.showLoader('Authenticating...');
        this.webServices.touchLogin(touchUserId).subscribe((result) => {
            let r: any = [];
            r = result;
            this.response = r.user;
            if (this.response.token) {
                this.loading.dismiss();
                if (this.response) {
                    this.setLoginData(this.response);

                } else {
                    let msg = 'Please try again!';
                    this.webServices.presentAlert(msg);
                }
            }
        }, (err) => {
            this.loading.dismiss();
            let msg = 'Please try again!';
            this.webServices.presentAlert(msg);
        });
    }

    async firstTimePopup() {
        const modal = await this.modalController.create({
            component: WelcomePage,
            cssClass: 'my-custom-class',
            componentProps: {
            }
        });
        modal.onDidDismiss().then((dataReturned) => {
            console.log('return', dataReturned);
        });
        this.storage.set('firsttime', '1');
        return await modal.present();
    }
    setActive__(btnname) {
        this.activeSocial = btnname;
    }

    async setGuestConfirm() {
        console.log("e here....");

        const modal = await this.modalController.create({
            component: WelcomePage,
            cssClass: 'my-custom-class',
            componentProps: {
            }
        });
        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned.data && dataReturned.data == 'guest') {
                this.setGuest();
            }
        });
        this.storage.set('FIRST_TIME_OPEN', '1');
        return await modal.present();
    }

    setGuest() {
        console.log("I AM A GUEST!!");
        this.storage.set('guestuser', 1);
        setTimeout(() => {
            this.events.publish('isGuest', 1);
        }, 1000);

        this.router.navigate(['/dashboard']);
    }

    facebook_login() {
        let options: InAppBrowserOptions = {
            statusbar: {
                color: this.headerHexColor
            },
            title: {
                color: '#ffffff',
                // staticText  : this.checkOutPageText,
                showPageTitle: false
            },
            closeButton: {
                wwwImage: 'assets/close.png',
                align: 'right',
                event: 'closePressed'
            },
            backButton: {
                wwwImage: 'assets/back.png',
                align: 'left'
                //event: 'closePressed'
            },
            backButtonCanClose: true,
            location: "no",
            footercolor: "#c7fedc",
            hidenavigationbuttons: "yes",
            hideurlbar: "yes",
            toolbarcolor: "#c7fedc",
            //hidden: 'yes',
            clearcache: "yes",
            clearsessioncache: 'yes'
        };
        const b: InAppBrowserObject = this.InAppBrowser.create('https://app.tagster.com/auth/facebook/', "_blank", options);
        let orderPlaced = false;
        b.on('loadstart').subscribe(res => {
            if (res.url.indexOf('access_token') != -1) {
                let p = this.getParams(res.url);
                orderPlaced = true;
                b.close();
                if (p['access_token'] != '') {
                    this.fb_access_token = p['access_token'];
                    let sendData = {
                        fb_access_token: p['access_token'],
                    }
                    this.login_with_social_media('facebook', sendData);
                } else {
                    this.webServices.presentAlert("Sorry, Facebook access token is not founds.");
                }
            } else if (res.url.indexOf('error_instagram') != -1) {
                b.close();
            }
        });
        b.on('loadstop').subscribe(res => {
            console.log('loadstop');
        });
        b.on('exit').subscribe(res => { });
    }

    getParams = function (url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    // By Su
    login(platform) {
        let login_url = '';
        let page_name = "";
        if (platform == 'instagram') {
            login_url = 'https://app.tagster.com/auth/instagram/';
            page_name = 'success_instagram';
        } else if (platform == 'twitter') {
            login_url = 'https://app.tagster.com/auth/twitter/';
            page_name = 'success_twitter';
        } else if (platform == 'google') {
            login_url = 'https://app.tagster.com/auth/google/';
            page_name = 'success_google';
        }
        else {
            return false;
        }
        let options: InAppBrowserOptions = {
            statusbar: {
                color: this.headerHexColor
            },
            title: {
                color: '#ffffff',
                // staticText  : this.checkOutPageText,
                showPageTitle: false
            },
            closeButton
                : {
                wwwImage: 'assets/close.png',
                align: 'right',
                event: 'closePressed'
            },
            backButton: {
                wwwImage: 'assets/back.png',
                align: 'left'
                //event : 'closePressed'
            },
            backButtonCanClose: true,
            location: "no",
            footercolor: "#c7fedc",
            hidenavigationbuttons: "yes",
            hideurlbar: "yes",
            toolbarcolor: "#c7fedc",
            //hidden    : 'yes',
            clearcache: "yes",
            clearsessioncache: 'yes'
        };
        const b: InAppBrowserObject = this.InAppBrowser.create(login_url, "_blank", options);
        let orderPlaced = false;
        b.on('loadstart').subscribe(res => {
            console.log("INAppBrowserRes:", res);

            let orderPlaced = false;
            if (res.url.indexOf(page_name) != -1) {
                let query_string = this.getParams(res.url);
                console.log("query_string:", query_string);
                b.close();
                if (platform == 'twitter') {
                    if (query_string['oauth_token'] != '' && query_string['oauth_token_secret'] != '') {
                        let sendData = {
                            oauth_token: query_string['oauth_token'],
                            oauth_token_secret: query_string['oauth_token_secret'],
                        }
                        this.login_with_social_media(platform, sendData);
                    } else {
                        this.webServices.presentAlert("Sorry, Twitter access token is not founds.");
                    }
                }
                else if (platform == 'instagram') {
                    if (query_string['access_token'] != '' && query_string['user_id']) {
                        let sendData = {
                            access_token: query_string['access_token'],
                            instagram_user_id: query_string['user_id'],
                        }
                        this.login_with_social_media(platform, sendData);
                    } else {
                        this.webServices.presentAlert("Sorry, Instagram access token is not founds.");
                    }
                } else if (platform == 'google') {
                    if (query_string['access_token'] != '') {
                        let sendData = {
                            google_access_token: query_string['access_token'],
                        }
                        this.login_with_social_media(platform, sendData);
                    } else {
                        this.webServices.presentAlert("Sorry, Google access token is not founds.");
                    }
                }
            } else if (res.url.indexOf('error') != -1) {
                b.close();
                this.webServices.presentAlert("Something went wrong. Try Again");
            }
        });
        b.on('loadstop').subscribe(res => {
            console.log('loadstop');
        });
        b.on('exit').subscribe(res => { });
    }

    login_with_social_media(platform, sendData) {
        this.webServices.showLoader("connecting with " + platform + "...");
        this.webServices.login_with_social_media(platform, sendData).subscribe((response) => {
            console.log("response:", response);
            this.webServices.dismissLoading();
            if (response['status'] == 'ok') {
                if (response['loginInfo']) {
                    this.loginInfo = response['loginInfo'];
                    this.now_login(response['is_new_user']);
                }
            } else {
                this.webServices.presentAlert(response['msg']);
            }
        }, (err) => {
            console.log("Err:", err);
            this.webServices.dismissLoading();
            this.webServices.presentAlert(err.error.msg);
        });
    }

    now_login(is_new_user) {
        console.log('loginInfo:', this.loginInfo);
        this.zone.run(() => {
            if (this.loginInfo) {
                if (is_new_user == true) {
                    if (this.loginInfo.user_id) {
                        this.resetTID(this.loginInfo.user_id);
                    }
                    this.webServices.firebaseAuthToken(this.loginInfo.email, this.loginInfo.email);
                    localStorage.setItem('remembarStatus', 'true');
                    this.storage.set('user', this.loginInfo);
                    this.webServices.setOneSignalId(this.loginInfo.user_id);
                    this.events.publish('userCheck:created', this.loginInfo);

                    let qp = {
                        source: 'signup'
                    }
                    this.navController.navigateRoot(['/dashboard', qp]);
                } else {

                    this.setLoginData(this.loginInfo);

                }
            } else {
                this.webServices.presentAlert("Unable to get logininfo.");
            }
        })
    }

    change_page(page_type) {
        this.page_type = page_type;
    }
    async presentModal() {
        const modal = await this.modalController.create({
            component: PrivacyRightsPage,
            cssClass: 'privacymodal'
        });
        return await modal.present();
    }


    //-------
    onChangePhone() {
        this.is_phone_verified = false;
        let phone_with_country_code = this.signupForm.value.phone_country_code + this.signupForm.value.phone;
        console.log("phone_with_country_code:", phone_with_country_code);
        if (this.verified_phone_with_country_code != '' && phone_with_country_code != '' && this.verified_phone_with_country_code == phone_with_country_code) {
            this.is_phone_verified = true;
        }
        console.log("verified_phone_with_country_code:", this.verified_phone_with_country_code);
        console.log("form:", this.signupForm);
    }
    onChangeEmail() {
        this.emailVerified = false;
    }

    strengthChanged(strength: number) {
        console.log("this.password:", this.password);
        console.log("strength: ", strength);
        this.strengthVal = strength;
    }

    doSignup() {
        this.webServices.showLoader();
        // get registration nonce
        console.log(this.signupForm.value);

        let sendData = {
            username: this.signupForm.value.email,
            email: this.signupForm.value.email,
            password: this.signupForm.value.matching_passwords.confirm_password,
            first_name: this.signupForm.value.first_name,
            last_name: this.signupForm.value.last_name,
            phone_country_code: this.signupForm.value.phone_country_code,
            phone: this.signupForm.value.phone,
            email_verified: this.emailVerified,
            phone_verified: this.is_phone_verified,
        };
        console.log("sendData:", sendData);
        // return false;
        this.webServices.doRegister(sendData).subscribe((response) => {
            this.webServices.dismissLoading();
            console.log("response:", response);
            if (response['status'] == 'ok') {
                console.log('response///////////////////////', response)
                this.webServices.presentToast("Registration complete.");
                this.signupForm.reset();
                this.menu.enable(true);
                if (response['loginInfo']) {
                    this.loginInfo = response['loginInfo'];
                    if (this.loginInfo.user_id) {
                        this.resetTID(this.loginInfo.user_id);
                    }
                    this.storage.set('user', this.loginInfo);
                    // this.serviceForAllService.SaveAutoConfiqure(this.reg_result.loginInfo.token);
                    this.events.publish('userCheck:created', this.loginInfo);
                    this.dismissModal();
                    let qp = {
                        source: 'signup'
                    }
                    this.navController.navigateRoot(['/dashboard', qp]);
                } else {
                    // Signin Form
                    this.page_type = "signin";
                }
            } else {
                let msg = "Something went wrong. Try again";
                if (response['msg'] && response['msg'] != undefined && response['msg'] != null) {
                    msg = response['msg'];
                }
                this.webServices.presentAlert(msg);
            }
        }, (err) => {
            console.log("err:", err);
            this.webServices.dismissLoading();
            let msg = "Something went wrong. Try again.";
            if (err.error.msg && err.error.msg != undefined && err.error.msg != null) {
                msg = err.error.msg;
            }
            this.webServices.presentAlert(msg);
        });
    }

    sendMobileVerificationCode() {
        let phone = this.signupForm.value.phone;
        console.log("phone:", phone);

        let phone_country_code = this.signupForm.value.phone_country_code;
        console.log("phone_country_code:", phone_country_code);

        if (!phone_country_code || phone_country_code == '' || phone_country_code == null || phone_country_code == undefined) {
            this.webServices.presentToast("Please valid country code.");
            return false;
        }
        if (phone_country_code.search("_") != -1) {
            let arr = phone_country_code.split('_');
            phone_country_code = arr[1];
            console.log("phone_country_code has -", phone_country_code);
        } else {
            console.log("phone_country_code has not _ ", phone_country_code);
        }
        let sendData = {
            phone: phone,
            phone_country_code: phone_country_code,
        }

        if (this.signupForm.value.phone && this.signupForm.value.phone != '') {
            this.webServices.showLoader("Sending OTP on your phone number..");
            this.webServices.resendVcodeSms(sendData).subscribe((response) => {
                this.webServices.dismissLoading();
                console.log("response:", response);
                if (response['vcode'] && response['vcode'] != undefined && response['vcode'] != null) {
                    this.webServices.presentToast("OTP sent. Please check your sms.");
                    this.page_type = 'phone_verification';
                    this.sentcode = response['vcode'];
                    if (response['instructions'] && response['instructions'] != '') {
                        this.phone_instructions = response['instructions'];
                    }
                    // this.router.navigate(['/verify-phone'],{queryParams: {user_phone: this.signupForm.value.phone, sentcode: r.vcode, frompage: 'signup'} });
                } else {
                    this.webServices.presentToast("Your phone number must be valid. Please try again.");
                }
            }, (err) => {
                console.log("Err:", err);
                this.webServices.dismissLoading();
                let msg = "Something went wrong. Try again";
                if (err.error.msg == undefined || err.error.msg == null || err.error.msg == '') {
                    msg = err.error.msg;
                }
                this.webServices.presentToast(msg);
            });
        } else {
            this.webServices.presentToast("Please enter your phone number.");
            return false;
        }
    }

    sendEmailVerificationCode() {
        console.log("EMail:", this.signupForm.value.email);
        if (this.signupForm.value.email && this.signupForm.value.email != '') {
            this.webServices.showLoader("Sending verification code on your email address..");
            this.webServices.resendVcode(this.signupForm.value.email).subscribe((result) => {
                console.log("result:", result);
                this.webServices.dismissLoading();
                let r: any = [];
                r = result;
                if (r.vcode && r.vcode != '') {
                    this.webServices.presentToast("Code sent. Please check your mail.");
                    this.page_type = 'email_verification';
                    this.sentcode = r.vcode;
                    //   this.router.navigate(['/verify-email'],{queryParams: {user_email: this.signupForm.value.email, sentcode: r.vcode, frompage: 'signup'} });
                } else {
                    this.webServices.presentToast("Your email address must be valid. Please try again.");
                }
            }, (err) => {
                console.log("err:", err);
                this.webServices.dismissLoading();
                let msg = "Something went wrong. Please try again";
                if (err.error.msg && err.error.msg != undefined && err.error.msg != null) {
                    msg = err.error.msg;
                }
                this.webServices.presentToast(msg);
            });
        }
        else {
            this.webServices.presentToast("Please enter your email.");
        }
    }
    moveFocus(ev, i) {
        console.log(ev.key);
        if (ev.key.length == 1) {
            this.inputElement['_results'][i].setFocus();
            this.inputElement['_results'][i].value = '';
        }
    }

    otpController(event, next, prev) {
        if (event.target.value.length < 1 && prev) {
            prev.setFocus()
        }
        else if (next && event.target.value.length > 0) {
            next.setFocus();
        }
        else {
            return 0;
        }
    }

    verify_email_otp() {
        if (this.c1 != '' && this.c2 != '' && this.c3 != '' && this.c4 != '' && this.c5 != '' && this.c6 != '') {
            let vcode = this.c1 + '' + this.c2 + '' + this.c3 + '' + this.c4 + '' + this.c5 + '' + this.c6;
            if (this.sentcode && this.sentcode != '') {
                if (this.sentcode == vcode) {
                    this.emailVerified = true;
                    this.webServices.presentToast("Email verified. Please save your profile.");
                    this.page_type = 'signup';
                    this.c1 = '';
                    this.c2 = '';
                    this.c3 = '';
                    this.c4 = '';
                    this.c5 = '';
                    this.c6 = '';
                } else {
                    this.webServices.presentToast("OTP is not match. Try again");
                }
            } else {
                this.webServices.presentToast("You have entered wrong code.");
            }
        } else {
            this.webServices.presentToast("Please resend the code.");
        }
    }
    verify_phone_otp() {
        if (this.c1 != '' && this.c2 != '' && this.c3 != '' && this.c4 != '' && this.c5 != '' && this.c6 != '') {
            let vcode = this.c1 + '' + this.c2 + '' + this.c3 + '' + this.c4 + '' + this.c5 + '' + this.c6;

            console.log("vcode:", vcode);
            console.log("sentcode:", this.sentcode);
            if (this.sentcode && this.sentcode != '') {
                if (this.sentcode == vcode) {
                    this.is_phone_verified = true;
                    this.verified_phone_with_country_code = this.signupForm.value.phone_country_code + this.signupForm.value.phone;
                    console.log("verified_phone_with_country_code:", this.verified_phone_with_country_code);
                    this.webServices.presentToast("Email verified. Please save your profile.");
                    this.page_type = 'signup';
                    this.c1 = '';
                    this.c2 = '';
                    this.c3 = '';
                    this.c4 = '';
                    this.c5 = '';
                    this.c6 = '';
                } else {
                    this.webServices.presentToast("You have entered wrong OTP.");
                }
            } else {
                this.webServices.presentToast("Please resend the OTP.");
            }
        } else {
            this.webServices.presentToast("OTP is 6 digit. Try again.");
        }
    }

    forgotpassword(value) {
        let user_login = this.forgot_form.value.user_login;
        console.log("user_login=>", user_login);
        this.webServices.showLoader();
        this.webServices.doReset(user_login).subscribe((result) => {
            console.log('result here', result);
            this.response = result;

            this.webServices.dismissLoading();

            this.webServices.presentAlert(this.response.msg);
            console.log(this.response);

        }, (err) => {
            this.webServices.dismissLoading();
            console.log(err);
            let msg: any = [];
            msg = err.error.msg;

            this.webServices.presentAlert(msg);
        });
    }

    async open_country_lists() {
        console.log("open_country_lists");
        const modal = await this.modalController.create({
            component: CountryListPage,
            componentProps: {},
            cssClass: 'country_list_page'
        });
        modal.onDidDismiss().then((response) => {
            console.log("response:", response);
            if (response.data) {
                let data = response.data;
                console.log("data:", data);
                if (data.selected_country) {
                    let selected_country = data.selected_country;
                    let value = selected_country.iso3 + '_' + selected_country.phonecode;

                    this.signupForm.controls['phone_country_code'].setValue(value);
                    this.phone_country_code_label = selected_country.phonecode;

                    this.is_phone_verified = false;
                    let phone_with_country_code = this.signupForm.value.phone_country_code + this.signupForm.value.phone;
                    console.log("New phone_with_country_code:", phone_with_country_code);
                    if (this.verified_phone_with_country_code != '' && phone_with_country_code != '' && this.verified_phone_with_country_code == phone_with_country_code) {
                        this.is_phone_verified = true;
                    }
                    console.log("verified_phone_with_country_code:", this.verified_phone_with_country_code);


                }
            }
        });
        return await modal.present();
    }

    login_test(platform) {
        let login_url = '';
        if (platform == 'facebook') {
            login_url = 'https://app.tagster.com/auth/facebook/';
        } else if (platform == 'google') {
            login_url = 'https://app.tagster.com/auth/google/';
            // page_name = 'success_google';
        } else if (platform == 'instagram') {
            login_url = 'https://app.tagster.com/auth/instagram/';
            // page_name = 'success_instagram';
        } else if (platform == 'twitter') {
            login_url = 'https://app.tagster.com/auth/twitter/';
            // page_name = 'success_twitter';
        }
        window.open(login_url, '_self');
    }

    test_fb() {

    }


    async viewTerms() {
        const modal = await this.modalController.create({
            component: TermsPage,
            cssClass: 'my-custom-signup',
            componentProps: {
            }
        });
        modal.onDidDismiss().then((dataReturned) => {
            console.log('return');
        });
        return await modal.present();
    }



}

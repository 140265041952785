import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AlertController, IonContent, Platform, ToastController, ActionSheetController, PopoverController, LoadingController, ModalController } from '@ionic/angular';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { Storage } from "@ionic/storage";
import * as firebase from 'firebase';
import * as moment from 'moment';
import { ServiceForAllService } from '../service-for-all.service';
import { ReactionsPage } from '../reactions/reactions.page';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import * as $ from 'jquery';
import { Chooser } from '@ionic-native/chooser/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { SelectUserPage } from '../select-user/select-user.page';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.page.html',
  styleUrls: ['./new-message.page.scss'],
})
export class NewMessagePage implements OnInit {
    @ViewChild(IonContent, { static: false }) content: IonContent;
    msg: any;
    myDetails: any = [];
    chatType:any = 'tagster_chat';
    messageList: any = [];

    ref4: any=[];
    ref1: any = [];

    currentUser:any = [];
    currUserImg:any = '';

    typeRef: any = [];
    user_typing:boolean = false;

    deliveryRef: any = [];
    seen_status:any = '';
    isSelectable:boolean = false;

    msgToDel:any = [];

    emojiList = ['like', 'love', 'wow', 'haha', 'sad', 'angry'];

    audioFile:any;
    recording: boolean = false;
    recording_time:number = 0;
    incre:any;

    filterOn:boolean = false;


    someTextUrl:any;
    selectedPhoto;
    currentImage;

    uploadMedia:boolean = false;
    total_progress:number = 0.0;
    user:any = [];

    showEmojiPicker:boolean=false;

    isPinned:boolean = false;

    pinnerRef:any;

    selectedUser:any = '';

  constructor(
    public modalCtrl: ModalController,
    public activatedRoute: ActivatedRoute, public storage: Storage, public toastController: ToastController,
    public serviceForAllService: ServiceForAllService, public alertCtrl: AlertController,
    private actionSheetController: ActionSheetController,
    private popoverCtrl: PopoverController,
    private media: Media,
    public platform: Platform,
    private file: File,
    private transfer: FileTransfer,
    private camera: Camera,
    public cdr: ChangeDetectorRef,
    private chooser: Chooser,
    private previewAnyFile: PreviewAnyFile,
    private filePicker: IOSFilePicker,
    private fileChooser: FileChooser,
    private filePath: FilePath,
    public loadingCtrl: LoadingController,
    public router: Router,
    private route: ActivatedRoute,
  ) { 
    this.uploadMedia = false;

    // this.chatType = this.activatedRoute.snapshot.parent.paramMap.get('activetab');
    // this.chatType = this.activatedRoute.snapshot.parent.paramMap.get('activetab');

    this.storage.get("user").then(data => {
      this.myDetails = data;
      this.user = data;
      console.log('this.myDetails: ',this.myDetails);
      this.GetUser(data);
    })

    this.route.queryParams.subscribe(params => {
        let queryVars = params;
        console.log("EEEE :: ", queryVars);
        console.log(queryVars.user_id);

        if(queryVars && queryVars!=undefined && queryVars.user_id && queryVars.user_id!='' && queryVars.user_id!=undefined){

            console.log("m hereeeeee");
            this.selectedUser = queryVars;
        }
        else{
            this.selectedUser = '';
        }
  
      },(err)=>{
        this.selectedUser = '';
      });
  }

  async GetUser(user){
    this.serviceForAllService.getSecoondUserInfo(user.token,user.user_id).subscribe((result) => {
      let cu:any = [];
      cu = result;
      this.myDetails = result;
    });
  }

  ngOnInit() {
  }

  async openSelectUser() {
    const modal = await this.modalCtrl.create({
      component: SelectUserPage,
      cssClass: 'fakespalsh'
    });

    modal.onDidDismiss().then((result) => {
        console.log("ModelClose:", result.data);

        if(result.data && result.data.usersData && result.data.usersData.length>0){
            this.selectedUser = result.data.usersData[0];
        }

    });
    return await modal.present();

  }

  removeUser(){
    this.selectedUser = "";
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }


  async showLoader(){
    let loading = await this.loadingCtrl.create({message: 'Please wait...', backdropDismiss: true});
    loading.present();
    setTimeout(() => {
        loading.dismiss();
    }, 5000);
  }
  

  //////// CHAT /////////

  
  checkSelectedUser(){
      if(this.selectedUser && this.selectedUser.user_id!=''){
        this.send();
      }
      else{
        this.presentToast('Please select the user first!');
      } 
  }

  openchat(id,name,profile, recever_default_tag, default_language){
    setTimeout(() => {
        this.selectedUser = '';
        this.router.navigate(['messages',{
            receverid:id,
            receverName:name,
            receverImage:profile,
            receverDTag: recever_default_tag,
            chatType: this.chatType,
            receverLang: default_language
          }]);
      }, 1000);
  }

  send() {
    // this.content.scrollToBottom(300);
    let date = new Date();
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;


    let message_id = Date.now();

    if (this.msg !== '') {
      let sender = firebase.database().ref(this.chatType+'/' + this.myDetails.user_id + '/' + this.myDetails.user_id + 'to' + this.selectedUser.user_id + '/messages');
      sender.push({
        message_id: message_id,
        message: this.msg,
        sendDate: moment(Date()).format("DD/MM/YYYY"),
        time: Date.now(),
        strTime: strTime,
        senderId: this.myDetails.user_id,
        senderName: this.myDetails.first_name + ' ' + this.myDetails.last_name,
        senderImage: this.myDetails.user_avatar,
        recevierId: this.selectedUser.user_id,
        receverImage: this.selectedUser.user_img,
        receverName: this.selectedUser.full_name,
        reaction: '',
        removed: 0,
        unread: 0,
        media:'no',
        media_type: '',
        file_path: '',
        file_ext: ''
      });
      //Recever
      let recevier = firebase.database().ref(this.chatType+'/' + this.selectedUser.user_id + '/' + this.selectedUser.user_id + 'to' + this.myDetails.user_id + '/messages');
      recevier.push({
        message_id: message_id,
        message: this.msg,
        sendDate: moment(Date()).format("DD/MM/YYYY"),
        time: Date.now(),
        strTime: strTime,
        senderId: this.myDetails.user_id,
        senderName: this.myDetails.first_name + ' ' + this.myDetails.last_name,
        senderImage: this.myDetails.user_avatar,
        recevierId: this.selectedUser.user_id,
        receverImage: this.selectedUser.user_img,
        receverName: this.selectedUser.full_name,
        reaction: '',
        removed: 0,
        unread: 1,
        media:'no',
        media_type: '',
        file_path: '',
        file_ext: ''
      });
      this.msg = '';

      this.handleSeen();
      this.openchat(this.selectedUser.user_id, this.selectedUser.full_name, this.selectedUser.user_img, this.myDetails.default_tag, this.selectedUser.default_language);

    } else {
      console.log("NO MSG");
      this.presentToast('Please Enter Your Msg');
    }
  }

  handleSeen(){
    firebase.database().ref(this.chatType+'/' + this.selectedUser.user_id + '/' + this.selectedUser.user_id + 'to' + this.myDetails.user_id + '/seenStatus/').update({ status: "0" });
  }


  addEmoji(event) { 
    console.log(event.data);
    if(this.msg && this.msg!='' && this.msg!=undefined){
        this.msg=this.msg+event.data; 
    }
    else{
        this.msg=event.data; 
    }
    this.showEmojiPicker = false;
  }
  
  captureImage(){
    const options: CameraOptions = {
        quality: 30,
        destinationType: this.camera.DestinationType.DATA_URL,
        sourceType: this.camera.PictureSourceType.CAMERA,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        correctOrientation: true
    }

    this.camera.getPicture(options).then((imageData) => {
        let base64Image = 'data:image/jpeg;base64,' + imageData;
        this.uploadImg(base64Image, 'chat_image', 'image/jpeg', 'jpg', 'image');
    }, (err) => {
        console.log(err);
        this.presentToast(err);
    });
}

galleryImage(){
    const options: CameraOptions = {
        quality: 30,
        destinationType: this.camera.DestinationType.DATA_URL,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        correctOrientation: true
    }

    this.camera.getPicture(options).then((imageData) => {
        let base64Image = 'data:image/jpeg;base64,' + imageData;
        this.uploadImg(base64Image, 'chat_image', 'image/jpeg', 'jpg', 'image');
    }, (err) => {
        console.log(err);
        this.presentToast(err);
    });
}

random(): number {
    let rand = Math.floor(Math.random()*20)+1;
    return rand;       
}

uploadImg(base64Image, attachment_type, media_type, file_ext, file_type) {

    let myRand = this.random();

    this.uploadMedia = true;
    this.total_progress = 0.0;
    const fileTransfer: FileTransferObject = this.transfer.create();
    let header: Headers = new Headers();
    header.append('Authorization', 'Bearer ' + this.user.token);
    let options: FileUploadOptions = {
      fileKey: 'file',
      fileName: myRand+"_docfile."+file_ext,
      chunkedMode: false,
      mimeType: media_type,
      params: { 'attachment_type': attachment_type, 'user_id': this.myDetails.user_id },
      headers: { 'Authorization': 'Bearer ' + this.user.token }
    }

    fileTransfer.upload(base64Image, 'https://app.tagster.com/wp-json/wp/v2/media?token=' + this.user.token, options)
      .then((data) => {
        console.log(" UPLOADED DATA ==== ", data);
        let d: any = [];
        d = JSON.parse(data.response);
        let smext = d.guid.rendered.split('.').pop();
        let ext = smext.toLowerCase();

        this.sendMedia(d.guid.rendered, ext, file_type);

        this.uploadMedia = false;
      }, (err) => {
        console.log(err);
        console.log('error');
        this.uploadMedia = false;
      });

    fileTransfer.onProgress((progressEvent) => {
      if (progressEvent.lengthComputable) {
        var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
        console.log("P: ", perc);
        this.total_progress = perc/100;
      }
    });
}

isIos() {
    return this.platform.is('ios');
}

chooseFiles() {
    let i = this.isIos();
    if(i){
      this.filePicker.pickFile()
        .then(uri => {
          console.log(uri);

          let smext = uri.split('.').pop();
            let file_ext = smext.toLowerCase();

            if (file_ext == 'pdf') {
                this.uploadImg(uri, 'chat_image', 'application/pdf', file_ext, 'document');
            }
            else if (file_ext == 'doc' || file_ext == 'docx') {
                this.uploadImg(uri, 'chat_image', 'application/msword', file_ext, 'document');
            }
            else{
                file_ext = '';
                this.presentToast("Invalid file type.");
            }
        })
        .catch(err => console.log('Error', err));
    }
    else{
      this.fileChooser
      .open()
      .then(url => {
        this.filePath.resolveNativePath(url)
          .then(uri => {
                console.log(uri);

                        let smext = uri.split('.').pop();
                        let file_ext = smext.toLowerCase();
                        if (file_ext == 'pdf') {
                            this.uploadImg(uri, 'chat_image', 'application/pdf', file_ext, 'document');
                        }
                        else if (file_ext == 'doc' || file_ext == 'docx') {
                            this.uploadImg(uri, 'chat_image', 'application/msword', file_ext, 'document');
                        }
                        else{
                            file_ext = '';
                            this.presentToast("Invalid file type.");
                        }
          })
          .catch(err => console.log(err));
      })
      .catch(e => {
        console.log(e);
      });
    }
  }

  rec(){
    this.file.createFile(this.file.tempDirectory, 'my_file.m4a', true).then(() => {
        this.audioFile = this.media.create(this.file.tempDirectory.replace(/^file:\/\//, '') + 'my_file.m4a');
        this.audioFile.startRecord();
        this.recording = true;
        this.recording_time = 0;
    });
}

stop(){
    this.recording = false;
    if(this.audioFile){
        this.audioFile.stopRecord();
        this.uploadImg(this.file.tempDirectory.replace(/^file:\/\//, '') + 'my_file.m4a', 'chat_audio', 'audio/mpeg', 'm4a', 'audio');
    }
}

sendMedia(file_path, file_ext, file_type) {
    let date = new Date();
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    let message_id = Date.now();
    this.msg = '';

      let sender = firebase.database().ref(this.chatType+'/' + this.myDetails.user_id + '/' + this.myDetails.user_id + 'to' + this.selectedUser.user_id + '/messages');
      sender.push({
        message_id: message_id,
        message: this.msg,
        sendDate: moment(Date()).format("DD/MM/YYYY"),
        time: Date.now(),
        strTime: strTime,
        senderId: this.myDetails.user_id,
        senderName: this.myDetails.first_name + ' ' + this.myDetails.last_name,
        senderImage: this.myDetails.user_avatar,
        recevierId: this.selectedUser.user_id,
        receverImage: this.selectedUser.user_img,
        receverName: this.selectedUser.full_name,
        reaction: '',
        removed: 0,
        unread: 0,
        media:'yes',
        media_type: file_type,
        file_path: file_path,
        file_ext: file_ext
      });
      //Recever
      let recevier = firebase.database().ref(this.chatType+'/' + this.selectedUser.user_id + '/' + this.selectedUser.user_id + 'to' + this.myDetails.user_id + '/messages');
      recevier.push({
        message_id: message_id,
        message: this.msg,
        sendDate: moment(Date()).format("DD/MM/YYYY"),
        time: Date.now(),
        strTime: strTime,
        senderId: this.myDetails.user_id,
        senderName: this.myDetails.first_name + ' ' + this.myDetails.last_name,
        senderImage: this.myDetails.user_avatar,
        recevierId: this.selectedUser.user_id,
        receverImage: this.selectedUser.user_img,
        receverName: this.selectedUser.full_name,
        reaction: '',
        removed: 0,
        unread: 1,
        media:'yes',
        media_type: file_type,
        file_path: file_path,
        file_ext: file_ext
      });
      this.msg = '';

      this.handleSeen();
      this.openchat(this.selectedUser.user_id, this.selectedUser.full_name, this.selectedUser.user_img, this.myDetails.default_tag, this.selectedUser.default_language);

  }

}

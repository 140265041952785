import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-privacy-rights',
  templateUrl: './privacy-rights.page.html',
  styleUrls: ['./privacy-rights.page.scss'],
})
export class PrivacyRightsPage implements OnInit {

  constructor(
    private modalController : ModalController
  ) { }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profilegallerymodal',
  templateUrl: './profilegallerymodal.page.html',
  styleUrls: ['./profilegallerymodal.page.scss'],
})
export class ProfilegallerymodalPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-social-tabs',
  templateUrl: './social-tabs.component.html',
  styleUrls: ['./social-tabs.component.scss'],
})
export class SocialTabsComponent implements OnInit {
    @Input() tab_name: any;
    @Input() login_user_id : any;
    
    // loginInfo : any = [];
    constructor(
        public storage : Storage,
        public router: Router, 
    ) {

    }

    ngOnInit() {
        console.log('login_user_id1:',this.login_user_id);
        console.log('tab_name:',this.tab_name);
        setTimeout(()=>{
            console.log('login_user_id2:',this.login_user_id);
        },2000)
      
        this.storage.get('user').then(user => {
            if(user){
                this.login_user_id = user.user_id;
            }
        });
    }

    addPost() {
        this.router.navigate(['/addpost']);
    }
    add_new_post() {
        this.router.navigate(['/add-new-post']);
    }
    
    goto_social_homepage() {
        this.router.navigate(['/social-media-dashboard']);
    }
    goto_social_search_page(){
        this.router.navigate(['/social-search']);
    }
    goto_activity_page(){
        this.router.navigate(['/activity']);
    }
    goto_social_user_profile(){
        // [routerLink]="['/social-user-profile/'+login_user_id]"
        this.router.navigate(['/social-user-profile/'+this.login_user_id]);
    }

}

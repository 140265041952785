import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceForAllService } from './service-for-all.service';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(
    private  translate: TranslateService,
    public   serviceForAllService: ServiceForAllService, 
    public   storage: Storage,
    private  events : Events
  ) { 
  }

  getDefaultLanguage(){
    // let language = this.translate.getBrowserLang();
    // console.log("language:: ", language);
    // this.translate.setDefaultLang(language);
    // return language;

    let language = 'en';
      this.storage.get('user').then((val) => {
        if (val != null) {
            return this.serviceForAllService.GetLanguageSetting(val.token)
            .subscribe(res => {
               language = res.toString();
              console.log("language:: ", language);
              this.translate.setDefaultLang(language);
              return language;
            },(err)=>{
                this.translate.setDefaultLang(language);
                return language;
            })
        }else{
            this.translate.setDefaultLang(language);
            return language;
        }
      },(err)=>{
        this.translate.setDefaultLang(language);
        return language;
      });
  }

  setLanguage(setLang) {
      console.log("setLang:: ", setLang);
      this.translate.use(setLang);

      this.SetLanguageSetting(setLang);
  }

  SetLanguageSetting(setLang){
    this.storage.get('user').then((val) => {
        if (val != null) {
            this.serviceForAllService.SetLanguageSetting(val.token, setLang)
            .subscribe(res => {
                let r:any = [];
                r = res;
                console.log("r:",r);
                if(r['loginInfo']){
                    val.default_language = setLang ;
                    console.log("Language changed!!",setLang);
                    this.storage.set('user', val);
                    this.events.publish('refLanguage',val);
                }
              
            })
        }
      });
  }
  
}

import { Component, OnInit, Input } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Events, ModalController, IonContent, AlertController, MenuController } from '@ionic/angular';
import { ServiceForAllService } from '../../service-for-all.service';
@Component({
    selector: 'app-social-sidebar',
    templateUrl: './social-sidebar.component.html',
    styleUrls: ['./social-sidebar.component.scss'],
})
export class SocialSidebarComponent implements OnInit {
    @Input() page: any;
    constructor(
        public storage: Storage,
        public router: Router,
        public events: Events,
        public alertController: AlertController,
        public modalController: ModalController,
        public menu: MenuController,
        public serviceForAllService: ServiceForAllService,
    ) {
        // console.log("page1:", this.page);
    }

    ngOnInit() {
        // console.log("page2:", this.page);
    }

    goto_page(page_slug) {
        this.router.navigate([page_slug]);
    }

}

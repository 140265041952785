import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../../../service-for-all.service';
@Component({
  selector: 'app-mute-notification',
  templateUrl: './mute-notification.page.html',
  styleUrls: ['./mute-notification.page.scss'],
})
export class MuteNotificationPage implements OnInit {
    loginInfo : any = [];
    second_user_id : any = '';
    duration : number = 15*60;
    is_spinner : string = 'off';
    muteInfo : any = [];
    constructor(
        private modalController: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    ) { }

    ngOnInit() { 
    
        console.log("navParams:", this.navParams);

        this.second_user_id = this.navParams.data.user_id;
        console.log("second_user_id", this.second_user_id);

        this.loginInfo = this.navParams.data.loginInfo;
        console.log("loginInfo:", this.loginInfo);

        this.muteInfo = this.navParams.data.muteInfo;
        console.log("muteInfo:", this.muteInfo);
        if(this.muteInfo.duration){
            this.duration = this.muteInfo.duration;
        }
    }

    async closeModal() {
        await this.modalController.dismiss({
            muteInfo : this.muteInfo,
        });
    }

    mute_the_user(){
        let sendData = {
            token   : this.loginInfo.token,
            second_user_id : this.second_user_id,
            duration : this.duration,
            type    : 'both',
        }
        console.log("sendData:", sendData);
        this.is_spinner  = 'on';
        this.serviceForAllService.sendData('do_mute', sendData).subscribe((response) => {
            console.log('response:',response);
            this.is_spinner = 'off';
            if(response['status'] == 'ok'){
                this.serviceForAllService.presentToast(response['msg']);
                this.muteInfo = response['muteInfo'];
                this.closeModal();
            }else{
                this.serviceForAllService.presentAlert(response['msg']);
            }
        }, (err) => {
            this.is_spinner = 'off';
            console.log("err:",err);
            this.serviceForAllService.presentAlert(err.error.msg);
            if(err.error.error_code == 'token_expired'){
                // Redirect to login page.
            }
        });
    }

}

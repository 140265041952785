import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, Platform, AlertController } from '@ionic/angular';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { VideoPopupPage } from '../video-popup/video-popup.page';
import { Storage } from '@ionic/storage';
import { IonSlides } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { PopoverController } from '@ionic/angular';
import { StoryViewersPage } from '../social-media/pages/story-viewers/story-viewers.page';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-grapevine-model',
  templateUrl: './grapevine-model.page.html',
  styleUrls: ['./grapevine-model.page.scss'],
})
export class GrapevineModelPage implements OnInit {
    private currentNumber = 0;
    owner:any=[];
    user:any = [];
    user_id:any = '';
    is_sending:boolean = false;
    grapevineInfo: any = [];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    private streamingMedia: StreamingMedia,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public popoverController: PopoverController,
    public webservice : ServiceForAllService,
    private router: Router,
    public alertCtrl: AlertController
  ) {
   }

    ngOnInit() {
        this.is_sending = false;
        this.owner = this.navParams.data.owner;
        console.log("owner:====>>", this.owner);


        this.storage.get('user').then((val) => {
            console.log('userData: ', val);
            if (val != null) {
            this.user = val;
            this.user_id = val.user_id;
            //   this.viewedStory(this.index);
            }
        });

    }

    async closeModal() {
        const onClosedData: string = "Wrapped Up!";
        await this.modalController.dismiss(onClosedData);
    }

    private increment () {
        this.currentNumber++;
    }
    
    private decrement () { 
        if(this.currentNumber>0){
            this.currentNumber--;
        }
    }

    send_tags(){
        if(this.currentNumber <= 0){
            this.webservice.presentToast("Please select a amount.");
            return false;
        }

        if(this.user.default_card_id == ''){
            this.serviceForAllService.presentAlert("You have not set default payment method.");
            return false;
        }

        // if(this.owner.default_bank_id == ''){
        //     this.serviceForAllService.presentAlert("User have not connect their default bank.");
        //     return false;
        // }

        this.confirmSending();
    }

  async confirmSending() {

    // let anonymous = (this.anonymous == true) ? 'yes' : 'no';
    // let sendData = {
    //     token       : this.user.token,
    //     parent_id   : "",
    //     amount      : this.currentNumber,
    //     receiver_user_id : this.owner.user_id,
    //     receiver_bank_id : this.owner.default_bank_id,
    //     sender_card_id : this.user.default_card_id,
    //     anonymous   : "",
    //     description : "",
    //     entry_type  : "grapevine"
    // }
    let sendData = {
      token: this.user.token,
      grapevine_id: this.owner.default_grapevine.grapevine_id,
      amount: this.currentNumber,
      receiver_user_id: this.owner.user_id,
      receiver_bank_id: this.owner.default_bank_id,
      sender_card_id: this.user.default_card_id,
      anonymous: "",
      description: "",
      entry_type: 'grapevine'
    }
    console.log("sendData:", sendData);


    const alert = await this.alertCtrl.create({
      header: 'Confirm',
      message: 'Are you sure you want to send $'+this.currentNumber+'?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            
            this.is_sending = true;
            this.webservice.send_contribution(sendData).subscribe((response) => {
                console.log('response:',response);
                this.is_sending = false;
                if(response['status'] == 'ok'){
                    this.webservice.presentToast(response['msg']);
                    // this.modalController.dismiss({sent:'yes'});
                  if (response['grapevineInfo']) {
                    this.grapevineInfo = response['grapevineInfo'];
                  }
                    this.closing_model();
                    // this.router.navigate(['/your-geo']);
                }else{
                    this.webservice.presentAlert(response['msg']);
                }
            }, (err) => {
                this.is_sending = false;
                console.log("err:",err);
                this.webservice.presentAlert(err.error.msg);
                if(err.error.error_code == 'token_expired'){
                }
            });
            
          }
        }
      ]
    });
  
    await alert.present();
  }
  closing_model() {
    this.modalController.dismiss({
      sent: 'yes',
      goto_page: 'your-geo',
      grapevineInfo: this.grapevineInfo,
    });
  }

  goGrapeMap(){
      this.modalController.dismiss({main_user_id: this.owner.user_id});
    //   this.router.navigate(['/grapevine-map']);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
@Pipe({ name: 'userExtract' })

export class UserExtractPipe implements PipeTransform {
  date: any;
  startdate: any;
  enddate: any;
  isSelected: any;
  isday: any;
  weekNames2: any;
  newVal:any;
  constructor(
    public serviceForAllService: ServiceForAllService) { 
    }
  transform(value: any, arg,arr:any): any {
    if (arg === 'getimage') {
      return this.newVal;
    }

    if (arg === 'check_if_user') {
      if(arr.indexOf(value.user_id) > -1){
        return true;
      }else{
        return false;
      }
      
    }
  }
} 
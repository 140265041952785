import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { PreloadImageComponent } from './preload-image/preload-image.component';

import { ImageShellComponent } from './image-shell/image-shell.component';
import { SocialTabsComponent } from './social-tabs/social-tabs.component';
import { SocialSidebarComponent } from './social-sidebar/social-sidebar.component';
import { HeaderComponent } from './header/header.component';
import { TextShellComponent } from './text-shell/text-shell.component';

import { ListShellComponent } from './list-shell/list-shell.component';
import { PopoverComponent } from '../components/popover/popover.component';
import { FormsModule } from '@angular/forms';
import { SingleGrapevineModelComponent } from '../components/single-grapevine-model/single-grapevine-model.component';
import { SearchUsersComponent } from 'src/app/components/search-users/search-users.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    FormsModule,
    RouterModule
  ],
  declarations: [
    SearchUsersComponent,
    PreloadImageComponent,
    ImageShellComponent,
    SocialTabsComponent,
    HeaderComponent,
    TextShellComponent,
    ListShellComponent,
    PopoverComponent,
    SocialSidebarComponent,
    SingleGrapevineModelComponent,
  ],
  exports: [
    SearchUsersComponent,
    PreloadImageComponent,
    ImageShellComponent,
    TextShellComponent,
    SocialTabsComponent, 
    HeaderComponent,
    ListShellComponent,
    PopoverComponent,
    SocialSidebarComponent,
    SingleGrapevineModelComponent,
  ],
  entryComponents: [PopoverComponent, SingleGrapevineModelComponent,SearchUsersComponent],
})
export class ComponentsModule {}

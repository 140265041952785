import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs"; 
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError, tap, map } from "rxjs/operators";
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';

/* EMRP
Photo Viewer
Video Editor
Media Capture
Streaming Media
File
File Transfer
Camera
*/

let wpUrl = "https://app.tagster.com//";
let fullUrl ="https://app.tagster.com//wp-json/mobileapi/v1/"
// const httpOptions = {
//   headers: new HttpHeaders({ "Content-Type": "application/json" })
// };
@Injectable({
  providedIn: "root"
})
export class SocialServicesService {
  url = "https://app.tagster.com//wp-json/wp/v2/";
  totalPosts = null;
  pages: any;

  constructor(
    private http: HttpClient, 
    public storage:Storage,
    public events: Events,
  ) { }

  get_category_list(userToken, serach_keyword) {
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/get_category_list", {
      token: userToken,
      serach_keyword: serach_keyword
    }).pipe(map(res => {
      return res;
    }));
  }

  getSocialFeed(page = 1, share_post_id, exclude_post_id, user_id, userToken:any='',mypost:number=0, byid:number=0, post_title:any='', postPer:any=10, cat_id, listtype, other_user_id): Observable<any[]> {

    console.log("listtype: ", listtype);
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: postPer,
        page: '' + page
      }
    };
    let post_title_url= post_title ? ("&search=" + post_title): "";
    return this.http.get<any[]>(this.url+'social_feed?_embed&token='+userToken+"&user_id="+user_id+"&mypost="+mypost+"&byid="+byid+post_title_url+'&cat_id='+cat_id+'&listtype='+listtype+'&other_user_id='+other_user_id+'&share_post_id='+share_post_id+'&exclude_post_id='+exclude_post_id, options).pipe(
    map(resp => {
      this.pages = resp['headers'].get('x-wp-totalpages');
      this.totalPosts = resp['headers'].get('x-wp-total');
      
      let data = resp['body'];
      
      for (let post of data) {
        post.media_url = post['media_url'];
      }
      return data;
    })
    )
  }

//   replyComment(post_id,parent_cid,post_comment, videoThumbnail, comment_attachment_url, post_content_type, user){
//     return this.http.post(wpUrl + 'wp-json/mobileapi/v1/reply_comment', {
//       token: user.token,
//       post_id: post_id,
//       parent_cid: parent_cid,
//       post_comment: post_comment,
//       video_thumbnail: videoThumbnail,
//       comment_attachment_url: comment_attachment_url,
//       post_content_type: post_content_type
//     }).pipe(
//       map(user => {
//         return user;
//       })
//     ) 
//   }

  deleteComment(comment_id,user){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/delete_comment', {
      token: user.token,
      comment_id: comment_id,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }


  likeDislike(id,type,user){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/like_dislike', {
      token: user.token,
      id: id,
      type: type
    }).pipe(
      map(user => {
        return user;
      })
    ) 
  }

  deletePost(token, post_id){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/delete_post', {
      token: token,
      post_id: post_id,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  ///


  get_social_categories(token, taxonomy){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_social_categories', {
      token: token,
      taxonomy: taxonomy
    }).pipe(
      map(user => {
        return user;
      })
    ) 
  }

  get_post_details(post_id, token){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_post_details', {
      post_id: post_id,
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    ) 
  }


  addfeed(sendData) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/create_feed', sendData).pipe(
      map(user => {
        return user;
      })
    ) 
  }

  get_comment_details(comment_id, token){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_comment_details', {
      comment_id: comment_id,
      token: token
    }).pipe(
      map(user => {
        return user;
      })
    ) 
  }

  update_comment(comment){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/update_comment',comment).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_followers(token, uid){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_followers', {
      token: token,
      uid: uid,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_following(token, uid){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_following', {
      token: token,
      uid: uid,
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  user_follow_unfollow(token, leader_id, status){
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/user_follow_unfollow",{
      token: token,
      leader_id: leader_id,
      status: status
    });
  }

  user_block_unblock(token, blocked_userid, status){
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/user_block_unblock",{
      token: token,
      blocked_userid: blocked_userid,
      status: status
    });
  }

  getCurrentUserInfo(userToken) {
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/getCurrentUserInfo", {
      token: userToken
    }).pipe(map(res => {
      return res;
    }));
  }

  // getCommentChilds(commentID,token) {
  //   return this.http.get(wpUrl + 'wp-json/mobileapi/v1/get_comment_childs/?comment_id=' + commentID+'&token='+token).pipe(
  //     map(user => {
  //       return user;
  //     })
  //   )
  // }

  report_as_abuse(post){
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/report_as_abuse',post).pipe(
      map(user => {
        return user;
      })
    )
  }

  get_other_user_details(uid, userToken) {
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/get_other_user_details", {
      uid: uid,
      token: userToken
    }).pipe(map(res => {
      return res;
    }));
  }

  /// ADD FEED

  addStory(feed, user) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/add_story', {
      post_id: feed.post_id,
      post_content: feed.description,
      token: user.token,
      // media_array: feed.media_array,
      post_content_type: feed.post_content_type,
      post_attachment_url: feed.post_attachment_url,
      video_thumbnail: feed.video_thumbnail,
    }).pipe(
      map(user => {
        return user;
      })
    ) 
  }


  loadStories(page = 1, user_id, userToken:any='',mypost:number=0, byid:number=0, post_title:any='', postPer:any=100, cat_id, listtype, other_user_id): Observable<any[]> {

    console.log("listtype: ", listtype);
    let options = {
      observe: "response" as 'body',
      params: {
        per_page: postPer,
        page: '' + page
      }
    };
    let post_title_url= post_title ? ("&search=" + post_title): "";
    return this.http.get<any[]>(this.url+'story?_embed&token='+userToken+"&user_id="+user_id+"&mypost="+mypost+"&byid="+byid+post_title_url+'&cat_id='+cat_id+'&listtype='+listtype+'&other_user_id='+other_user_id, options).pipe(
    map(resp => {
      this.pages = resp['headers'].get('x-wp-totalpages');
      this.totalPosts = resp['headers'].get('x-wp-total');
      
      let data = resp['body'];
      
      for (let post of data) {
        post.media_url = post['media_url'];
      }
      return data;
    })
    )
  }

  replyComment(sendData){
    return this.postData('reply_comment', sendData);
}

postData(endpoint, sendData){
    return this.http.post(fullUrl + endpoint, sendData);
}

}

import { Component, OnInit } from '@angular/core';
import { Platform, NavController, AlertController, LoadingController, MenuController, Events, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-search-listing',
  templateUrl: './search-listing.page.html',
  styleUrls: ['./search-listing.page.scss'],
})
export class SearchListingPage implements OnInit {
    user:any = [];
    users_list:any = [];
    userready: boolean = false;
    userpage: any = 1;
    count:number = -1;

    recent_list:any = [];

    selected_users:any = [];
    searchQuery:any;
    loginInfo : any = [];
    constructor(
        public modalCtrl: ModalController,
        public serviceForAllService: ServiceForAllService,
        public loadingCtrl: LoadingController,
        public storage: Storage,
    ) {

    }

    ngOnInit() {

    }

    ionViewWillEnter(){
        this.storage.get('user').then((val) => {
            if (val != null) {
                this.user = val;
                this.loginInfo = val;
                console.log('loginInfo:',this.loginInfo);
                this.getAllUsers();
                this.getRecentSearches();
            }else{
                this.serviceForAllService.presentToast("Token expired. Please login again");
                this.modalCtrl.dismiss();
            }
        });
    }

    dismissModal(){
        this.modalCtrl.dismiss();
    }

    getAllUsers(){
        // this.searchQuery = username;
        this.userready = false;
        this.userpage = 1;
        console.log("searchQuery:",this.searchQuery);
        this.serviceForAllService.searchAllUser(this.user.token, this.userpage, this.searchQuery, '').subscribe((result) => {
            let r:any = [];
            r = result;
            this.users_list = r.users;
        
            if(this.searchQuery != '' && this.searchQuery  != undefined){
                this.users_list.forEach(element => {
                    this.updateRecentSearches(element.user_id);
                });
            }

            this.userready = true;
            this.count = this.users_list.length;
        }, (err) => {
            console.log(err);
            this.userready = true;
            this.count = 0;
        });
    }

    updateRecentSearches(search_user_id){
        this.storage.get('user').then((val) => {
            console.log(val);
            if (val != null) {
                this.serviceForAllService.updateSearches(val.user_id, search_user_id, 'user').subscribe((result) => {
                    console.log("search saved...");
                });
            }
        });
    }
    
    
    loadMore(event) {
        this.userpage++;
        this.serviceForAllService.searchAllUser(this.user.token, this.userpage, this.searchQuery, '').subscribe((result) => {
            console.log(result);
            let r:any = [];
            r = result;
            this.users_list = [...this.users_list, ...r.users];
            if(r.users.length==0){
              this.userpage--;
            }
            event.target.complete();
        }, (err) => {
            console.log(err);
            this.userpage--;
            event.target.complete();
        });
    }
    
    selectUser(userdata){
        if(userdata){
            this.selected_users.push(userdata);
            this.modalCtrl.dismiss({usersData: this.selected_users});
        }
    }


    submitSearch($event) { 
        if(typeof this.searchQuery != "undefined") {
            setTimeout((z) => {
                this.getAllUsers();
            }, 200);
        }
    }
    
    clearSearch(e) {
        this.searchQuery = '';
        console.log(this.searchQuery)
        setTimeout((z) => {
            this.getAllUsers();
        }, 200);
    }


    getRecentSearches(){
        this.serviceForAllService.getRecentSearches(this.loginInfo.token).subscribe((result) => {
            console.log(result);
            this.recent_list = result;
        }, (err) => {
            console.log(err);
            this.recent_list = [];
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router, NavigationExtras } from '@angular/router';
import { Events, ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
@Component({
  selector: 'app-select-users',
  templateUrl: './select-users.page.html',
  styleUrls: ['./select-users.page.scss'],
})
export class SelectUsersPage implements OnInit {
    loginInfo : any = [];
    is_spinner : string = 'on';
    users_list: any = [];
    count: any;
    loading: any;
    // post_id: number = 0;
    searchedUser: any;

    selected_user_ids : any = [];
    selected_users : any = [];

    action_type : string = 'tagging_users';
    tagged_users : any = [];
    can_remove : string = 'no'; 
    post_id : any = '';
    constructor(
        public storage: Storage,
        public router: Router,
        private navParams: NavParams,
        public loadingCtrl: LoadingController,
        public modalController: ModalController,
        public alertCtrl: AlertController,
        public menu: MenuController,
        public navCtrl: NavController,
        public toastController: ToastController,
        public serviceForAllService: ServiceForAllService,
        public events: Events
    ) {
        this.selected_user_ids = this.navParams.get('selected_user_ids');
        this.selected_users = this.navParams.get('selected_users');
        this.action_type = this.navParams.get('action_type');
        console.log('selected_user_ids: ', this.selected_user_ids);
        console.log('selected_users: ', this.selected_users);
        console.log('action_type: ', this.action_type);

        
        this.storage.get('user').then((user) => {
            if (user) {
                this.loginInfo = user;
                if(this.action_type == 'tagging_users'){
                    this.get_user_for_tagging();
                }else{
                    this.is_spinner = "off";
                    this.can_remove = this.navParams.get('can_remove');
                    this.post_id = this.navParams.get('post_id');
                    console.log('can_remove: ', this.can_remove);
                    this.tagged_users = this.selected_users;

                    console.log("UU:", this.users_list);
                }
            }else{
                this.loading.dismiss();
                this.serviceForAllService.presentToast("Token is expired. Please login again.");
                this.router.navigate(['/login']);
            }
        });
    }

    ngOnInit() {

    }

    async closeModal() {
        await this.modalController.dismiss({
            selected_user_ids   : this.selected_user_ids,
            selected_users      : this.selected_users,
            post_id             : this.post_id,
        });
    }


    ionViewWillEnter(){
        console.log("INO");
       
    }
    
    get_user_for_tagging() {
        let sendData = {
            token : this.loginInfo.token,
        }
        this.is_spinner = 'on';
        this.serviceForAllService.sendData('get_users_for_tagging', sendData).subscribe((response) => {
            this.is_spinner = 'off';
            console.log("response:", response);
            if(response['status'] == 'ok'){
                this.users_list = response['users'];
                console.log('users_list:', this.users_list);
                this.count = this.users_list.length;

                let i=0;
                this.users_list.forEach((u) => {
                    this.users_list[i].is_display = true;
                    if(this.selected_user_ids.indexOf(u.user_id) > -1){
                    this.users_list[i].isChecked = true;
                    }else{
                    this.users_list[i].isChecked = false;
                    }
                    i++;
                });

                // console.log('users_list after ====', this.users_list);
            }else{

            }
            
        }, (err) => {
            console.log("err:", err);
            this.is_spinner = 'off';
            this.serviceForAllService.presentToast(err.error.msg);
        
        });
        
    }

    sortUsersListData(event){
        this.searchedUser = event.target.value.toLowerCase();
        // console.log(this.searchedUser);
        // if (this.searchedUser == '') {
        //   this.get_my_followers_and_followings();
        // }
        this.count = 0;
        this.users_list.forEach(item => {
            const one = item.full_name.toLowerCase().indexOf(this.searchedUser) > -1;
            //const two = item.msg_data.message.toLowerCase().indexOf(this.searchedChatUser) > -1;
            //const three = item.report_status_show.toLowerCase().indexOf(this.searchedChatUser) > -1;
            if(one==true){
                item.is_display = true;
            }else{
                item.is_display = false;
            }
            if(item.is_display == false){
                this.count = this.count + 1;
            }
        });
    }

    selectUser(ev, index){
        let row = this.users_list[index];
        console.log("row:", row);
        let i = 0;
        let indx = this.selected_user_ids.indexOf(row['user_id']);
        console.log("indx:", indx);
        if(indx > -1){
            this.selected_user_ids.splice(indx, 1);
            this.selected_users.forEach((s) => {
                if(s.user_id == row['user_id']){
                    this.selected_users.splice(i,1);
                }
                i++;
            });
        }
        else{
            this.selected_user_ids.push(row['user_id']);
            let user_data:any = {
                user_id : row['user_id'],
                full_name : row['full_name'],
            };
            this.selected_users.push(user_data);
        }
        console.log("selected_user_ids: ",this.selected_user_ids);
        console.log("selected_users: ",this.selected_users);
    }

    remove_tagged_user(user_id){
        console.log('removed user_id: '+user_id);
        let i=0;
        let indx;
        console.log("bbb selected_user_ids: ", this.selected_user_ids);
        console.log("selected_users: ", this.selected_users);
        indx = this.selected_user_ids.indexOf(user_id);
        if(indx > -1){
            this.selected_user_ids.splice(indx, 1);

            this.selected_users.forEach((s) => {
                if(s.user_id == user_id){
                    this.selected_users.splice(i,1);
                }
                i++;
            });
        }
        let j=0;
        this.users_list.forEach((u) => {
            if(this.selected_user_ids.indexOf(u.user_id) > -1){
                this.users_list[j].isChecked = true;
            }else{
                this.users_list[j].isChecked = false;
            }
            j++;
        });
        console.log("aaa selected_user_ids: "+this.selected_user_ids);
        console.log("selected_users", this.selected_users);
    }  


    async delete_tagged_user(index) {
        let row = this.tagged_users[index];
        const alert = await this.alertCtrl.create({
            header: "Remove",
            // subHeader: 'Subtitle',
            cssClass: 'alertCancel', 
            message: '<img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
            buttons: [{
                text: '',
                handler: () => {
                    let sendData = {
                        token : this.loginInfo.token,
                        post_id : this.post_id,
                        tagged_user_id : row.user_id,
                    }
                    console.log("sendData:", sendData);
                    this.serviceForAllService.showLoader();
                    this.serviceForAllService.sendData('remove_tagged_user', sendData).subscribe((response) => {
                        this.serviceForAllService.dismissLoading();
                        console.log("response:", response);
                        if(response['status'] == 'ok'){
                            this.tagged_users.splice(index, 1);
                        }
                        this.serviceForAllService.presentToast(response['msg']);
                    }, (err) => {
                        console.log("err:", err);
                        this.serviceForAllService.dismissLoading();
                        this.serviceForAllService.presentToast(err.error.msg);
                    
                    });
                }
            },
            {
                text: '',
                role: 'cancel',
                handler: () => {

                }
            }],
        });
        await alert.present();
    }
}

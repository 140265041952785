import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { ModalController, MenuController, NavController, LoadingController, AlertController, Events, ToastController } from '@ionic/angular';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { Globalization } from '@ionic-native/globalization/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Platform } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage';
import * as $ from 'jquery';
import * as firebase from "firebase";
// import { tokenKey } from '@angular/core/src/view';
let wpUrl = 'https://app.tagster.com/';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
    providedIn: 'root'
})
export class ServiceForAllService {

    loading: any;
    url = 'https://app.tagster.com/wp-json/wp/v2/';
    url2 = 'https://app.tagster.com/wp-json/mobileapi/v1/';

    totalPosts = null;
    pages: any;

    iana_timezone: any;
    one_id: any;
    token: any;
    deviceData: any = [];
    deviceToken: any;

    ip_address: any = '';

    isPlatformAndroidIos: boolean;

    allpopovers : any = [];

    constructor(private http: HttpClient,
        public loadingCtrl: LoadingController,
        private globalization: Globalization,
        private oneSignal: OneSignal,
        public nativeStorage: NativeStorage,
        private platform: Platform,
        public alertCtrl: AlertController,
        private toastController: ToastController,
        public router: Router,
        private device: Device,
        public storage: Storage,
    ) {

        if (this.platform.is('cordova')) {
            this.isPlatformAndroidIos = true;
            console.log("isPlatformAndroidIos1=", this.isPlatformAndroidIos)
        } else {
            this.isPlatformAndroidIos = false;
            console.log("isPlatformAndroidIos2=", this.isPlatformAndroidIos)
        }

        this.getIp();
    }

    open_in_new_tab(url: string) {
        console.log('url', url)
        window.open(url, "_blank");
    }
    // goto_other_user_profile_page(){

    // }
    // (click)="goto('/other-account/'+ol.user_id)"

    getIp() {
        console.log("---->>>>>>>>>>");
        $.getJSON('http://ip-api.com/json?callback=?', (data) => {
            console.log(JSON.stringify(data, null, 2));
            let r: any = [];
            r = data;
            this.ip_address = r.query;
            console.log("this.ip_address::::dddddddddddd ", r.query);
            return r.query;
        });

        //   setTimeout(() => {
        //     console.log("this.ip_address::: SSETTT", this.ip_address);
        //   }, 5000);
    }

    getURL() {
        return wpUrl;
    }


    async presentToast(text) {
        const toast = await this.toastController.create({
            message: text,
            position: 'bottom',
            duration: 3000
        });
        toast.present();
    }

    async presentAlert(msg, title: any = '' ,page : any = '') {
        if (title == '') { title = "Alert"; }
        let alert = await this.alertCtrl.create({
            header: title,
            message: msg,
            buttons: [{
                text: 'OK',
                handler: () => { 
                    if(page != ''){
                        this.router.navigate([page]);
                    }
                }
            }]
        });
        await alert.present();
    }


    async presentAlertConfirm() {
        const alert = await this.alertCtrl.create({
            header: 'Discard Changes?',
            // subHeader: 'Subtitle',
            cssClass: 'alertCancel',
            message: '<img src="assets/question.png" class="question"/> <img src="assets/w-check.png" /> <img src="assets/w-cancel.png" />',
            //buttons: ['OK'],
        });
        await alert.present();
    }

    async showAlertCustom(msg, title: any = '', classname: any = '', redirectpath: any = '') {
        const alert = await this.alertCtrl.create({
            header: title,
            cssClass: classname,
            message: msg,
        });
        await alert.present();

        // alert.onDidDismiss(); 

        const result = await alert.onDidDismiss();
        console.log(result);
        if (result && redirectpath != '') {
            this.router.navigate([redirectpath]);
        }

    }

    async presentAlertBlank(msg) {
        let alert = await this.alertCtrl.create({
            message: msg,
            buttons: [{
                text: 'OK',
                handler: () => { }
            }]
        });
        await alert.present();
    }

    async showLoader(msg: string = '') {
        if (msg == "") {
            msg = 'Please wait...';
        }
        this.loading = await this.loadingCtrl.create({ message: msg });
        await this.loading.present();
    }
    async dismissLoading() {
        console.log(this.loading);
        if (this.loading) {
            await this.loading.dismiss();
        }

    }

    getPosts(page = 1, userToken: any = '', mypost: number = 0, c: number): Observable<any[]> {
        let category_url = c ? ("&service_category=" + c) : "";
        let options = {
            observe: "response" as 'body',
            params: {
                per_page: '10',
                page: '' + page
            }
        };

        return this.http.get<any[]>(this.url + 'beacon_services?_embed&token=' + userToken + "&mypost=" + mypost + category_url, options).pipe(
            map(resp => {
                this.pages = resp['headers'].get('x-wp-totalpages');
                this.totalPosts = resp['headers'].get('x-wp-total');

                let data = resp['body'];

                for (let post of data) {
                    post.media_url = post['media_url'];
                }
                return data;
            })
        )
    }

    getPostContent(id) {
        return this.http.get(this.url + 'beacon_services/' + id + '?_embed').pipe(
            map(post => {
                post['media_url'] = post['media_url'];
                return post;
            })
        )
    }

    doRegister(user_data) {
        console.log("SERVICES === ", user_data);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/register', {
            username: user_data.username,
            first_name: user_data.first_name,
            last_name: user_data.last_name,
            email: user_data.email,
            password: user_data.password,
            phone_country_code: user_data.phone_country_code,
            phone: user_data.phone,
            email_verified: user_data.email_verified,
            phone_verified: user_data.phone_verified,
            jw_auth_sec: "wivxCNm$<(+WF#7}1]TWMUl7OUYTGHDJHGRTFD%%#^#GFDGHDHDaU*TxS(r*$F8:akLdapjD[j.g0wgra#c/f,P`1l"
        })
    }



    //register_step1
    doLogin(email, password) {
        console.log("SERVICE == " + email);
        return this.http.post(wpUrl + 'wp-json/jwt-auth/v1/token', {
            username: email,
            password: password,
        })
    }


    doReset(user_login) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/retrieve_password', {
            user_login: user_login
        }).pipe(
            map(user => {
                return user;
            })
        )
    }


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    sendPushNotification(sender_id, receiver_id, message, post_id, notification_type, section) {
        console.log("ggggggg", sender_id + '-' + receiver_id + '-' + message + '-' + post_id + '-' + notification_type + '-' + section);
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/sendPushNotification", {
            sender_id: sender_id,
            receiver_id: receiver_id,
            message: message,
            post_id: post_id,
            notification_type: notification_type,
            section: section
        });
    }


    CreateStripeUser(token, stripeToken, type: number = 0) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/CreateStripeUser', {
            token: token,
            stripeToken: stripeToken,
            type: type
        });
    }

    addCardtoBank(token, stripeToken, kycdetails = '') {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/add_debit_card', {
            token: token,
            type: 'card',
            stripeToken: stripeToken,
            kycdetails: kycdetails
        });
    }

    manageKYC(token, kycdetails) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/manage_kyc', {
            token: token,
            kycdetails: kycdetails
        });
    }

    //StripeAddBankAccount


    StripeAddBankAccount(token, bank_new, type: number = 0) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/StripeAddBankAccount', {
            token: token,
            bank_new: bank_new,
            type: type
        });
    }

    GetCards(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/StripeGetCards', {
            token: token,
        })
    }

    addContact(feed, user, service) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/create_contact', {
            name: feed.name,
            post_content: feed.post_content,
            email: feed.email,
            contact_number: feed.contact_number,
            service: service,
            token: user.token
        }).pipe(
            map(user => {
                return user;
            })
        )
    }


    getUser(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/GetUser', {
            token: token
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    getAllUser(token, userpage, username: string = '', searchType: string = '') {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_all_users', {
            token: token,
            username: username,
            searchType: searchType,
            page_no: userpage
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    searchAllUser(token, userpage, username: string = '', searchType: string = '', searchFilter = []) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/search_all_users', {
            token: token,
            username: username,
            searchType: searchType,
            page_no: userpage,
            searchFilter: searchFilter
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    //   validateUserForPay(token, user_id) {
    //     return this.http.post(wpUrl + 'wp-json/mobileapi/v1/validateUserForPay', {
    //       token: token,
    //       to_user_id: user_id
    //     }).pipe(
    //       map(user => {
    //         return user;
    //       })
    //     )
    //   }


    // checkEmailVerification(token) {
    //   return this.http.post(wpUrl + 'wp-json/mobileapi/v1/checkEmailVerification', {
    //     token: token
    //   }).pipe(
    //     map(user => {
    //       return user;
    //     })
    //   )
    // }


    verify_email(user_id, vcode) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/verify_email', {
            user_id: user_id,
            vcode: vcode
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    resendVcode(user_email) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/resendVcode', {
            user_email: user_email
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    // resendVcodeSms(user_phone) {
    //   return this.http.post(wpUrl + 'wp-json/mobileapi/v1/send_verification_sms', {
    //       user_phone: user_phone
    //   }).pipe(
    //     map(user => {
    //       return user;
    //     })
    //   )
    // }

    resendVcodeSms(sendData) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/send_verification_sms', sendData).pipe(
            map(user => {
                return user;
            })
        )
    }


    resendAllcodes(user_email, phone) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/resendAllcodes', {
            user_email: user_email,
            phone: phone
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    ladger_request(token, details) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/ladger_request', {
            token: token,
            to_user_id: details.to_user_id,
            amount: details.Amount,
            Currency: details.Currency,
            notes: details.notes
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    get_all_transactions(token, dataPost) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_all_transactions', {
            token: token,
            filter: dataPost.filter,
            is_pdf: dataPost.is_pdf
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    getfavorites(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/favorites', {
            token: token,
            action_type: 'view'
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    // GetStripeAuthToken(token, details) {
    //   return this.http.post(wpUrl + 'wp-json/mobileapi/v1/CreateStripeCapture', {
    //     token: token,
    //     Currency: details.Currency,
    //     Card: details.Card,
    //     Amount: details.Amount,
    //     notes: details.notes,
    //     to_user_id: details.to_user_id,
    //     // event_id: details.event_id,
    //     // request_id: details.request_id,
    //     // taxable: details.taxable,
    //     extra_data: details.extra_data
    //   }).pipe(
    //     map(data => {
    //       return data;
    //     })
    //   )
    // }

    GetStripeAuthToken(token, details) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/send_tag_requests', {
            token: token,
            receiver_user_id: details.to_user_id,
            currency: details.Currency,
            card: details.Card,
            amount: details.Amount,
            notes: details.notes,
            sender_tag_id: details.extra_data.sent_from_tag_id,
            receiver_tag_id: details.extra_data.tag,
            you_raok: details.extra_data.you_raok,
            state: details.extra_data.state,
            tag_category: details.extra_data.tag_category,
            other_type: details.extra_data.other_type,
            message: details.extra_data.message,
            share_details: details.extra_data.share_details
        }).pipe(
            map(data => {
                return data;
            })
        )
    }

    socialUpdateProfile(user_data, token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/update_social_profile', {
            token: token,
            user_data: user_data,
        })
    }

    updateProfile(user_data, token, company_exist, is_email_verified, is_phone_verified) {
        console.log("SERVICES === ", user_data);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/doProfileUpdate', {
            token: token,
            user_data: user_data,
            company_exist: company_exist,
            is_email_verified: is_email_verified,
            is_phone_verified: is_phone_verified
        })
    }


    changePassword(changePassword, token) {
        console.log(changePassword);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/changePassword', {
            token: token,
            password: changePassword.password,
            confirm_password: changePassword.confirm_password,
        })
    }

    checkPassword(username, old_password) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/checkPassword', {
            username: username,
            old_password: old_password,
        })
    }


    getPageContent(page: number = 1) {
        //if we want to query posts by category
        return this.http.get(
            wpUrl +
            'wp-json/wp/v2/pages/' + page)
            .pipe(
                retry(2),
                map(content => {
                    return content;
                })
            )
    }







    get_notification_list(userToken) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_notification_list', {
            token: userToken,
            jw_auth_sec: "wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    saveOneSignID(token, oneSignID) {
        this.globalization.getDatePattern({ formatLength: 'string', selector: 'timezone and utc_offset' }).
            then(res => {
                this.iana_timezone = res.iana_timezone
                this.http.post(wpUrl + 'wp-json/mobileapi/v1/save_onesignal_id', {
                    oneSignID: oneSignID,
                    token: token,
                    type: 'ios',
                    timezone: this.iana_timezone
                })
            })
            .catch(e => console.log(e));

        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/save_onesignal_id', {
            oneSignID: oneSignID,
            token: token,
            type: 'ios',
            timezone: this.iana_timezone
        });
    }

    SaveAutoConfiqure(token) {
        console.log(token);
        if (this.platform.is('cordova')) {
            this.oneSignal.getIds().then((id) => {
                this.one_id = id.userId;
                this.token = token;
                this.saveOneSignID(this.token, this.one_id).subscribe(m => {
                });
            });
        }

    }


    //   updateToken(userToken, deviceID, deviceData, status) {

    //     return this.http.post(wpUrl + "wp-json/mobileapi/v1/updateDeviceToken", {
    //       token: userToken,
    //       deviceID: deviceID,
    //       deviceData: deviceData,
    //       status: status
    //     })
    //   }


    setSetting(setting) {
        if (this.platform.is('cordova')) {
            //return this.nativeStorage.setItem('fancase_user', user);
            this.nativeStorage.setItem('setting', setting)
                .then(
                    () => console.log('Stored item!'),
                    error => console.error('Error storing item', error)
                );
        }
    }

    getStoreSetting() {
        if (this.platform.is('cordova')) {
            return this.nativeStorage.getItem('setting');
        }
    }


    GetSetting(sendData: any = null) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/GetSetting', sendData);
    }

    GetTwoFactorData(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/GetTwoFactorData', {
            token: token
        });
    }

    getData(endPoint) {
        return this.http.get(this.url2 + endPoint).pipe(
            map(data => {
                return data;
            })
        )
    }

    sendData(endPoint, data) {
        return this.http.post(this.url2 + endPoint, data).pipe(
            map(data => {
                return data;
            })
        )
    }

    validate_code(token, text) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/validate_code', {
            token: token,
            text: text
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getCurrentUserInfo(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getCurrentUserInfo', {
            token: token
        })
    }
    getSecoondUserInfo(token, id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getSecoondUserInfo', {
            token: token,
            id: id
        })
    }

    getSecoondUserInfo1(token, id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getSecoondUserInfo1', {
            token: token,
            id: id,

        })
    }

    doFBSignup(obj) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/facebook_login', {
            email: obj.email,
            nickname: obj.email,
            username: obj.email,
            id: obj.id,
            fbname: obj.name,
            type: obj.type,
            password: ""
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    //   updateCard(token,card,card_new,type){
    //     return this.http.post(wpUrl+ "wp-json/mobileapi/v1/updateCard",{
    //       token:token,
    //       card:card,
    //       name:card_new.name,
    //       expMonth:card_new.expMonth,
    //       expYear:card_new.expYear,
    //       type:type,
    //       default:card_new.default
    //     }).pipe(
    //       retry(2),
    //       map(content => {
    //         return content;
    //       })
    //     )
    //   }

    removeCard(token, card, type) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/DeleteCard", {
            token: token,
            card: card,
            type: type
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }


    careateTag(form_data, token, tag_id) {
        console.log("FORM VALUE === ", form_data);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/create_tag', {
            token: token,
            form_data: form_data,
            tag_id: tag_id
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    createDocumentPost(form_data, token) {
        console.log("FORM VALUE === ", form_data);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/create_doc_post', {
            token: token,
            form_data: form_data
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getMyTags(sendData) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_my_tags', sendData).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    deleteTag(tag_id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/deleteTag', {
            tag_id: tag_id
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }


    setDefaultTag(tag_id, token, action) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/setDefaultTag', {
            tag_id: tag_id,
            token: token,
            action: action
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }


    removeImage(token, img) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/removeImage', {
            token: token,
            img: img
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    deleteCompany(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/deleteCompany', {
            token: token
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    sendFeedback(form_data, token) {
        console.log("form_data === ", form_data);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/send_feedback', {
            token: token,
            name: form_data.name,
            email: form_data.email,
            message: form_data.message
        })
    }


    touchLogin(user_id) {
        console.log("user_id === ", user_id);
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/touch_login', {
            user_id: user_id,
        })
    }

    //////
    acceptReq(token, request_id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/acceptReq', {
            token: token,
            request_id: request_id
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    acceptAllReq(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/acceptAllReq', {
            token: token
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    denyReq(token, request_id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/denyReq', {
            token: token,
            request_id: request_id
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }


    unFriend(token, request_id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/unFriend', {
            token: token,
            request_id: request_id
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getFriendRequests(token, page_no) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getFriendRequests', {
            token: token,
            page_no: page_no
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getMyFriends(token, page_no) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getMyFriends', {
            token: token,
            page_no: page_no
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getUserImage(senderId, recevierId) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getUserImage', {
            senderId: senderId,
            recevierId: recevierId
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }



    modifiedChatData(msg_data) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/modifiedChatData', {
            msg_data: msg_data
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    changeValue(token, meta_key, meta_value) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/changeValue', {
            token: token,
            meta_key: meta_key,
            meta_value: meta_value
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    setStatus(token, story_id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/setStatus', {
            token: token,
            story_id: story_id
        });
    }

    getTagsRequests(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_tag_requests', {
            token: token
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    GetTagRequestDeatils(token, request_id) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/GetTagRequestDeatils', {
            token: token,
            request_id: request_id
        })
    }

    change_request_status(token, request_id, status) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/change_request_status', {
            token: token,
            request_id: request_id,
            status: status
        })
    }

    getNewsFeeds() {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_news', {
            // token: token
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    getContactSettingsData(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/get_contact_snc_settings', {
            token: token
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    saveContactSettingsData(token, connect_contacts, allow_contact_discovery) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/save_contact_snc_settings', {
            token: token,
            connect_contacts: connect_contacts,
            allow_contact_discovery: allow_contact_discovery
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    //   ConnectWithFacebook(sendData){
    //     console.log("ConnectWithFacebook",  sendData);
    //     return this.http.post(wpUrl + 'wp-json/mobileapi/v1/connect_with_facebook', sendData)
    //   }

    //   ConnectWithGoogle(sendData){
    //     console.log("ConnectWithGoogle",  sendData);
    //     return this.http.post(wpUrl + 'wp-json/mobileapi/v1/connect_with_google', sendData)
    //   }

    ////


    //[START]=> By Su
    upload_image(data) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/upload_image', data).pipe(
            map(data => {
                return data;
            })
        )
    }
    upload_video(data) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/upload_video', data).pipe(
            map(data => {
                return data;
            })
        )
    }

    login_with_social_media(platform, sendData) {
        let end_point = '';
        if (platform == 'facebook') {
            end_point = "login_with_facebook";

        } else if (platform == 'instagram') {
            end_point = "login_with_instagram";

        } else if (platform == 'twitter') {
            end_point = "login_with_twitter";

        }
        // else if(platform == 'linkedin'){
        //     end_point = "login_with_linkedin";

        // }
        else if (platform == 'google') {
            end_point = "login_with_google";

        }
        return this.postData(end_point, sendData);
    }

    connect_with_social_media(platform, sendData) {
        let end_point = '';
        if (platform == 'facebook') {
            end_point = "connect_with_facebook";
        } else if (platform == 'instagram') {
            end_point = "connect_with_instagram";
        } else if (platform == 'twitter') {
            end_point = "connect_with_twitter";
        }
        return this.postData(end_point, sendData);
    }

    postData(endpoint, sendData) {
        return this.http.post(this.url2 + endpoint, sendData);
    }
    add_card(sendData) {
        return this.sendData('add_card', sendData);
    }
    // getStripeBanksCards(sendData) {
    //     return this.sendData('get_stripe_banks_cards', sendData);
    // }
    saveStripeAccountId(sendData) {
        return this.postData('save_stripe_account_id', sendData);
    }
    delete_card(sendData) {
        return this.sendData('delete_card', sendData);
    }
    get_user_informations(sendData) {
        return this.sendData('get_user_informations', sendData);
    }
    get_recent_stories(sendData) {
        return this.sendData('get_recent_stories', sendData);
    }
    get_stories(sendData) {
        return this.sendData('get_stories', sendData);
    }
    // do_follow_unfollow(sendData){
    //     return this.postData('do_follow_unfollow', sendData);
    // }
    create_update_hightlight(sendData) {
        return this.postData('create_update_hightlight', sendData);
    }
    get_highlights(sendData) {
        return this.postData('get_highlights', sendData);
    }
    do_block_unblock(sendData) {
        return this.postData('do_block_unblock', sendData);
    }


    do_block_myself(sendData) {
        return this.postData('do_block_myself', sendData);
    }

    create_new_story(sendData) {
        return this.postData('create_new_story', sendData);
    }
    submitForm(formId, formdata, user_id, user_token) {
        return this.http.post(this.url2 + "submitForm", {
            formId: formId,
            formdata: formdata,
            user_id: user_id,
            user_token: user_token
        })
    }

    firebaseAuthToken(email, password) {
        // let email = 'mainuser@knoxweb.com';
        // let password = 'sR123456';
        firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {

            console.log('firebase login = ', { user });

        }).catch((err: any = []) => {

            if (err.code == 'auth/user-not-found') {
                firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                    console.log('firebase account created = ', { user });
                    this.firebaseAuthToken(email, password);
                });
            }

        });
    }

    firebaseSignOut() {
        firebase.auth().signOut();
    }


    get_countries(sendData) {
        return this.postData('get_countries', sendData);
    }
    get_states(sendData) {
        return this.postData('get_states', sendData);
    }
    get_cities(sendData) {
        return this.postData('get_cities', sendData);
    }
    get_taxonomies(sendData) {
        return this.postData('get_taxonomies', sendData);
    }
    store_request(sendData) {
        return this.postData('store_request', sendData);
    }
    create_new_feed(sendData) {
        return this.postData('create_new_feed', sendData);
    }
    update_feed(sendData) {
        return this.postData('update_feed', sendData);
    }
    get_feeds(sendData) {
        return this.postData('get_feeds', sendData);
    }
    seen_story(sendData) {
        return this.postData('see_story', sendData);
    }
    delete_story(sendData) {
        return this.postData('delete_story', sendData);
    }
    GetFinancialResources(sendData) {
        return this.sendData('get_financial_resources', sendData);
    }
    make_as_default(sendData) {
        return this.postData('make_as_default', sendData);
    }
    send_kindness(sendData) {
        return this.postData('send_kindness', sendData);
    }
    get_kindness(sendData) {
        return this.postData('get_kindness', sendData);
    }
    send_feedback(sendData) {
        return this.postData('send_feedback', sendData);
    }
    get_block_users(sendData) {
        return this.postData('get_block_users', sendData);
    }
    get_yourgeo_users(sendData) {
        return this.postData('get_yourgeo_users', sendData);
    }
    getSocialNotifications(sendData) {
        return this.postData('get_notifications', sendData);
    }
    send_contribution(sendData) {
        return this.postData('send_contribution', sendData);
    }
    sendFriendRequest(sendData) {
        return this.postData('sendFriendRequest', sendData);
    }

    cancelSentFriendRequest(sendData) {
        return this.postData('cancelSentFriendRequest', sendData);
    }
    getComments(sendData) {
        return this.postData('get_comments', sendData);
    }

    getChildComments(sendData) {
        return this.postData('get_child_comments', sendData);
    }
    update_card(sendData) {
        return this.postData('update_card', sendData);
    }
    save_update_social_links(sendData) {
        return this.postData('save_update_social_links', sendData);
    }
    get_social_links(sendData) {
        return this.postData('get_social_links', sendData);
    }
    get_grapevine_contributors(sendData) {
        return this.postData('get_grapevine_contributors', sendData);
    }

    get_my_grapevine(sendData) {
        return this.postData('get_my_grapevine', sendData);
    }

    getAllFriends(sendData) {
        return this.postData('getAllFriends', sendData);
    }

    GetTransactions(sendData) {
        sendData['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.postData('get_transactions', sendData);
    }

    //[END]=> By Su
    setOneSignalId(user_id) {
        this.oneSignal.getIds().then((data) => {
            this.deviceToken = data.userId;
            this.updateOSToken(user_id, this.deviceToken);
        });
    }

    updateOSToken(userId, deviceID) {

        if (this.ip_address && this.ip_address != undefined && this.ip_address != '') {
            this.updateOSTokenPost(userId, deviceID, this.ip_address);
        }
        else {
            this.updateOSTokenPost(userId, deviceID, '');
        }
        // this.storage.get('IP_ADDRESS').then((val) => {
        //     console.log('userData1: ', val);
        //     if (val != null && val!=undefined) {
        //         this.updateOSTokenPost(userId, deviceID, val);
        //     }
        //     else{
        //         this.updateOSTokenPost(userId, deviceID, '');
        //     }
        // },(err)=>{
        //     this.updateOSTokenPost(userId, deviceID, '');
        // });
    }

    updateOSTokenPost(userId, deviceID, ip_address) {

        console.log("this.ip_address:::: ", ip_address);
        this.deviceData = [];
        this.deviceData.push({
            uuid: this.device.uuid,
            model: this.device.model,
            platform: this.device.platform,
            version: this.device.version,
            offset: new Date().getTimezoneOffset(),
            deviceToken: deviceID,
            logindate: new Date(),
            ip_address: ip_address
        });
        console.log(this.deviceData, 'userToken', deviceID, userId);
        this.updateToken(userId, deviceID, this.deviceData, 'login')
            .subscribe(res => {
                console.log('success');
            });
    }

    updateToken(userId, deviceID, deviceData, status) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/updateDeviceToken", {
            userid: userId,
            deviceID: deviceID,
            deviceData: deviceData,
            status: status
        });
    }

    // Logout OST
    removeToken(user_id) {
        this.deviceData = [];
        this.deviceData.push({
            uuid: this.device.uuid,
            model: this.device.model,
            platform: this.device.platform,
            version: this.device.version,
        });
        this.updateToken(user_id, '', this.deviceData, 'logout')
            .subscribe(res => {
                console.log(res);
            });
    }


    updateSearches(search_by, item_id, item_type) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/updateSearches", {
            search_by: search_by,
            item_id: item_id,
            item_type: item_type
        });
    }

    getRecentSearches(token) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/getRecentSearches", {
            token: token
        });
    }


    get_feed_attachments(sendData) {
        return this.postData('get_feed_attachments', sendData);
    }
    get_notfications(sendData) {
        return this.postData('get_notfications', sendData);
    }


    //////E

    deletePhoneNumber(token) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/deletePhoneNumber", {
            token: token
        });
    }

    getAllGrapevines(sendData) {
        return this.postData('get_all_grapevines', sendData);
    }


    GetLanguageSetting(token) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/GetLanguageSetting", {
            token: token
        });
    }

    SetLanguageSetting(token, language) {
        return this.http.post(wpUrl + "wp-json/mobileapi/v1/SetLanguageSetting", {
            token: token,
            language: language
        });
    }

    language_request(sendData) {
        return this.postData('language_request', sendData);
    }

    lisOfPlans() {
        return this.http
            .get(wpUrl + "wp-json/pmpro/v1/listofplans")
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    SubscribePlan(payDetails) {
        console.table(payDetails);
        let header = new HttpHeaders({ 'Content-Type': 'application/json' });
        header.append('user-id', payDetails.userid);
        header.append('access-token', payDetails.token);
        let options = { headers: header };
        console.log(header.get('user-id'), header.get('access-token'));

        return this.http.post(wpUrl + '/wp-json/pmpro/v1/subscribeuser', {
            //return this.http.post(wpUrl + 'wp-json/pmpro/v1/subscribeuser',{
            planid: payDetails.planid,
            userid: payDetails.userid,
            card_number: payDetails.card_number,
            exp_month: payDetails.exp_month,
            exp_year: payDetails.exp_year,
            cvc: payDetails.cvc,
            action_type: payDetails.action_type,
            card_id: payDetails.card_id
        }, options);
    }

    suggested_users(sendData) {
        return this.postData('suggested_users', sendData);
    }


    GetNotiSettings(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/GetNotiSettings', {
            token: token
        });
    }



    getMemberLevel(user_id) {
        return this.http.get(wpUrl + 'wp-json/pmpro/v1/getlevelforuser/' + user_id).pipe(
            map(user => {
                return user;
            })
        )
    }

    getPremiumData(token) {
        return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getPremiumData', {
            token: token
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    // https://tagster.com///wp-json/pmpro/v1/
    get_payment_method(sendData) {
        return this.http.post(wpUrl + 'wp-json/pmpro/v1/get_payment_method', sendData).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    cancel_subscription(sendData) {
        return this.http.post(wpUrl + 'wp-json/pmpro/v1/cancelsubscription', sendData).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }
    getCircularReplacer() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    }

    generateThumbnailFromVideo = (file: File) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    }


    deleteChatMessages(selected_message_ids :any ,user_id,other_user_id){
        console.log('selected_message_ids:',selected_message_ids);
        // MINE 
        console.log("rf1:", 'tagster_chat/' + user_id + '/' + user_id + 'to' + other_user_id + '/messages');
        firebase.database().ref('tagster_chat/' + user_id + '/' + user_id + 'to' + other_user_id + '/messages').once('value', data1 => {
            data1.forEach(data => {
                console.log("data:",data.val().message_id);
                let find_val = selected_message_ids.find(mid => mid == data.val().message_id);
                if(find_val && find_val.message_id!=''){
                    console.log("find_val:",find_val);
                    let m = { 
                        removed: 1
                    };
                    firebase.database().ref('tagster_chat/' + user_id + '/' + user_id + 'to' + other_user_id + '/messages/' + data.key).update(m);
                }
            });
        });

        // OTHER 
        console.log("rf2:",'tagster_chat/' + other_user_id + '/' + other_user_id + 'to' +user_id + '/messages');
        firebase.database().ref('tagster_chat/' + other_user_id + '/' + other_user_id + 'to' +user_id + '/messages').once('value', data2 => {
            data2.forEach(data => {
                let find_val2 = selected_message_ids.find(mid => mid == data.val().message_id);
                if(find_val2 && find_val2.message_id!=''){
                    console.log("find_val2:",find_val2);
                    let m = { 
                        removed: 1
                    };
                    firebase.database().ref('tagster_chat/' + other_user_id + '/' + other_user_id + 'to' + user_id + '/messages/' + data.key).update(m);
                }
            }); 
        });

        return true;
    }

    getDeviceTimezoe() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

}

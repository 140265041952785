import { Component, NgZone } from '@angular/core';
import { Platform, NavController, AlertController, MenuController, Events, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { OneSignal, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { Router, NavigationExtras } from '@angular/router';
import * as firebase from 'firebase';
import { ServiceForAllService } from './service-for-all.service';
import { CustomSplashPage } from './custom-splash/custom-splash.page';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

// import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { TranslateConfigService } from './translate-config.service';
import { ContactsyncPage } from '../app/contactsync/contactsync.page';
import { ContactUsPage } from 'src/app/contact-us/contact-us.page';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    public appPages = [];
    isGuestUser: any = '';
    user: any;
    total_unread_home: any = 0;
    total_unread_social: any = 0;
    total_unread_messages: any = 0;

    idType: any = 'Touch ID';

    onlineRef: any;
    online: any = 1;
    activeTab: any = '0';
    caller_data: any;

    plan: any = [];

    constructor(
        private platform: Platform,
        public storage: Storage,
        public alertController: AlertController,
        public menu: MenuController,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private navController: NavController,
        private oneSignal: OneSignal,
        public serviceForAllService: ServiceForAllService,
        public events: Events,
        public modalCtrl: ModalController,
        public zone: NgZone,
        // private deeplinks: Deeplinks,
        private faio: FingerprintAIO,
        private translateConfigService: TranslateConfigService
    ) {
        this.activeTab = '0';
        this.initializeApp();
        // https://tagster.com//
        // cordova plugin add ionic-plugin-deeplinks --variable URL_SCHEME=tagster --variable DEEPLINK_SCHEME= http --variable DEEPLINK_HOST=tagster.betaplanets.com

        window.addEventListener('keyboardWillShow', () => {
            this.zone.run(() => {
                this.events.publish('addClassCustom', 'another-class');
                // $('#ekey').val(this.className);
            })
        });
        window.addEventListener('keyboardDidShow', () => {
            this.zone.run(() => {
                this.events.publish('addClassCustom', 'another-class');
            })
        });
        window.addEventListener('keyboardWillHide', () => {
            this.zone.run(() => {
                this.events.publish('addClassCustom', '');
            })
        });
        window.addEventListener('keyboardDidHide', () => {
            this.zone.run(() => {
                this.events.publish('addClassCustom', '');
            })
        });
        this.events.subscribe('check_online_status_of_login_user', (response) => {
            console.log("check_online_status_of_login_user()->response:", response);
        });

        this.events.subscribe('userCheck:created', (val) => {
            console.log("userCheck: ", val);
            if (val && val != 'userNotLogin') {
                this.getUserProfile(val.token);

                this.CheckUserMembership(val.user_id);

                this.checkIncomingCall();

                this.translateConfigService.getDefaultLanguage();

                this.appPages = [
                    {
                        title: 'Home',
                        url: 'tabs/dashboard',
                        icon: 'home',
                        image: 'assets/menu/home.png',
                        imageActive: 'assets/menu/home-active.png'
                    },
                    {
                        title: 'Financial Settings',
                        url: 'financial-settings',
                        icon: 'wallet',
                        image: 'assets/menu/financialsetting.png',
                        imageActive: 'assets/menu/financialsetting-active.png'
                    },
                    {
                        title: 'Wallet',
                        url: 'wallet',
                        icon: 'wallet',
                        image: 'assets/menu/financialsetting.png',
                        imageActive: 'assets/menu/financialsetting-active.png'
                    },
                    {
                        title: 'Change Password',
                        url: 'check-pass',
                        icon: 'lock',
                        image: 'assets/menu/changepassword.png',
                        imageActive: 'assets/menu/changepassword-active.png'
                    },
                    {
                        title: 'Feedback',
                        url: 'feedback',
                        icon: 'lock',
                        image: 'assets/menu/feedback.png',
                        imageActive: 'assets/menu/feedback-active.png'
                    },
                    {
                        title: 'Personal Tags',
                        url: 'tags',
                        icon: 'pricetags',
                        image: 'assets/menu/personaltags.png',
                        imageActive: 'assets/menu/personaltags-active.png'
                    },
                    {
                        title: 'Get Social',
                        url: 'social-media-dashboard',
                        icon: 'people',
                        image: 'assets/menu/getsocial.png',
                        imageActive: 'assets/menu/getsocial-active.png'
                    },
                    {
                        title: 'Contact Us',
                        url: '/contact-us',
                        icon: 'information-circle',
                        image: 'assets/menu/contactus.png',
                        imageActive: 'assets/menu/contactus-active.png'
                    },
                    {
                        title: 'Go Premium',
                        url: '/gopremium',
                        icon: 'happy',
                        image: 'assets/menu/gopremium.png',
                        imageActive: 'assets/menu/gopremium-active.png'
                    },
                    {
                        title: 'About',
                        url: '/aboutus',
                        icon: 'document',
                        image: 'assets/menu/about.png',
                        imageActive: 'assets/menu/about-active.png'
                    },
                    {
                        title: 'Settings',
                        url: '/setting',
                        icon: 'settings',
                        image: 'assets/menu/setting.png',
                        imageActive: 'assets/menu/setting-active.png'
                    },
                    {
                        title: 'Make Us Better',
                        url: '/sendfeedback',
                        icon: 'information-circle',
                        image: 'assets/menu/smile.png',
                        imageActive: 'assets/menu/smile-active.png'
                    },
                    {
                        title: 'Store Request',
                        url: '/store-request',
                        icon: 'appstore',
                        image: 'assets/menu/store.png',
                        imageActive: 'assets/menu/store-active.png'
                    },
                    // {
                    //     title: 'VIP Request',
                    //     url: '/vip-request',
                    //     icon: 'appstore',
                    //     image: 'assets/menu/store.png',
                    //     imageActive: 'assets/menu/store-active.png'
                    // }
                ];

                this.getUnreadMessageCount();
                this.initiateFBOnline();
            }

        });


        this.events.subscribe('isGuest', (val) => {
            this.isGuestUser = val;
            if (this.isGuestUser == 1) {

                this.appPages = [
                    {
                        title: 'Home',
                        url: 'tabs/dashboard',
                        icon: 'list',
                        image: 'assets/menu/home.png',
                        imageActive: 'assets/menu/home-active.png'
                    },
                    {
                        title: 'About Us',
                        url: '/aboutus',
                        icon: 'list',
                        image: 'assets/menu/about.png',
                        imageActive: 'assets/menu/about-active.png'
                    }
                ];
            }
        });


        this.events.subscribe('membershipRef', (data) => {
            let gopre = '/gopremium';
            if (data && data != '') {
                gopre = '/subscription-informations';
            }


            this.appPages = [
                {
                    title: 'Home',
                    url: 'tabs/dashboard',
                    icon: 'home',
                    image: 'assets/menu/home.png',
                    imageActive: 'assets/menu/home-active.png'
                },
                {
                    title: 'Financial Settings',
                    url: 'financial-settings',
                    icon: 'wallet',
                    image: 'assets/menu/financialsetting.png',
                    imageActive: 'assets/menu/financialsetting-active.png'
                },
                {
                    title: 'Wallet',
                    url: 'wallet',
                    icon: 'wallet',
                    image: 'assets/menu/financialsetting.png',
                    imageActive: 'assets/menu/financialsetting-active.png'
                },
                {
                    title: 'Change Password',
                    url: 'check-pass',
                    icon: 'lock',
                    image: 'assets/menu/changepassword.png',
                    imageActive: 'assets/menu/changepassword-active.png'
                },
                {
                    title: 'Feedback',
                    url: 'feedback',
                    icon: 'lock',
                    image: 'assets/menu/feedback.png',
                    imageActive: 'assets/menu/feedback-active.png'
                },
                {
                    title: 'Personal Tags',
                    url: 'tags',
                    icon: 'pricetags',
                    image: 'assets/menu/personaltags.png',
                    imageActive: 'assets/menu/personaltags-active.png'
                },
                {
                    title: 'Get Social',
                    url: 'social-media-dashboard',
                    icon: 'people',
                    image: 'assets/menu/getsocial.png',
                    imageActive: 'assets/menu/getsocial-active.png'
                },
                {
                    title: 'Contact Us',
                    url: '/contact-us',
                    icon: 'information-circle',
                    image: 'assets/menu/contactus.png',
                    imageActive: 'assets/menu/contactus-active.png'
                },
                {
                    title: 'Go Premium',
                    url: gopre,
                    icon: 'happy',
                    image: 'assets/menu/gopremium.png',
                    imageActive: 'assets/menu/gopremium-active.png'
                },
                {
                    title: 'About',
                    url: '/aboutus',
                    icon: 'document',
                    image: 'assets/menu/about.png',
                    imageActive: 'assets/menu/about-active.png'
                },
                {
                    title: 'Settings',
                    url: '/setting',
                    icon: 'settings',
                    image: 'assets/menu/setting.png',
                    imageActive: 'assets/menu/setting-active.png'
                },
                {
                    title: 'Make Us Better',
                    url: '/sendfeedback',
                    icon: 'information-circle',
                    image: 'assets/menu/smile.png',
                    imageActive: 'assets/menu/smile-active.png'
                },
                {
                    title: 'Store Request',
                    url: '/store-request',
                    icon: 'appstore',
                    image: 'assets/menu/store.png',
                    imageActive: 'assets/menu/store-active.png'
                },
                // {
                //     title: 'VIP Request',
                //     url: '/vip-request',
                //     icon: 'appstore',
                //     image: 'assets/menu/store.png',
                //     imageActive: 'assets/menu/store-active.png'
                // }
            ];

        })
    }

    CheckUserMembership(user) {
        this.serviceForAllService.getMemberLevel(user).subscribe(res => {
            this.plan = res;

            console.log("AppComponent->CheckUserMembership-> Plan:", this.plan);

            if (this.plan.membership && this.plan.membership.id != '') {
                this.events.publish('membershipRef', '1');
            }
            else {
                this.events.publish('membershipRef', '');
            }

        });
    }

    getCountries() {
        this.storage.get('countries').then((countries) => {
            if (countries != null) {

            } else {
                let sendData = {}
                this.serviceForAllService.get_countries(sendData).subscribe((response) => {
                    console.log("response:", response);
                    if (response['status'] == 'ok') {
                        console.log("countries:", response['countries']);
                        this.storage.set('countries', response['countries']);
                    }
                })
            }
        });
    }

    async viewContactSync() {
        const modal = await this.modalCtrl.create({
            component: ContactsyncPage,
            cssClass: 'my-custom-sync',
            componentProps: {
            }
        });
        modal.onDidDismiss().then((dataReturned) => {
            console.log('return');
        });
        return await modal.present();
    }

    async showCustomSplash() {
        const modal = await this.modalCtrl.create({
            component: CustomSplashPage,
            cssClass: 'fakespalsh'
        });
        return await modal.present();
    }


    initializeApp() {
        this.platform.ready().then(() => {

            this.faio.isAvailable().then((result: any) => {
                if (result == 'face') {
                    this.idType = 'Face ID';
                }
                else {
                    this.idType = 'Touch ID';
                }
                console.log(result)
            })
                .catch((error: any) => {
                    console.log(error)
                });
            this.showCustomSplash();    

            setTimeout(() => {
                // this.setupDeeplinks();
                this.splashScreen.hide();
            }, 3000);
            this.splashScreen.hide();
            this.storage.get('user').then((userInfo) => {
                if (userInfo != null) {
                    this.getUnreadMessageCount();

                    this.events.publish('userCheck:created', userInfo);
                    this.checkIncomingCall();
                    this.navController.navigateRoot(['/dashboard']);
                    // this.navController.navigateRoot(['/social-user-profile/14']);
                    // this.navController.navigateRoot(['/social-user-profile/86']);
                    // this.navController.navigateRoot(['/setting']);
                    // this.navController.navigateRoot(['/shop']);

                    // this.navController.navigateRoot(['/messages']); //messages
                    this.splashScreen.hide();
                } else {
                    this.events.publish('userCheck:created', 'userNotLogin');
                    this.navController.navigateRoot(['/landing']);
                    console.log("redirect from app.componenent");
                    this.splashScreen.hide();
                }
            });

            this.statusBar.backgroundColorByHexString('#171d4e');
            this.statusBar.overlaysWebView(false);

            this.oneSignal.startInit('f2bcdbb5-805f-4f98-bf9e-be225192f85c');
            //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
            this.oneSignal.handleNotificationReceived().subscribe(data => this.onPushReceived(data.payload));
            this.oneSignal.handleNotificationOpened().subscribe(data => this.onPushOpened(data.notification.payload));
            this.oneSignal.endInit();

            // setTimeout(()=>{
            //   this.splashScreen.hide();  
            // },1000);

            this.storage.get('user').then((val) => {
                console.log('userData1: ', val);
                if (val != null) {
                    this.user = val;
                    this.getUserProfile(val.token);
                }
            });


            this.storage.get('guestuser').then((val) => {
                this.isGuestUser = val;
            });

            this.events.subscribe('isGuest', (val) => {
                this.isGuestUser = val;
            });
        });

        this.events.subscribe('user_update', (number) => {
            this.storage.get('user').then((val) => {
                if (val != null) {
                    this.user = val;
                    this.getUserProfile(val.token);
                }
            });
        });


        this.events.subscribe('chackeActiveMenu', (number) => {
            console.log('NUMBER ----> ', number);

            this.zone.run(() => {
                this.activeTab = number;
            })
        });
        this.getCountries();
    }


    getUnreadMessageCount() {
        this.total_unread_messages = 0;
        this.storage.get('user').then((details) => {
            console.log("getUnreadMessageCount->details:", details);
            if (details) {
                firebase.database().ref('tagster_chat/' + details.user_id).orderByKey().endAt("time").on('value', data => {
                    let tmp = [];
                    data.forEach(data => {
                        if (data.val().messages) {
                            tmp.push({
                                key: data.key,
                                values: data.val()
                            })
                        }
                    });

                    this.total_unread_home = 0;
                    for (let msg of tmp) {
                        let keys = msg.values.messages;
                        for (let data in keys) {
                            let msgData = keys[data];
                            if (msgData.unread && msgData.unread > 0) {
                                this.total_unread_home = this.total_unread_home + 1;
                            }

                            // console.log('total_unread_home: ', this.total_unread_home);
                            this.events.publish('notiCountChanged_home', this.total_unread_home);
                        }
                    }
                });
            }
        });
    }

    getUserProfile(token) {
        this.serviceForAllService.getUser(token).subscribe((result) => {
            this.user = result;
        });
    }

    private onPushReceived(payload: OSNotificationPayload) {
        console.log('Push recevied:' + payload.additionalData);
    }

    private onPushOpened(payload: OSNotificationPayload) {

    }

    gotoDashboard() {
        this.menu.close();
    }

    initiateFBOnline() {
        console.log("M HERE");
        this.platform.ready().then(() => {

            this.storage.get('user').then(val => {
                if (val) {
                    this.onlineRef = firebase.database().ref('online_users/' + val.user_id);
                    if (this.onlineRef) {
                        this.onlineRef.once('value', (res: any) => {
                            console.log("res1:", res);
                            console.log("res2->key:", res.key);
                            console.log("res3->val:", res.val());
                            if (res.val() == null || res.val().online_status == 'offline') {
                                this.onlineRef.update({ 'online_status': 'online' });
                            }
                        });

                        // this.onlineRef.update({'online_status' : 'online'});
                        // this.onlineRef.push({ online: 1});


                        this.platform.pause.subscribe(async () => {
                            console.log('Pause event detected');
                            this.onlineRef.update({ 'online_status': 'offline' });
                            // this.onlineRef.remove();
                        });

                        this.platform.resume.subscribe(async () => {
                            console.log('Resume event detected');
                            // this.onlineRef.update({'online_status' : 'online'});
                            // this.onlineRef.push({
                            //   online: 1
                            // });
                        });

                    } else {
                        console.log("I AM IN ERROR!!");
                    }


                    // this.onlineRef = firebase.database().ref('online_users/'+val.user_id).update({'online_status' : 'online'});
                }
            });
        });
    }

    setActiveMenu(i, url) {
        console.log(i, url);
        this.activeTab = i;
    }

    goToPage(page) {
        this.navController.navigateForward(page);
    }


    checkIncomingCall() {
        this.storage.get('user').then(val => {
            if (val !== null) {
                console.log(val);
                firebase.database().ref('call/' + val.user_id).on('child_added', data => {
                    console.log(data.val());
                    if (data.val() !== undefined) {
                        this.caller_data = data.val();
                        let extra_param: any = [];
                        extra_param['token'] = this.caller_data.token;
                        extra_param['session_id'] = this.caller_data.session_id;
                        extra_param['caller_id'] = val.user_id;
                        extra_param['caller_name'] = this.caller_data.user_name;
                        extra_param['caller_img'] = this.caller_data.user_img;
                        extra_param['status'] = this.caller_data.status;
                        extra_param['key'] = data.key;
                        extra_param['type'] = this.caller_data.type;
                        let navigationExtras: NavigationExtras = {
                            queryParams: extra_param
                        };
                        this.router.navigate(['/video-call'], navigationExtras);
                    }
                });
            }
        });
    }
    async contactUs() {
        console.log("aboutUs");
        const modal = await this.modalCtrl.create({
            component: ContactUsPage,
            componentProps: {

            },
            cssClass: 'video_popup_modal_css'
        });
        return await modal.present();
    }


}



import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { ToastController, LoadingController, Events } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-setup-notification',
  templateUrl: './setup-notification.page.html',
  styleUrls: ['./setup-notification.page.scss'],
})
export class SetupNotificationPage implements OnInit {

    settingres:any;
    terms:any;
    ready:boolean=false;
    notiVal:boolean;
    user:any = [];
    isDataLoaded:any = false;

  constructor(
    public serviceForAllService: ServiceForAllService, 
    public events: Events,
    public storage: Storage,
    public modalController: ModalController,
    public zone: NgZone,
    public cdr: ChangeDetectorRef
  ) { 
    this.storage.get('user').then((val) => {
        if (val != null) {
            this.user = val;
        }
    });
  }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.GetNotiSettings();
  }

  GetNotiSettings(){
    this.isDataLoaded = false;

    this.storage.get('user').then((val) => {
        if (val != null) {
            this.serviceForAllService.GetNotiSettings(val.token)
            .subscribe(res => {
              let r:any = [];
              r = res;

                console.log("fff:: ", r);

                // this.zone.run(()=>{
                    this.notiVal = r.notification_enabled;
                // })

                this.cdr.detectChanges();

              

            //   this.notiVal = 
              this.isDataLoaded = true;
            },(err)=>{
                this.isDataLoaded = true;
            })
        }else{ this.isDataLoaded = true; }
    },(err)=>{
        this.isDataLoaded = true;
    });


  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


  changeNotiSet(meta_val){
    this.isDataLoaded = false;
    console.log("meta_val:: ", meta_val);
    this.storage.get('user').then((val) => {
        if (val != null) {
            this.user = val;
            this.serviceForAllService.changeValue(this.user.token, 'notification_enabled', meta_val).subscribe((result) => {
                this.isDataLoaded = true;
                let r:any = [];
                r = result;
                console.log(result);

                this.GetNotiSettings();
            }, (err) => {
                this.isDataLoaded = true;
                let msg = err.error.errormsg;
            });
        }
        else{
            this.isDataLoaded = true;
        }
    },(err)=>{
        this.isDataLoaded = true;
    });
  }

}

import { Component, OnInit, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController, MenuController, NavController, LoadingController, AlertController, Events, ToastController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { PasswordValidator } from '../validators/password.validator';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-authpopup',
  templateUrl: './authpopup.page.html',
  styleUrls: ['./authpopup.page.scss'],
})
export class AuthpopupPage implements OnInit {
    phone_number:any = '';
    twofactdata:any = [];
    isDataLoaded:any = false;

  constructor(
    public events: Events,
    public navCtrl: NavController,
    public storage: Storage,
    public router: Router,
    public serviceForAllService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public zone: NgZone,
    public modalCtrl: ModalController
  ) { 
  }

  ngOnInit() {
  } 

  ionViewWillEnter(){
    this.GetTwoFactorData();
  }

  GetTwoFactorData(){
    this.isDataLoaded = false;

    this.storage.get('user').then((val) => {
        if (val != null) {
            this.serviceForAllService.GetTwoFactorData(val.token)
            .subscribe(res => {
              let r:any = [];
              r = res;
              this.twofactdata = r.twofactdata;
              this.isDataLoaded = true;
            },(err)=>{
                this.isDataLoaded = true;
            })
        }else{ this.isDataLoaded = true; }
    },(err)=>{
        this.isDataLoaded = true;
    });
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }


  sendMobileVerificationCode(){
    if(this.phone_number && this.phone_number!=''){
        this.serviceForAllService.showLoader("Sending OTP on your phone number..");
        this.serviceForAllService.resendVcodeSms(this.phone_number).subscribe((result) => {
          this.serviceForAllService.dismissLoading();
          let r:any = [];
          r = result;
          if(r.vcode && r.vcode!=''){
            this.presentToast("OTP sent. Please check your sms.");
            this.router.navigate(['/verify-phone'],{queryParams: {user_phone: this.phone_number, sentcode: r.vcode, frompage: 'twofactor'} });
            this.modalCtrl.dismiss();
          }
          else{
            this.presentToast("Your phone number must be valid. Please try again.");
          }
  
        },(err)=>{
          this.serviceForAllService.dismissLoading();
          console.log(err);
          this.presentToast(err.error.msg);
        });
      }
      else{
        this.presentToast("Please enter your phone number.");
      }
    }


    async removePhone() {
        const alert = await this.alertCtrl.create({
            header: 'Confirmation to delete?',
            // subHeader: 'Are you sure you want to remove the phone number?',
            cssClass: 'alertCancel', 
            message: '<img src="assets/question.png" class="question" /> <img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
            buttons: [{
                text: '',
                handler: () => {
                    this.deletePhone();
                }
            },
            {
                text: '',
                role: 'cancel',
                handler: () => {
    
                }
            }],
        });
        await alert.present();
    }

    deletePhone(){
        this.storage.get('user').then((val) => {
            if (val != null) {
                this.serviceForAllService.showLoader("Removing...");
                this.serviceForAllService.deletePhoneNumber(val.token).subscribe((result) => {
                  this.serviceForAllService.dismissLoading();
                  this.presentToast("Phone number removed. Please update from your profile page.");
                  this.GetTwoFactorData();
                },(err)=>{
                    let msg = err.error.errormsg;
                    if(msg==''){
                      this.serviceForAllService.presentToast("something went wrong, please try again later.");
                    }else{
                      this.serviceForAllService.presentToast(msg);
                    }
                    this.serviceForAllService.dismissLoading();
                });
            }
        })
    }


}

import { Component, OnInit } from '@angular/core';
import { ModalController ,Events ,ToastController} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { NavParams } from '@ionic/angular';
import { FunctionsService } from '../shopping/services-shop/functions.service';
import { WoocommerceService } from '../shopping/services-shop/woocommerce.service';
import { DataService, HomeTab } from '../shopping/services-shop/data.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-productmodal',
  templateUrl: './productmodal.page.html',
  styleUrls: ['./productmodal.page.scss'],
})
export class ProductmodalPage implements OnInit {

  loginInfo : any = [];
  navData   : any = [];
  productInfo : any = [];
  img : any = '';
  cart : any = [];
  i : any ;
  j : any ;

  constructor(
    public fun: FunctionsService,
    public dataService: DataService,
    public WC: WoocommerceService,
    private storage: Storage,
    public modalController: ModalController,
    public navParams : NavParams,
    public events: Events,
    public toast: ToastController,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.storage.get('user').then(userInfo => {
      if (userInfo != null) {
        this.loginInfo = userInfo;
         this.navData = this.navParams.get('values');
         this.i = this.navParams.get('i');
         this.j = this.navParams.get('j');
          if(this.navData){
              this.productInfo = JSON.parse(this.navData.productInfo);
              this.img = this.productInfo.images[0].src ? this.productInfo.images[0].src : 'assets/pwa/productmodal.png';
              console.log("shop=>",this.productInfo);
              this.get_cart_items();
          }
      }
    });
  }

  get_cart_items(){
    this.storage.get('cart').then((items) => {
        if(items){
            this.cart = items;
            console.log("Cart:", this.cart);
        } 
    });
}

  like() {
    this.productInfo.fav = !this.productInfo.fav;
    this.saveFav(this.productInfo.id, this.productInfo.fav);
    this.events.publish('refLikeUnlike',{ i : this.i , j : this.j , fav : this.productInfo.fav});
  }
  saveFav(product_id, status) {
    console.log({ product_id });
    this.storage.get('user').then((val) => {
      if (val != null) {

        this.WC.add_favService_fromApp(val.token, product_id, status).subscribe((data) => {
          let rs: any = [];
          rs = data;
          console.log(" SUBSCRIBE  == ", rs);
          if (rs.status = 'ok') {
            console.log({ rs });
          }
        }, (err) => {
          console.log({ err });
        })
      }
    });
  }

  async addItemToCart() {
      const itemIndex = _.findIndex(this.cart, ((o) => o.id === this.productInfo.id));
      if (itemIndex !== -1) {
          const toast = await this.toast.create({
              message: 'Item already in Cart',
              duration: 2000
          });
          toast.present();
      } else {
          const toast = await this.toast.create({
              message: 'Added to Cart',
              duration: 2000
          });
          toast.present();
          // this.dataService.addItemToCart(product);

          this.productInfo.quantity = 1;
          this.productInfo.price = parseFloat(this.productInfo.price);
          this.cart.push(this.productInfo);
          this.storage.set('cart', this.cart);
          this.events.publish('refaddToCart',{ i : this.i , j : this.j , product_id : this.productInfo.id});
      }
  }

  checkInCart() {
    const itemIndex = _.findIndex(this.cart, ((o) => o.id === this.productInfo.id));
    return (itemIndex !== -1) ? true : false;
  }

  close(){
    this.modalController.dismiss();
  }

}

import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ModalController, ActionSheetController, LoadingController, AlertController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { Events, NavController } from "@ionic/angular";
import { ServiceForAllService } from 'src/app/service-for-all.service';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { NavParams } from '@ionic/angular'

@Component({
  selector: 'app-add-hide-story',
  templateUrl: './add-hide-story.page.html',
  styleUrls: ['./add-hide-story.page.scss'],
})
export class AddHideStoryPage implements OnInit {

  user_id: any;
  loginInfo: any = [];
  friends: any = [];
  hide_users: any = [];
  navData: any = [];
  hide_story_list: any = [];

  search_keywords: string = '';
  is_spinner: any = 'on';

  values : any = [];

  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public alertCtrl: AlertController,
    public router: Router,
    public loadingCtrl: LoadingController,
    public modalController: ModalController,
    public navParams: NavParams,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.storage.get("user").then(userData => {
      this.loginInfo = userData;
      if (this.loginInfo != '') {
        this.user_id = this.loginInfo.user_id;
        this.get_friends();
        console.log("values :", this.values);
        
      } else {
        this.storage.clear();
        this.serviceForAllService.presentToast("Token is expired. Please login again.");
        this.router.navigate(["/login"]);
      }
    });
  }

  get_friends() {
    let sendData = {
      token: this.loginInfo.token,
      search_keywords: this.search_keywords
    }
    this.is_spinner = 'on';
    console.log("sendData:", sendData);
    this.serviceForAllService.sendData('get_close_friends', sendData).subscribe(response => {
      this.is_spinner = 'off';
      this.friends = response['friends'];

      this.friends.forEach(element => {
        element.is_selected = false;
      });

      this.hide_story_list =  this.values.hide_user_list;
      if(this.hide_story_list.length > 0){
        this.friends.forEach(element => {
          const index = this.hide_story_list.findIndex(ele => ele == element.user_id );
          if(index > -1){
            element.is_selected = true;
          }
        });
      }

      
    }, (err) => {
      this.is_spinner = 'off';
      this.serviceForAllService.presentAlert(err.error.msg);
      if (err.error.error_code == "token_expired") {
        this.storage.clear();
        this.router.navigate(["/login"]);
      }
    });
  }

add_remove_hide_story(i, second_user_id, action){
  const index = this.hide_story_list.findIndex(element => element == second_user_id );
  if(index > -1){
    this.hide_story_list.splice(index,1);
    this.friends[i].is_selected = false;
  }else{
    this.hide_story_list.push(second_user_id);
    this.friends[i].is_selected = true;
  }
  console.log('hide_story_list:',this.hide_story_list);
}

async closeModal() {
  await this.modalController.dismiss({});
}
async add() {
  await this.modalController.dismiss({
    action : 'submit',
    hide_story_list : this.hide_story_list,
  });
}

}

import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router, NavigationExtras } from '@angular/router';
import { ToastController, ModalController, NavController, NavParams, MenuController, LoadingController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.page.html',
  styleUrls: ['./video-popup.page.scss'],
})
export class VideoPopupPage implements OnInit {

  user:any;
  loading: any; 
  url: any;
  video_thumbnail: any='';

  constructor(
    public storage:Storage,
    public router:Router,
    private navParams: NavParams,
    public loadingCtrl: LoadingController, 
    public modalController: ModalController,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public navCtrl:NavController, 
    public toastController:ToastController,
  ) { 
    this.url = this.navParams.get('url');
    this.video_thumbnail = this.navParams.get('video_thumbnail');
  }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}

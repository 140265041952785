import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { SocialServicesService } from '../services/social-services.service';
import { Storage } from '@ionic/storage';
import { Events, ToastController, ModalController, MenuController, LoadingController, AlertController, ActionSheetController } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
// import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions, CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { VideoEditor, CreateThumbnailOptions } from '@ionic-native/video-editor/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
// import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { VideoPopupPage } from '../video-popup/video-popup.page';
import { SelectUsersPage } from '../select-users/select-users.page';
import { ServiceForAllService } from '../service-for-all.service';
import { ReferfriendmodalPage } from '../referfriendmodal/referfriendmodal.page';
@Component({
  selector: 'app-feedbackmodal',
  templateUrl: './feedbackmodal.page.html',
  styleUrls: ['./feedbackmodal.page.scss'],
})
export class FeedbackmodalPage implements OnInit {

  loginInfo : any = [];
    rate: number = 1;
    feedback_type : any = '';
    post_content : any = '';
    is_spinner : string = 'off';
    constructor(
        private platform: Platform,
        public storage: Storage,
        private route: ActivatedRoute,
        private router: Router,
        public modalController: ModalController,
        public loadingCtrl: LoadingController,
        public alertCtrl: AlertController,
        private actionSheetController: ActionSheetController,
        private cdRef: ChangeDetectorRef,
        public serviceForAllService : ServiceForAllService,
    ) { }

    async refermodal() {
        const modal = await this.modalController.create({
          component: ReferfriendmodalPage,
          cssClass: 'refermodal'
        });
        return await modal.present();
    }

    ionViewWillEnter() {
        this.storage.get('user').then(user => {
            if(user){
                this.loginInfo = user;
            }else{
                this.serviceForAllService.presentToast("Token is expired. Please login again");
                this.router.navigate(['/login']);
            }
        });
    }

    ngOnInit() {
    
    }

    give_rating(rate){
        this.rate = rate;
    }

    send_feedback(){
        let sendData = {
            token       : this.loginInfo.token,
            rate        : this.rate,
            post_content: this.post_content,
            feedback_type : this.feedback_type,
        }
        console.log("sendData:", sendData);
        this.is_spinner = 'on';
        this.serviceForAllService.send_feedback(sendData).subscribe((response) => {
            console.log('response:', response);
            this.is_spinner = 'off';
            if(response['status'] == 'ok'){
                this.rate = 1;
                this.feedback_type = '';
                this.post_content = '';
                this.serviceForAllService.presentToast(response['msg']);
            }else{
                this.serviceForAllService.presentAlert(response['msg']);
            }
        }, (err) => {
            this.is_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentToast(err.error.msg);
            if(err.error.error_code == 'token_expired'){
                this.router.navigate(['/login']);
            }
          });
    }

}

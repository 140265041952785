import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceForAllService } from '../service-for-all.service';
import { Router } from '@angular/router';
import { MenuController, LoadingController, AlertController, NavController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { Events } from '@ionic/angular';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.page.html',
  styleUrls: ['./contact-us.page.scss'],
})
export class ContactUsPage implements OnInit {
  contactusData: FormGroup;
 
  constructor(public route: ActivatedRoute,
    public allServices: ServiceForAllService,
    public router: Router,
    public menu: MenuController,
    public events: Events,
    public storage: Storage,
    public formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    public loadingCtrl: LoadingController,
    private platform: Platform,
    private location: Location,
    private _DomSanitizationService: DomSanitizer,
    public modalCtrl: ModalController
    ) { 
    this.contactusData = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      phone: new FormControl('', [Validators.required, Validators.pattern("[0-9 ]{10}$")]),
      content: new FormControl('', Validators.required),
    });
    }

  ngOnInit() {}

  ionViewWillEnter() {}

  dismiss(){
    this.modalCtrl.dismiss()
  }

  createContactus(contactusData) {
    this.allServices.showLoader();
    this.allServices.sendData('add_coutactUs_api', contactusData).subscribe(res => {
      console.log('response=>', res);
      let response: any = [];
      response = res;
      console.log('response==>', response);
      if (response.status == 'ok') {
        this.allServices.presentToast("Thanks for contacting us! We will be in touch with you shortly.");
      }
      this.contactusData.reset();
      this.allServices.dismissLoading();
    }, err => {
      this.allServices.dismissLoading();

    });
  }

}

/**
 * Shoppr - E-commerce app starter Ionic 5(https://www.enappd.com)
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source .
 *
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NewAddressPage } from './new-address.page';
import { TranslateModule} from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: NewAddressPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
    ],
  declarations: [NewAddressPage]
})
export class NewAddressPageModule { }

import { Component, OnInit } from '@angular/core';
import { TranslateConfigService } from '../translate-config.service';
import { ServiceForAllService } from '../service-for-all.service';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Events, ToastController, ModalController, MenuController, LoadingController, AlertController, ActionSheetController } from '@ionic/angular';
@Component({
  selector: 'app-language',
  templateUrl: './language.page.html',
  styleUrls: ['./language.page.scss'],
})
export class LanguagePage implements OnInit {
    loaded:boolean = true;
  selectedLanguage:any = "en";
  languages = [
    {"code" : "en" ,"name": "English" ,"imagesrc" :"assets//flags/en.png"},
    {"code" :"hi", "name": "Hindi", "imagesrc" :"assets//flags/hi.png"},
  ];
    constructor(
        private  translateConfigService: TranslateConfigService,
        public   serviceForAllService: ServiceForAllService, 
        public   storage: Storage,
        private  router: Router,
        public   modalController: ModalController,
        private  events : Events
    ) {

    }

    //[START]=> Close Model
    closeModel(){
        this.modalController.dismiss({
        'dismissed': true,
        // 'open_model' : model_name,
        });
    }
    //[END]=> Close Model

  ionViewWillEnter(){
      this.loaded = false;
      this.storage.get('user').then((val) => {
        if (val != null) {
            return this.serviceForAllService.GetLanguageSetting(val.token).subscribe(res => {
                this.loaded = true;
                this.selectedLanguage = res.toString();
                console.log("language:: ", this.selectedLanguage);
            },(err)=>{this.loaded = true;})
        }else{this.loaded = true;}
      },(err)=>{this.loaded = true;});

    //   this.selectedLanguage = this.translateConfigService.getDefaultLanguage();
      console.log("get d l::::: ", this.selectedLanguage);
  }

    loadlanguages() {
        setTimeout (() => {
            var radios= document.getElementsByClassName('alert-radio-label');
            for (let index = 0; index < radios.length; index++) {
                let singrad = radios[index];
                singrad.innerHTML = singrad.innerHTML.concat('<img src='+this.languages[index].imagesrc+' style="width:26px; position:absolute; right:40px;"/>');
                // (singrad as HTMLElement).style.fontSize  = "20px";
                // (singrad as HTMLElement).style.textTransform  = "uppercase";
            }
        }, 200);
    }

    languageChanged(){
        console.log("selectedLanguage1:", this.selectedLanguage);
        if(this.selectedLanguage && this.selectedLanguage!=undefined){

            this.loaded = false;
            setTimeout(() => {
                this.loaded = true;
            }, 3000);
            console.log("selectedLanguage2:", this.selectedLanguage);
            this.translateConfigService.setLanguage(this.selectedLanguage);
        }
    }

  ngOnInit() {
  }

    openLRPage(){
        this.closeModel();
        this.router.navigate(['/language-request']);
    }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleCloudVisionAPIKey: 'AIzaSyBBZQ2CILmg3-E6a78a-YOofQuftQtaRhQ',

  firebase: {
    apiKey: "AIzaSyBBZQ2CILmg3-E6a78a-YOofQuftQtaRhQ",
    authDomain: "tagster-56569.firebaseapp.com",
    databaseURL: "https://tagster-56569.firebaseio.com",
    projectId: "tagster-56569",
    storageBucket: "tagster-56569.appspot.com",
    messagingSenderId: "1029346550454",
    appId: "1:1029346550454:web:ae916960d66613effefc17"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

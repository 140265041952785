/**
* Shoppr - E-commerce app starter Ionic 5(https://www.enappd.com)
*
* Copyright © 2018-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source .
*
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment-shop';
import {Storage} from '@ionic/storage'
@Injectable({
  providedIn: 'root'
})
export class WoocommerceService {
  url = environment.url;
  consumerKey = environment.consumerKey;
  restApi = environment.url+'/wp-json/wc/v3';
  consumerSecret = environment.consumerSecret;
    currentuserID:any;
  constructor(private http: HttpClient,public storage:Storage) {

    this.storage.get('user').then((userInfo:any=[]) => {
      
      if (userInfo!=null) {
        this.currentuserID =userInfo.user_id;
      }
    });
  }

  getProducts() {
    return `${this.url}/wp-json/wc/v3/products?consumer_key=${
      this.consumerKey
      }&consumer_secret=${this.consumerSecret}&customerID=${this.currentuserID}`;
  }

  getTags() {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.url}/wp-json/wc/v3/products/tags?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}&customerID=${this.currentuserID}`
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  getProduct(pid) {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.url}/wp-json/wc/v3/products/${pid}?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}&customerID=${this.currentuserID}`
        )
        .subscribe(productData => {
          resolve(productData);
        });
    });
  }

  getProductReviews(pid) {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.url}/wp-json/wc/v2/products/${pid}/reviews?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(productData => {
          resolve(productData);
        });
    });
  }

  updateEmail(userId, email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const data = {
      email: email
    };

    const convertedData = this.JSON_to_URLEncoded(data);

    return new Promise(resolve => {
      this.http
        .put(
          `${this.url}/wp-json/wc/v3/customers/${userId}/?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`,
          convertedData,
          { headers }
        )
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateUser(user, address, type) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const data = {
      first_name: user.first_name,
      last_name: user.last_name,
      billing: type === 'billing' ? address : user.billing,
      shipping: type === 'shipping' ? address : user.shipping,
    };

    const convertedData = this.JSON_to_URLEncoded(data);

    return new Promise(resolve => {
      this.http
        .post(
          `${this.url}/wp-json/wc/v3/customers/${user.id}/?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`,
          convertedData,
          { headers }
        )
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  JSON_to_URLEncoded(element, key?, lists?) {
    const list = lists || [];
    if (typeof element === 'object') {
      // tslint:disable-next-line:forin
      for (const idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          list
        );
      }
    } else {
      list.push(key + '=' + encodeURIComponent(element));
    }
    return list.join('&');
  }

  createOrder(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const order = this.JSON_to_URLEncoded(obj);

    return new Promise(resolve => {
      this.http
        .post(
          `${this.url}/wp-json/wc/v3/orders/?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`,
          order,
          { headers }
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  getPastOrders(customerId) {
    return new Promise(resolve => {
      this.http
        .get(
          `${
          this.url
          }/wp-json/wc/v3/orders?customer=${customerId}&consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getPosts() {
    return new Promise(resolve => {
      this.http.get(`${this.url}/wp-json/wp/v2/posts`).subscribe(res => {
        resolve(res);
      });
    });
  }

  getPostsTags() {
    return new Promise(resolve => {
      this.http.get(`${this.url}/wp-json/wp/v2/categories`).subscribe(res => {
        resolve(res);
      });
    });
  }

  getPostsData(pid) {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.url}/wp-json/wp/v2/posts?categories=${pid}&consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(postsData => {
          resolve(postsData);
        });
    });
  }

  getAttributes() {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.url}/wp-json/wc/v3/products/attributes?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getAttributeTerms(attrId) {
    return new Promise(resolve => {
      this.http
        .get(
          `${
          this.url
          }/wp-json/wc/v3/products/attributes/${attrId}/terms?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  filterProducts(attr, attrTerm) {
    return new Promise(resolve => {
      this.http
        .get(
          `${
          this.url
          }/wp-json/wc/v3/products?attribute=${attr}&attribute_term=${attrTerm}&consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getPaymentGateways() {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.url}/wp-json/wc/v3/payment_gateways?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`
        )
        .subscribe(data => {
          resolve(data);
        });
    });
  }

  changePassword(uid, pass) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const changedPass = `password=${pass}`;
    return new Promise(resolve => {
      this.http
        .post(
          `${this.url}/wp-json/wc/v3/customers/${uid}/?consumer_key=${
          this.consumerKey
          }&consumer_secret=${this.consumerSecret}`,
          changedPass,
          { headers }
        )
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  getCustomerDetails(userId,email='user1@klnoxweb.com') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      
    });

    return new Promise(resolve => {
           this.http
           .get(
            `${this.url}/wp-json/wc/v3/customers/${userId}/?consumer_key=${
            this.consumerKey
            }&consumer_secret=${this.consumerSecret}`
          )
          .subscribe(data => {
            resolve(data);
          });

    });
  }

  add_favService_fromApp(token, product_id,status) {
    return this.http.post(this.url + 'wp-json/mobileapi/v1/add_fav_product', {
      token: token,
      product_id: product_id,
      status:status
    })
  }

  getFavoriteStatus(token, product_id) {
    return this.http.post(this.url + 'wp-json/mobileapi/v1/getFavoriteStatus', {
      token: token,
      product_id: product_id
    })
  }
  get_favorite_product(token,page:any=0) {
    return this.http.post(this.url + 'wp-json/mobileapi/v1/get_favorite_product', {
      token: token,
      page: page
    })
  }
  woocommercecheck(api,args:any='') {
    if(args!=''){
      return this.http.delete(
        `${this.restApi}/`+api+`?consumer_key=${
        this.consumerKey
        }&consumer_secret=${this.consumerSecret}`+args
      )
    }else{
      return this.http.get(
        `${this.restApi}/`+api+`?consumer_key=${
        this.consumerKey
        }&consumer_secret=${this.consumerSecret}`+args
      )
    }



  }
}

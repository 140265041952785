import { Component, OnInit } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.page.html',
  styleUrls: ['./aboutus.page.scss'],
})
export class AboutusPage implements OnInit {

  settingres:any;
  about:any;
  ready:boolean=false;
  constructor(
    public serviceForAllService: ServiceForAllService,
    public modalCtrl: ModalController
    
    ) { }

  ngOnInit() {
    
  }

  ionViewWillEnter() {
    this.GetSetting();
 
   }
   
  dismiss() {
    this.modalCtrl.dismiss()
  }

   GetSetting(){
    this.serviceForAllService.GetSetting()
    .subscribe(res => {
      this.ready=true;
      this.settingres=res;
      this.about=this.settingres.about.about_text;
    })
  }
}


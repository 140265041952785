/**
 * Shoppr - E-commerce app starter Ionic 5(https://www.enappd.com)
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source .
 *
 */

import { Injectable } from '@angular/core';
import { NotificationsCard } from '../models/notifications-card';
import { Notification } from '../models/notification';
import { Product } from '../models/product';
import { LineItem } from '../models/line-item';
import { Order } from '../models/order';
import { WoocommerceService } from '../services-shop/woocommerce.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment-shop';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { Events } from '@ionic/angular';
export interface HomeTab {
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(
    public WC: WoocommerceService,
    private http: HttpClient,
    private storage: Storage,
    public events:Events
  ) {
    this.events.subscribe('userCheck:created', (val) => {
      if (val!=null) {
        this.WC.getCustomerDetails(val.user_id).then((res: any) => {
          this.current_user = res;
          console.log(this.current_user);
        });
      }
    })
    this.storage.get('user').then((userInfo:any=[]) => { 

      if (userInfo!=null) {
        this.WC.getCustomerDetails(userInfo.user_id).then((res: any) => {
          this.current_user = res;
          console.log(this.current_user);
        });
      }
    });


   }


  home_products = [];
  home_tabs = [];
  home_current_segment = '';
  address_type = '';
  cart: Array<LineItem> = [];
  home_filter = {
    type: '',
    value: ''
  };
  spinner = false;
  current_user:any=[];
  card: NotificationsCard = {
    image: 'assets/images/products/1.jpg',
    title: 'Kya aapne kabhi online hotel book kia hai???\nHotel? Sastago',
    time: 9
  };
  recent = environment.products_1;
  current_product: Product = environment.products_1[0];

  notifications: Notification = {
    all: [
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card
    ],
    deals: [
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card
    ],
    orders: [],
    others: [
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card
    ]
  };

  addItemToCart(product) {
    product.quantity = 1;
    product.price = parseFloat(product.price);
    this.cart.push(product);
    this.storage.set('cart', this.cart);
    // this.storage.set('cart', JSON.stringify(this.cart)).then(() => {
        // this.fun.updateCartBadge();
    //   });
  }

  changeItemQuantity(id, quantity) {
    const itemIndex = _.findIndex(this.cart, (o) => o.id === id);
    this.cart[itemIndex].quantity = quantity;
  }

  removeItemFromCart(id) {
    const itemIndex = _.findIndex(this.cart, (o) => o.id === id);
    this.cart.splice(itemIndex, 1);
  }

  

  getTabs(event?: any): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.WC.getTags().then((tags: Array<any>) => {
        if (tags.length) {
          this.home_products.length = 0;
          this.home_tabs.length = 0;
          tags.forEach(tag => {
            this.home_products.push({
              tag: tag.name,
              id: tag.id,
              items: []
            });
            this.home_tabs.push(tag);
          });
          this.getProductData(tags[0].id, event);
          this.home_current_segment = tags[0].name;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
  getProductData(tagId, event?: any) {
    const query = this.WC.getProducts();
    const fullQuery = `${query}&tag=${tagId}`;
    const existing_index = _.findIndex(this.home_products, function (o) { return o.id === tagId; });
    if (existing_index !== -1 && this.home_products[existing_index].items && this.home_products[existing_index].items.length) {
      // do nothing
    } else {
      this.spinner = true;
      this.http.get(fullQuery).subscribe(data => {
        this.home_products[existing_index].items = data;
        this.spinner = false;
        if (event) {
          event.target.complete();
        }
      });

    }
  }
  populateFilteredProducts(products) {
    this.home_products.forEach(element => {
      element.items.length = 0;
    });
    products.forEach(product => {
      if (product.tags.length) {
        product.tags.forEach(tag => {
          const tag_index = _.findIndex(this.home_products, function (o) { return o.tag === tag.name; });
          if (tag_index !== -1) {
            this.home_products[tag_index].items.push(product);
          }
        });
      }
    });
  }

    checkAddressExists(type) {
        let status = true;
        console.log("checkAddressExists()->current_user:", this.current_user);
        if(!this.current_user[type]){
            status = false;
        }
        if(this.current_user[type]){
            if (this.current_user[type].first_name === '' &&
                this.current_user[type].last_name === '' &&
                this.current_user[type].address_1 === '' &&
                this.current_user[type].address_2 === '' &&
                this.current_user[type].city === '' &&
                this.current_user[type].state === '' &&
                this.current_user[type].country === '') {
                status = false;
            }
        }
        
        return status;
    }
  checkAddressValid(address) {
    let error = '';

    if (address.first_name === '') {
      error = 'First Name';
    } else if (
      address.address_1 === '') {
      error = 'Address - Line 1';
    } else if (
      address.city === '') {
      error = 'City';
    } else if (
      address.state === '') {
      error = 'State';
    } else if (
      address.country === '') {
      error = 'Country';
    } else if (
      address.postcode === '') {
      error = 'Postcode';
    }
    return error;
  }
}

import { Component, OnInit } from '@angular/core';
import { ServiceForAllService } from '../service-for-all.service';
import { ToastController, LoadingController, Events } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit { 

  settingres:any;
  terms:any;
  ready:boolean=false;
  tncAccepted:any = '';
  constructor(
    public serviceForAllService: ServiceForAllService, 
    public events: Events,
    public storage: Storage,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    
  }
  
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  
  ionViewWillEnter() {
    this.GetSetting();

    this.storage.get('TNC').then((val) => {
      if (val != null && val == '1') {
        console.log("Accepted");
        this.tncAccepted = "1";
      }else{
        this.tncAccepted = "";
      }
    },err=>{
      this.tncAccepted = "";
    });
 
   }

   GetSetting(){
    this.serviceForAllService.GetSetting()
    .subscribe(res => {
      this.ready=true;
      this.settingres=res;
      this.terms=this.settingres.about.term_text;
    })
  }

  acceptTnC(){
    this.storage.set('TNC', '1');
    this.tncAccepted = "1";
    this.serviceForAllService.presentToast("You have accepted terms & conditions. Please go back and sign up.");
  }
}

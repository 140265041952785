/**
 * Shoppr - E-commerce app starter Ionic 5(https://www.enappd.com)
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the 
 * LICENSE.md file in the root directory of this source .
 *
 */
import { Component, OnInit } from '@angular/core';
import { FunctionsService } from '../../services-shop/functions.service';
import { DataService } from '../../services-shop/data.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { WoocommerceService } from '../../services-shop/woocommerce.service';
import * as _ from 'lodash';
import { LocationDataService } from '../../../services/location-data/location-data.service';
import { CountryListPage } from './../../../country-list/country-list.page';
@Component({
    selector: 'app-new-address',
    templateUrl: './new-address.page.html',
    styleUrls: ['./new-address.page.scss']
})
export class NewAddressPage implements OnInit {

    constructor(
        private fun: FunctionsService,
        private dataService: DataService,
        private lcoationService: LocationDataService,
        private http: HttpClient,
        private wooCommerce: WoocommerceService,
        public ModalController: ModalController,
    ) {
        this.getCountries();

    }
    address: any = {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        country: '',
        state: '',
        city: '',
        postcode: undefined,
        phone: undefined
    };
    country_lebel : any = 'Select Country';
    flag;
    userData: any;
    spinner = false;
    countries = [];

    ngOnInit() {
        this.userData = this.dataService.current_user;
        this.address = this.dataService.address_type === 'billing' ? JSON.parse(JSON.stringify(this.dataService.current_user.billing)) :
            JSON.parse(JSON.stringify(this.dataService.current_user.shipping));
    }

    getCountries() {
        this.countries = this.lcoationService.data.countries;
        // this.http.get('https://restcountries.eu/rest/v2/all').subscribe(d => {
        //   this.countries = _.map(d, 'name');
        // });
    }

    update() {
        if (this.dataService.address_type === 'billing' && !this.fun.validateEmail(this.address.email)) {
            this.fun.presentToast('Please correct Email', true, 'top', 1500);
        } else if (this.dataService.checkAddressValid(this.address) !== '') {
            const error = this.dataService.checkAddressValid(this.address);
            this.fun.presentToast('Please add ' + error, true, 'top', 1500);
        } else {
            this.spinner = true;
            if (this.userData.billing.email === '') {
                this.userData.billing.email = this.userData.email;
            }
            this.wooCommerce.updateUser(this.userData, this.address, this.dataService.address_type).then((res: any) => {
                this.spinner = false;
                this.dataService.current_user.shipping = res.shipping;
                this.dataService.current_user.billing = res.billing;
                this.fun.presentToast('Success', true, 'bottom', 2100);
                this.fun.back();
            });
        }
    }

    //[START]=> Close Model
    closeModel() {
        this.ModalController.dismiss({
            'dismissed': true,
        });
    }
    //[END]=> Close Model

    async open_country_lists(type) {
        console.log("Address:", this.address);
        console.log("open_country_lists");
        const modal = await this.ModalController.create({
            component: CountryListPage,
            componentProps: {},
            cssClass: 'country_list_page'
        });
        modal.onDidDismiss().then((response) => {
            console.log("response:", response);
            if (response.data) {
                let data = response.data;
                console.log("data:", data);
                if (data.selected_country) {
                    let selected_country = data.selected_country;
                    console.log("selected_country:", selected_country);
                    if (type == 'phone_country_code') {
                        // let value = selected_country.iso3+'_'+selected_country.phonecode;
                        // this.signupForm.controls['phone_country_code'].setValue(value);
                        // this.phone_country_code_label = selected_country.phonecode;

                       

                    } else if (type == 'company_phone_country_code') {
                        // let value = selected_country.iso3+'_'+selected_country.phonecode;
                        // this.signupForm.controls['company_phone_country_code'].setValue(value);
                        // this.company_phone_country_code_label = selected_country.phonecode;

                    } else if (type == 'country') {
                        this.address.country = selected_country.iso2;
                        this.country_lebel = selected_country.name;
                    } else if (type == 'billing_country') {
                        // this.signupForm.controls['billing_country'].setValue(selected_country.iso2);
                        // this.billing_country_lebal = selected_country.name;

                        // console.log("billing_country_lebal:", this.billing_country_lebal);
                        // console.log("this.signupForm.value.billing_country:", this.signupForm.value.billing_country);
                        // this.getStates('company', this.signupForm.value.billing_country)
                    }


                }
            }
        });
        return await modal.present();
    }

}

import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ModalController, ActionSheetController, LoadingController, AlertController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { Events, NavController } from "@ionic/angular";
import { ServiceForAllService } from '../../../service-for-all.service';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { NavParams } from '@ionic/angular';

@Component({
    selector: 'app-friend-list',
    templateUrl: './friend-list.page.html',
    styleUrls: ['./friend-list.page.scss'],
})
export class FriendListPage implements OnInit {

    api_msg: string = '';
    loginInfo: any = [];
    type: string = 'following';
    lists: any = [];
    is_spinner: any = 'on';
    user_id: any;

    close_friends: any = [];
    friends: any = [];

    navData: any = [];
    selectedusers: any = [];
    search_keywords: string = ''
    total_close_friend : any = 0;
    constructor(
        public navCtrl: NavController,
        public menuCtrl: MenuController,
        public storage: Storage,
        public serviceForAllService: ServiceForAllService,
        public alertCtrl: AlertController,
        public router: Router,
        public loadingCtrl: LoadingController,
        private route: ActivatedRoute,
        public modalController: ModalController,
        public navParams: NavParams,
    ) { }

    ngOnInit() {
    }

    ionViewWillEnter() {
        this.storage.get("user").then(userData => {
            this.loginInfo = userData;
            if (this.loginInfo != '') {
                this.user_id = this.loginInfo.user_id;
                this.get_friends();
                this.navData = this.navParams.get('values');

                console.log("navData :", this.navData);

            } else {
                this.storage.clear();
                this.serviceForAllService.presentToast("Token is expired. Please login again.");
                this.router.navigate(["/login"]);
            }
        });
    }


    search_user() {
        this.get_friends();
    }

    get_friends() {
        let sendData = {
            token: this.loginInfo.token,
            search_keywords: this.search_keywords
        }
        // this.showLoader();
        this.is_spinner = 'on';
        console.log("sendData:", sendData);
        this.serviceForAllService.sendData('get_close_friends', sendData).subscribe(response => {
            this.is_spinner = 'off';
            console.log("get_close_friends : response:", response);
            this.close_friends = response['close_friends'];
            this.friends = response['friends'];
            this.total_close_friend = response['total_close_friend'];
            if (response['status'] == 'ok') {

            } else {
                this.api_msg = response['msg'];
            }
        }, (err) => {
            console.log(err);
            // this.dismiss();
            this.is_spinner = 'off';
            this.serviceForAllService.presentAlert(err.error.msg);
            this.api_msg = err.error.ms;
            if (err.error.error_code == "token_expired") {
                this.storage.clear();
                this.router.navigate(["/login"]);
            }
        });
    }

    // setuser(i,item){

    //   let userid = item.second_user_id;
    //   let ind = this.selectedusers.indexOf(e=>second_user_id == userid);
    //   if(ind==-1){
    //     this.selectedusers.push(item);
    //   }
    //   else{
    //     this.selectedusers.splice(ind,1);
    //   };

    //   console.log("Selected Users=",this.selectedusers);
    // }


    add_remove_close_friend(ind, friend_id, action) {
        if (action == 'add') {
            this.serviceForAllService.showLoader('Adding..');
        } else {
            this.serviceForAllService.showLoader('Removing..');
        }
        let sendData = {
            token: this.loginInfo.token,
            friend_id: friend_id
        };
        this.serviceForAllService.sendData('add_remove_close_friends', sendData).subscribe(response => {
            console.log("add_remove_close_friends : response:", response);
            this.serviceForAllService.dismissLoading();
            if (response['status'] == 'ok') {
                this.serviceForAllService.presentToast(response['msg']);
                if (action == 'add') {
                    this.close_friends.push(this.friends[ind]);
                    this.friends.splice(ind, 1);
                } else {
                    this.friends.push(this.close_friends[ind]);
                    this.close_friends.splice(ind, 1);
                }
                this.total_close_friend = response['total_close_friend'];
                //  this.get_follows();
            } else {
                this.api_msg = response['msg'];
            }
        }, (err) => {
            console.log(err);
            // this.dismiss();
            this.is_spinner = 'off';
            this.serviceForAllService.dismissLoading();
            this.serviceForAllService.presentAlert(err.error.msg);
            this.api_msg = err.error.ms;
            if (err.error.error_code == "token_expired") {
                this.storage.clear();
                this.router.navigate(["/login"]);
            }
        });

    }


    async closeModal() {
        await this.modalController.dismiss({
            total_close_friend : this.total_close_friend,
        });
    }

}

import { Component, OnInit, NgZone } from '@angular/core';
import { AlertController, LoadingController, NavController, MenuController, ActionSheetController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../../../service-for-all.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Globalization } from '@ionic-native/globalization/ngx';
declare var google;
@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.page.html',
  styleUrls: ['./select-location.page.scss'],
})
export class SelectLocationPage implements OnInit {

  response1 : any;
  checkin_PlaceList_ready: boolean = false;
  checkin_PlaceList: any;
  iana_timezone: any;
  user: any;
  res: any;
  search_keywords : string;
  places: any = "";
  place_name: string = "";


  latitude: number = 0;
  longitude: number = 0;
  GoogleAutocomplete: any;
  search_results : any = [];
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public serviceForAllService: ServiceForAllService,
    public actionSheetController: ActionSheetController,
    public storage: Storage,
    private globalization: Globalization,
    public modalController: ModalController,
    public zone:NgZone,
    
    ){

        this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    
    }

    ngOnInit() {
        console.log("places:", this.places);
        if(this.places){
            this.search_keywords = this.places.description;
            this.updateSearchResults();
        }
        if(this.places.name){
        console.log("places",this.places);
            //   this.place_name = this.checkedin_PlaceInfo.name;
            //   this.search_keywords = this.checkedin_PlaceInfo.name;
        }
  }

  //[START]=> Checkin This Place
  checkinHere(places){
    this.places = places;
    this.modalController.dismiss({
      'dismissed': true,
      'places' : this.places,
    });
  }
  //[END]=> Checkin This Place

  //[START]=> Close Model
  closeModel(){
    this.modalController.dismiss({
      'dismissed': true,
      'places' : this.places,
    });
  }
  //[END]=> Close Model


    updateSearchResults() {
        console.log("search_keywords:", this.search_keywords);
        if (this.search_keywords == '') {
            this.search_results = [];
            return;
        }
        this.GoogleAutocomplete.getPlacePredictions({ input:this.search_keywords },(predictions, status) => {
            // console.log("predictions:", predictions);
            this.search_results = [];
            this.zone.run(() => {
                if (predictions) {
                    predictions.forEach((prediction) => {
                        this.search_results.push(prediction);
                    });
                }
            });
        });
    }
}

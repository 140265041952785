import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { VideoPopupPage } from '../../../video-popup/video-popup.page';
import { Storage } from '@ionic/storage';
import { IonSlides } from '@ionic/angular';
import { ServiceForAllService } from '../../../service-for-all.service';
import { PopoverController } from '@ionic/angular';
import { StoryViewersPage } from './../story-viewers/story-viewers.page';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import * as $ from 'jquery';

@Component({
    selector: 'app-view-stories',
    templateUrl: './view-stories.page.html',
    styleUrls: ['./view-stories.page.scss'],
})
export class ViewStoriesPage implements OnInit {
    @ViewChild('slides', { static: true }) slides: IonSlides;

    loading: any;
    slideOpts: any = {
        initialSlide: 0,
        speed: 1000
    };
    user_stories: any = [];
    user_index: number;
    user: any;
    user_id: any = '';
    loginInfo: any = [];

    stories: any = [];
    current_user_full_name: any = '';
    current_user_avatar: any = '';
    story_bar_width: any = 10;
    active_slide: any = 0;
    plt_width: any = 600;
    plt_height: any = '';
    isReadMore: boolean = false;

    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        private platform: Platform,
        private streamingMedia: StreamingMedia,
        public storage: Storage,
        public serviceForAllService: ServiceForAllService,
        public popoverController: PopoverController,
        public router: Router,
    ) {

    }

    ngOnInit() {

        console.log('plt_width:', this.plt_width);

        this.loginInfo = this.navParams.data.loginInfo;
        this.user_index = this.navParams.data.user_index;
        this.user_stories = this.navParams.data.user_stories;

        console.log("user_stories:", this.user_stories);
        console.log("user_stories[Cureent_story]", this.user_stories[this.user_index]);

        this.current_user_full_name = this.user_stories[this.user_index].full_name;
        this.current_user_avatar = this.user_stories[this.user_index].user_avatar;
        this.stories = this.user_stories[this.user_index].stories;
        this.story_bar_width = (this.plt_width - (this.stories.length * 5)) / (this.stories.length);
        this.story_bar_width = parseInt(this.story_bar_width);
        console.log("story_bar_width:", this.story_bar_width);
        console.log("stories:", this.stories);
        console.log('index:', this.user_index);
        this.slideOpts = {
            initialSlide: this.user_index,
            speed: 1000
        }

        this.storage.get('user').then((val) => {
            console.log('userData: ', val);
            if (val != null) {
                this.user = val;
                this.loginInfo = val;
                console.log("loginInfo:", this.loginInfo);
                this.user_id = val.user_id;
                console.log("Need to see the 1st story");
                // this.seen_story(this.user_index);
            }
        });
    }

    async closeModal() {
        const onClosedData: string = "Wrapped Up!";
        await this.modalController.dismiss(onClosedData);
    }

    playVideo(url, video_thumbnail) {
        console.log('Video url: ' + url);
        this.platform.ready().then(() => {
            if (this.platform.is('android')) {
                console.log('I m android');
                this.open_video_modal_popup(url, video_thumbnail);
            } else {
                let options: StreamingVideoOptions = {
                    successCallback: () => { console.log('Video played') },
                    errorCallback: (e) => { console.log('Error streaming', e) },
                    // orientation: 'landscape',
                    shouldAutoClose: true,
                    controls: true
                };
                this.streamingMedia.playVideo(url, options);
            }
        });
    }

    async open_video_modal_popup(url, video_thumbnail) {
        const modal = await this.modalController.create({
            component: VideoPopupPage,
            componentProps: {
                url: url,
                video_thumbnail: video_thumbnail
            },
            cssClass: 'video_popup_modal_css'
        });
        return await modal.present();
    }

    slideChanged(e: any) {
        this.slides.getActiveIndex().then((index: number) => {
            this.active_slide = index;
            console.log("active_slide:", this.active_slide);

            // this.stories[this.active_slide].

            this.playVideoAgain(this.active_slide);

            this.seen_story(this.active_slide);
        });
    }

    playVideoAgain(index) {
        $('.v_' + index).each(function () {
            $(this)[0].play();
        })
    }

    seen_story(index) {
        let row = this.stories[index];
        console.log("row:", row);
        let sendData = {
            token: this.loginInfo.token,
            story_id: row.story_id
        }
        console.log("sendData:", sendData);
        this.serviceForAllService.seen_story(sendData).subscribe(response => {
            console.log("response:", response);
            if (response['status'] == "ok") {
                this.stories[index].total_views = response['total_views'];
                this.stories[index].viewers = response['viewers'];
            } else {
                this.serviceForAllService.presentAlert(response['msg']);
            }
        }, (err) => {
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);
            if (err.error_code == "token_expired") {
                this.storage.clear();
                this.router.navigate(["/login"]);
            }
        });
    }

    next_story() {
        console.log("next_story->slides:", this.slides);
        this.slides.slideNext();
    }
    previous_story() {
        console.log("previous_story->slides:", this.slides);
        this.slides.slidePrev();
    }

    async see_story_viewers(index) {
        console.log("index:", index);
        let item = this.stories[index];
        console.log("item:", item);
        console.log("item.viewers:", item.viewers);

        let sendData = {
            story_id: item.story_id,
            storyInfo: item,
            viewers: item.viewers,
            loginInfo: this.loginInfo,
        }
        console.log("sendData:", sendData);
        const popover = await this.popoverController.create({
            component: StoryViewersPage,
            componentProps: sendData,
            // event: index,
            // translucent: true,
            cssClass: 'viewers-popup'
        });
        popover.onDidDismiss().then((res) => {
            console.log("opover.onDidDismiss->res: ", res);
            this.closeModal();
            this.closeModal();
        });
        return await popover.present();

    }

    displayContent(content: string) {
        return this.isReadMore ? content : this.trimContent(content, 50);
    }

    trimContent(content: string, limit: number) {
        if (content.length <= limit) {
            return content;
        } else {
            return content.substring(0, limit) + '...';
        }
    }

    toggleReadMore(): void {
        this.isReadMore = !this.isReadMore;
    }
}

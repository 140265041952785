import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage';
// import { HttpModule } from '@angular/http';
// import { HttpClientModule } from '@angular/common/http';
import { Stripe } from '@ionic-native/stripe/ngx';
import { BrMaskerModule } from 'br-mask';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
// import { Facebook } from '@ionic-native/facebook/ngx';
import { Generaterecipt2PageModule } from './generaterecipt2/generaterecipt2.module';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

import { File } from '@ionic-native/file/ngx';

import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { PipesModule } from '../app/pipes/pipes.module';;
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { CurrencyPipe } from '@angular/common';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Generaterecipt2Page } from './generaterecipt2/generaterecipt2.page';
import { CameraPageModule } from './camera/camera.module';
import { OpenALPR, OpenALPROptions, OpenALPRResult } from '@ionic-native/openalpr/ngx';

import { ActionsPageModule } from './actions/actions.module';
import { ReactionsPageModule } from './reactions/reactions.module';
import { VideoPopupPageModule } from './video-popup/video-popup.module';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { SocialServicesService } from '../app/services/social-services.service';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { LocationDataService } from './services/location-data/location-data.service';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { AddNewStoryPageModule } from './social-media/pages/add-new-story/add-new-story.module';
import {AboutusPageModule} from 'src/app/aboutus/aboutus.module';
import {ContactUsPageModule} from 'src/app/contact-us/contact-us.module';
import { GalleryCustomModalPageModule } from './gallery-custom-modal/gallery-custom-modal.module';
import { ViewStoriesPageModule } from './social-media/pages/view-stories/view-stories.module';
import { MuteNotificationPageModule } from './social-media/pages/mute-notification/mute-notification.module';
import { AuthpopupPageModule } from './authpopup/authpopup.module';
import { AuthpopupemailPageModule } from './authpopupemail/authpopupemail.module';

import { GrapevineModelPageModule } from './grapevine-model/grapevine-model.module';
import { SelectUsersPageModule } from './select-users/select-users.module';
import { FunctionsService } from '../app/shopping/services-shop/functions.service';
import { DataService } from '../app/shopping/services-shop/data.service';
import { WoocommerceService } from '../app/shopping/services-shop/woocommerce.service';
import * as firebase from 'firebase';
import { environment } from '../environments/environment';
import { ConfirmAccountPageModule } from './confirm-account/confirm-account.module';
import { SigninSignupPageModule } from './signin-signup/signin-signup.module';
import { FeedbackPageModule } from './feedback/feedback.module';
import { CountryListPageModule } from './country-list/country-list.module';
import { NewAddressPageModule } from './shopping/pages/new-address/new-address.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';






// import { ProductmodalPageModule } from './productmodal/productmodal.module';
// import { FeedbackmodalPageModule } from './feedbackmodal/feedbackmodal.module';
// import { ReferfriendmodalPageModule } from './referfriendmodal/referfriendmodal.module';
// import { FrontofcardPageModule } from './frontofcard/frontofcard.module';
// import { ProfilegallerymodalPageModule } from './profilegallerymodal/profilegallerymodal.module';
// import { SelecttypeidPageModule } from './selecttypeid/selecttypeid.module';
// import { IdrequestPageModule } from './idrequest/idrequest.module';
import { PrivacyRightsPageModule } from './privacy-rights/privacy-rights.module';
// firebase.initializeApp(environment.firebase);

firebase.initializeApp({
    apiKey: "AIzaSyBBZQ2CILmg3-E6a78a-YOofQuftQtaRhQ",
    authDomain: "tagster-56569.firebaseapp.com",
    databaseURL: "https://tagster-56569.firebaseio.com",
    projectId: "tagster-56569",
    storageBucket: "tagster-56569.appspot.com",
    messagingSenderId: "1029346550454",
    appId: "1:1029346550454:web:ae916960d66613effefc17"
});


import { StoryViewersPageModule } from './social-media/pages/story-viewers/story-viewers.module';


import { TagmenusPageModule } from './tagmenus/tagmenus.module';
import { SocialProfileMenuPageModule } from './custom-popover/social-profile-menu/social-profile-menu.module';

import { CustomSplashPageModule } from './custom-splash/custom-splash.module';
import { SelectUserPageModule } from './select-user/select-user.module';
import { SearchListingPageModule } from './search-listing/search-listing.module';
import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

// import { LottieAnimationViewModule } from 'ng-lottie';

import { IonBottomDrawerModule } from 'ion-bottom-drawer';
import { TermsPageModule } from './terms/terms.module';
import { WelcomePageModule } from './welcome/welcome.module';
import { ContactsyncPageModule } from './contactsync/contactsync.module';
import { Contacts, Contact, ContactField, ContactName } from '@ionic-native/contacts/ngx';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';

import { SetupNotificationPageModule } from './setup-notification/setup-notification.module';

import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { Device } from '@ionic-native/device/ngx';
// import { PickerModule } from '@ctrl/ngx-emoji-mart'

// import { HttpClientModule } from '@angular/common/http';

import { UserVerifyFrontPageModule } from './subscription/pages/user-verify-front/user-verify-front.module';

import { CuServiceService } from './services/cu-service/cu-service.service';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './translate-config.service';
import { SelectLocationPageModule } from './social-media/pages/select-location/select-location.module';
import { FeedbackmodalPageModule } from './feedbackmodal/feedbackmodal.module';
import { ContactusModalPageModule } from './contactus-modal/contactus-modal.module';
import { ProductmodalPageModule } from './productmodal/productmodal.module';
import { FeedsmodalPageModule } from './feedsmodal/feedsmodal.module';
import { ProfilegallerymodalPageModule } from './profilegallerymodal/profilegallerymodal.module';
import { LanguagePageModule } from './language/language.module';
import { AuthGuard } from './auth.guard';
import { CapturePhotoPageModule } from './capture-photo/capture-photo.module';
import { FriendListPageModule } from './social-media/pages/friend-list/friend-list.module';
import { NewMessagePageModule } from './new-message/new-message.module';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { AddHideStoryPageModule } from 'src/app/add-hide-story/add-hide-story.module';
export function LanguageLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

// PopoverComponent
// import { PopoverComponent } from './components/popover/popover.component';

// import { LottieAnimationViewModule } from 'ng-lottie';

// https://tagster.com//

// ionic cordova plugin add ionic-plugin-deeplinks --variable URL_SCHEME=tagster --variable DEEPLINK_SCHEME=http --variable DEEPLINK_HOST=tagster.betaplanets.com
@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        // LottieAnimationViewModule.forRoot(), 
        BrMaskerModule,
        PipesModule,
        BrowserModule,
        HttpClientModule,
        SigninSignupPageModule,
        ContactusModalPageModule,
        FeedbackPageModule,
        LanguagePageModule,
        PrivacyRightsPageModule,
        FeedbackmodalPageModule,
        ProductmodalPageModule,
        FeedsmodalPageModule,
        CapturePhotoPageModule,
        ProfilegallerymodalPageModule,
        FriendListPageModule,
        NewMessagePageModule,
        AddHideStoryPageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (LanguageLoader),
                deps: [HttpClient]
            }
        }),
        // HttpModule,
        ActionsPageModule,
        ReactionsPageModule,
        StoryViewersPageModule,
        TagmenusPageModule,
        SocialProfileMenuPageModule,
        CustomSplashPageModule,
        UserVerifyFrontPageModule,
        SelectUserPageModule,
        SelectLocationPageModule,
        SearchListingPageModule,
        AddNewStoryPageModule,
        AboutusPageModule,
        ContactUsPageModule,
        GalleryCustomModalPageModule,
        AuthpopupPageModule,
        AuthpopupemailPageModule,
        ConfirmAccountPageModule,
        ViewStoriesPageModule,
        SelectUsersPageModule,
        GrapevineModelPageModule,
        TermsPageModule,
        SetupNotificationPageModule,
        WelcomePageModule,
        ContactsyncPageModule,
        IonBottomDrawerModule,
        MuteNotificationPageModule,
        CountryListPageModule,
        // PopoverComponent,
        IonicModule.forRoot({
            animated: false
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        Generaterecipt2PageModule,
        CameraPageModule,
        NewAddressPageModule,
        NgxIonicImageViewerModule,
    ],
    providers: [
        AuthGuard,
        StatusBar,
        SplashScreen,
        OneSignal,
        Globalization,
        NativeStorage,
        Stripe,
        // Facebook,
        BarcodeScanner,
        Camera,
        File,
        FilePath,
        PhotoViewer,
        FileTransfer,
        // GooglePlus,
        CurrencyPipe,
        SocialSharing,
        DocumentViewer,
        FileOpener,
        OpenALPR,
        SocialServicesService,
        Generaterecipt2Page,
        StreamingMedia,
        MediaCapture,
        VideoEditor,
        FingerprintAIO,
        PhotoLibrary,
        LocationDataService,
        CuServiceService,
        NativePageTransitions,
        NativeGeocoder,
        Geolocation,
        Deeplinks,
        Contacts,
        Media,
        InAppBrowser,
        Chooser,
        FileChooser,
        IOSFilePicker,
        PreviewAnyFile,
        Device,
        //   PickerModule,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        FormsModule, ReactiveFormsModule,
        FunctionsService,
        DataService,
        WoocommerceService,
        TranslateConfigService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, LoadingController, AlertController, Events, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router'; 
import { ServiceForAllService } from '../service-for-all.service';

@Component({
  selector: 'app-contactsync',
  templateUrl: './contactsync.page.html',
  styleUrls: ['./contactsync.page.scss'],
})
export class ContactsyncPage implements OnInit {
  user: any;

  connect_contacts:any;
  allow_contact_discovery:any;

  ready:boolean=false;

  constructor(
    public serviceForAllService: ServiceForAllService, 
    public events: Events,
    public storage: Storage,
    public modalController: ModalController,
    public router: Router,
  ) { 
      
  }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.getContactSettings();
  }

  getContactSettings(){
    this.ready = false;
    this.storage.get('user').then((val) => {
        console.log('userData: ', val);
        if (val != null) {
        this.user = val;

        this.serviceForAllService.getContactSettingsData(val.token).subscribe((result) => {
            this.ready = true;
            let r:any = [];
            r = result;
            this.connect_contacts = r.connect_contacts;
            this.allow_contact_discovery = r.allow_contact_discovery;
        },err=>{
            this.ready = true;
            this.connect_contacts = false;
            this.allow_contact_discovery = false;
        });
      }
    },(err)=>{
        this.ready = true;
    });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({ 
      'dismissed': true
    });
  }

  saveSetiings(){
    this.ready = false;
    this.serviceForAllService.showLoader();

    this.storage.get('user').then((val) => {
        console.log('userData: ', val);
        if (val != null) {
        this.user = val;

        this.serviceForAllService.saveContactSettingsData(val.token, this.connect_contacts, this.allow_contact_discovery).subscribe((result) => {
            this.ready = true;
            this.serviceForAllService.dismissLoading();
            let r:any = [];
            r = result;
            this.serviceForAllService.presentToast("Contact settings saved successfully.");
            // 

            // Open Suggested Contact Users

            this.dismiss();

            console.log("this.connect_contacts:: ", this.connect_contacts);

            if(this.connect_contacts && this.connect_contacts==true){
                this.router.navigate(['/suggested']);
            }


        },err=>{
            this.ready = true;
            this.serviceForAllService.dismissLoading();
            this.serviceForAllService.presentToast("Please try again!");
        });
      }
    },(err)=>{
        this.ready = true;
        this.serviceForAllService.dismissLoading();
        this.serviceForAllService.presentToast("Please try again!");
    }); 
  }

}

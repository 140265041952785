import { Component, OnInit } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ModalController,NavController } from '@ionic/angular'; 
import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { WelcomePage } from '../welcome/welcome.page';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-custom-splash',
  templateUrl: './custom-splash.page.html',
  styleUrls: ['./custom-splash.page.scss'],
})
export class CustomSplashPage implements OnInit {
  display:any=0;
  constructor(
    public splashScreen:SplashScreen,
    public modalController:ModalController,
    private nativePageTransitions: NativePageTransitions,
    public storage: Storage,
  ) { 
    
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.splashScreen.hide();
    setTimeout(() => {
      this.modalController.dismiss({
        'dismissed': true
      });

      // setTimeout(() => {
      //   this.storage.get('FIRST_TIME_OPEN').then((val) => {
      //     console.log("VLA: ", val);
      //     if(!val){
      //       this.firstTimePopup();
      //     }
      //   },
      //   (err) => {
      //     this.firstTimePopup();
      //   });
      // }, 1000);
      // this.firstTimePopup();
      
    }, 5000);
  }

  async firstTimePopup() {
    const modal = await this.modalController.create({
      component: WelcomePage,
      cssClass: 'my-custom-class',
      componentProps: {
      }
    });
    modal.onDidDismiss().then((dataReturned) => {
      console.log('return');
    });
    this.storage.set('FIRST_TIME_OPEN', '1');
    return await modal.present();
  }

  ionViewWillLeave() {

    let options: NativeTransitionOptions = {
       direction: 'up',
       duration: 500,
       slowdownfactor: 3,
       slidePixels: 20,
       iosdelay: 100,
       androiddelay: 150,
       fixedPixelsTop: 0,
       fixedPixelsBottom: 60
      }
   
    this.nativePageTransitions.slide(options);
   }

}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  url: 'https://app.tagster.com/', 
 // url: 'https://ebluestone.betaplanets.com', 
  // consumerKey: 'ck_61ba04acc753efc56ff69b65528433f88ab4bffd',
  // consumerSecret: 'cs_69b73ba1f235118f121a25f0fc1e035a216c610e',
  consumerKey: 'ck_edf99652b9fbb74b8578c38c23c077282dcdc7ea', 
  consumerSecret: 'cs_d795f986cc900039ea0ec17326373c3ebd944ee3',
  terms_of_use:
    'The Terms and Conditions agreement can act as a legal contract between you, the mobile app owner or developer, and the users of your app. Like a Terms and Conditions for a website, this agreement for a mobile app would set the rules and terms that users must follow in order to use your app.' +
    'Here are a couple of reasons why you\'ll want to have a Terms and Conditions for a mobile app:' +
    'You can stop abusive users from using your app.' +
    'You can terminate or block accounts at your sole discretion.' +
    'Liability to users will be limited.' +
    'And many more.' +
    'If you don\'t have this agreement for your mobile app yet, use the Generator to create it!',
  privacy_policy:
    'You\'ll need the Privacy Policy agreement even if you don\'t collect any personal data yourself through the mobile app you\'re building, but instead use third party tools such as:' +
    '- Google Analytics Mobile' +
    '- Flurry' +
    '- Firebase' +
    '- Mixpanel' +
    'And so on' +
    'If you use at least one third party tool that might collect personal data through your mobile app, you need this agreement in place.' +
    'Each app store also requires you to have this agreement in place before submitting the mobile app:' +
    '- Apple App Store' +
    '- Google Play Store' +
    '- Microsoft Windows Phone Store',
  faqs: {
    'Shipping and Delivery': [
      {
        'How log does shipping take?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How can I track my order?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How much does shipping cost?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Where does my order ship from?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How do I change my shipping address?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ],
    'Returns and Refunds': [
      {
        'How do I return a product?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can I exchange an item?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How do I cancel my order?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ],
    'Payment, Pricing & Promotions': [
      {
        'How do I return a product?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can I exchange an item?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How do I cancel my order?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ],
    Orders: [
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ],
    'Managing Your Account': [
      {
        'How do I return a product?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can I exchange an item?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How do I cancel my order?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ],
    'User Feedback': [
      {
        'How do I return a product?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can I exchange an item?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How do I cancel my order?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ],
    'Customer Support': [
      {
        'How do I return a product?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can I exchange an item?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'How do I cancel my order?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'What\'s the status of my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'Can you issue my refund to a different card if my card is canceled, lost, expired, or stolen?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      },
      {
        'I cancelled my order. How will I receive my refund?':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit sed lacus non condimentum. Sed sapien augue, ornare non eros eu, bibendum pulvinar purus. Aenean eu blandit elit, quis tincidunt turpis.'
      }
    ]
  },
  wish_cash: {
    currency: '$',
    amount: 0.0,
    history: [{ amount: 10 }, { amount: 20 }]
  },
  products_1: [
    {
      name: 'Bianca Top',
      id: 12312,
      cost_price: 128,
      discount: 80,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/1.jpg',
        'assets/images/products/1_1.jpg',
        'assets/images/products/1_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 1200,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/1.jpg',
            'assets/images/products/1_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/1_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/1.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/1.jpg',
            'assets/images/products/1_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/1_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/1.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Scarpetta Dress',
      id: 12313,
      cost_price: 198,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/2.jpg',
        'assets/images/products/2_1.jpg',
        'assets/images/products/2_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 400,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/2.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/2_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/2.jpg',
            'assets/images/products/2_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/2.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Misty Dress',
      id: 12314,
      cost_price: 218,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/3.jpg',
        'assets/images/products/3_1.jpg',
        'assets/images/products/3_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 365,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/3.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/3.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/3_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/3.jpg',
            'assets/images/products/3_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/3.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/3.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Blanch Dress',
      id: 12315,
      cost_price: 248,
      discount: 20,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/4.jpg',
        'assets/images/products/4_1.jpg',
        'assets/images/products/4_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 1200,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/4.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/4.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/4_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/4.jpg',
            'assets/images/products/4_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/4.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/4.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Scarlett Dress',
      id: 12316,
      cost_price: 218,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/5.jpg',
        'assets/images/products/5_1.jpg',
        'assets/images/products/5_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 234,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/5.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/5.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/5_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/5.jpg',
            'assets/images/products/5_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/5.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/5.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    }
  ],
  products_2: [
    {
      name: 'Morrisson Dress',
      id: 12317,
      cost_price: 128,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/6.jpg',
        'assets/images/products/6_1.jpg',
        'assets/images/products/6_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 567,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/6.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/6.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/6_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/6.jpg',
            'assets/images/products/6_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/6.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/6.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Morrisson Dress',
      id: 12318,
      cost_price: 128,
      discount: 80,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/7.jpg',
        'assets/images/products/7_1.jpg',
        'assets/images/products/7_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 137,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/7.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/7.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/7_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/7.jpg',
            'assets/images/products/7_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/7.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/7.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Smith Dress',
      id: 12319,
      cost_price: 98,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/8.jpg',
        'assets/images/products/8_1.jpg',
        'assets/images/products/8_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 236,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/8.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/8.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/8.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/8.jpg',
            'assets/images/products/8_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/8.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/8.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Nicola Dress',
      id: 12320,
      cost_price: 278,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/9.jpg',
        'assets/images/products/9_1.jpg',
        'assets/images/products/9_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 982,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/9.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/9.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/9_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/9.jpg',
            'assets/images/products/9_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/9.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/9.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Smith Dress',
      id: 12321,
      cost_price: 98,
      discount: 80,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/10.jpg',
        'assets/images/products/10_1.jpg',
        'assets/images/products/10_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 214,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/10.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/10.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/10_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/10.jpg',
            'assets/images/products/10_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/10.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/10.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    }
  ],
  orders: [
    {
      product: {
        name: 'Bianca Top',
        cost_price: 128,
        discount: 80,
        offer: true,
        stock: 69,
        description: 'sample data',
        image: [
          'assets/images/products/1.jpg',
          'assets/images/products/1_1.jpg',
          'assets/images/products/1_2.jpg'
        ],
        rating_count: 11,
        store_rating_count: 11,
        currency: '$',
        bought: 1200,
        size: 'M',
        color: 'Black',
        shipping: 250,
        rating: 4,
        store_rating: 18090,
        store_rate: 3,
        sold_by: 'seller',
        specs: 'this is a sample product',
        reviews: [
          {
            image: 'assets/images/products/1.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: [
              'assets/images/products/1.jpg',
              'assets/images/products/1_1.jpg'
            ]
          },
          {
            image: 'assets/images/products/1_2.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: ['assets/images/products/1.jpg']
          }
        ],
        store_reviews: [
          {
            image: 'assets/images/products/1.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: [
              'assets/images/products/1.jpg',
              'assets/images/products/1_1.jpg'
            ]
          },
          {
            image: 'assets/images/products/1_2.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: ['assets/images/products/1.jpg']
          }
        ],
        sizing: { small: 10, okay: 80, large: 5 },
        buyer_guarantee:
          'Return all products within 30 days of delivery if they are not up to your satisfaction',
        sponsored: []
      },
      amount: 123,
      billing_address: {
        address_line_1: 'ghar',
        address_line_2: 'ghar',
        city: 'jaipur',
        last_name: 'bond',
        phone_number: 1125532553,
        postcode: 12345,
        country: 'India',
        first_name: 'James',
        state: 'Rajasthan'
      },
      shipping_address: {
        address_line_1: 'ghar',
        address_line_2: 'ghar',
        city: 'jaipur',
        last_name: 'bond',
        postcode: 12345,
        country: 'India',
        first_name: 'James',
        state: 'Rajasthan'
      },
      delivery_date: new Date(),
      id: 'B102013526',
      order_date: new Date(),
      status: 'Delivered',
      tax: 40
    },
    {
      product: {
        name: 'Morrisson Dress',
        cost_price: 128,
        discount: 80,
        offer: false,
        stock: 69,
        description: 'sample data',
        image: [
          'assets/images/products/6.jpg',
          'assets/images/products/6_1.jpg',
          'assets/images/products/6_2.jpg'
        ],
        rating_count: 11,
        store_rating_count: 11,
        currency: '$',
        bought: 567,
        size: 'M',
        color: 'Black',
        shipping: 250,
        rating: 4,
        store_rating: 18090,
        store_rate: 3,
        sold_by: 'seller',
        specs: 'this is a sample product',
        reviews: [
          {
            image: 'assets/images/products/6.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: ['assets/images/products/6.jpg']
          }
        ],
        store_reviews: [
          {
            image: 'assets/images/products/6_1.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: [
              'assets/images/products/6.jpg',
              'assets/images/products/6_1.jpg'
            ]
          },
          {
            image: 'assets/images/products/6.jpg',
            name: 'sample',
            comment:
              'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
            rating: 5,
            images: ['assets/images/products/6.jpg']
          }
        ],
        sizing: { small: 10, okay: 80, large: 5 },
        buyer_guarantee:
          'Return all products within 30 days of delivery if they are not up to your satisfaction',
        sponsored: []
      },
      amount: 123,
      billing_address: {
        address_line_1: 'ghar',
        address_line_2: 'ghar',
        city: 'jaipur',
        last_name: 'bond',
        phone_number: 1125532553,
        postcode: 12345,
        country: 'India',
        first_name: 'James',
        state: 'Rajasthan'
      },
      shipping_address: {
        address_line_1: 'ghar',
        address_line_2: 'ghar',
        city: 'jaipur',
        last_name: 'bond',
        postcode: 12345,
        country: 'India',
        first_name: 'James',
        state: 'Rajasthan'
      },
      delivery_date: new Date(),
      id: 'B102013526',
      order_date: new Date(),
      status: 'Delivered',
      tax: 40
    }
  ],
  sponsored: [
    {
      name: 'Bianca Top',
      cost_price: 128,
      discount: 80,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/1.jpg',
        'assets/images/products/1_1.jpg',
        'assets/images/products/1_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 1200,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/1.jpg',
            'assets/images/products/1_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/1_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/1.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/1.jpg',
            'assets/images/products/1_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/1_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/1.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Scarpetta Dress',
      cost_price: 198,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/2.jpg',
        'assets/images/products/2_1.jpg',
        'assets/images/products/2_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 400,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/2.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/2_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/2.jpg',
            'assets/images/products/2_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/2.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Misty Dress',
      cost_price: 218,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/3.jpg',
        'assets/images/products/3_1.jpg',
        'assets/images/products/3_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 365,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/3.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/3.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/3_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/3.jpg',
            'assets/images/products/3_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/3.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/3.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Blanch Dress',
      cost_price: 248,
      discount: 20,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/4.jpg',
        'assets/images/products/4_1.jpg',
        'assets/images/products/4_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 1200,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/4.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/4.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/4_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/4.jpg',
            'assets/images/products/4_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/4.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/4.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Scarlett Dress',
      cost_price: 218,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/5.jpg',
        'assets/images/products/5_1.jpg',
        'assets/images/products/5_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 234,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/5.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/5.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/5_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/5.jpg',
            'assets/images/products/5_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/5.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/5.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Morrisson Dress',
      cost_price: 128,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/6.jpg',
        'assets/images/products/6_1.jpg',
        'assets/images/products/6_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 567,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/6.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/6.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/6_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/6.jpg',
            'assets/images/products/6_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/6.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/6.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Morrisson Dress',
      cost_price: 128,
      discount: 80,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/7.jpg',
        'assets/images/products/7_1.jpg',
        'assets/images/products/7_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 137,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/7.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/7.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/7_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/7.jpg',
            'assets/images/products/7_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/7.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/7.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Smith Dress',
      cost_price: 98,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/8.jpg',
        'assets/images/products/8_1.jpg',
        'assets/images/products/8_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 236,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/8.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/8.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/8.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/8.jpg',
            'assets/images/products/8_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/8.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/8.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Nicola Dress',
      cost_price: 278,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/9.jpg',
        'assets/images/products/9_1.jpg',
        'assets/images/products/9_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 982,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/9.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/9.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/9_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/9.jpg',
            'assets/images/products/9_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/9.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/9.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Smith Dress',
      cost_price: 98,
      discount: 80,
      offer: true,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/10.jpg',
        'assets/images/products/10_1.jpg',
        'assets/images/products/10_2.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 214,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/10.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/10.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/10_1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/10.jpg',
            'assets/images/products/10_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/10.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/10.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    },
    {
      name: 'Kinsley Dress',
      cost_price: 198,
      discount: 80,
      offer: false,
      stock: 69,
      description: 'sample data',
      image: [
        'assets/images/products/11.jpg',
        'assets/images/products/11_1.jpg',
        'assets/images/products/11_2.jpg',
        'assets/images/products/11_3.jpg',
        'assets/images/products/11_4.jpg',
        'assets/images/products/11_5.jpg'
      ],
      rating_count: 11,
      store_rating_count: 11,
      currency: '$',
      bought: 434,
      size: 'M',
      color: 'Black',
      shipping: 250,
      rating: 4,
      store_rating: 18090,
      store_rate: 3,
      sold_by: 'seller',
      specs: 'this is a sample product',
      reviews: [
        {
          image: 'assets/images/products/11.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/11.jpg']
        }
      ],
      store_reviews: [
        {
          image: 'assets/images/products/1.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: [
            'assets/images/products/11.jpg',
            'assets/images/products/11_1.jpg'
          ]
        },
        {
          image: 'assets/images/products/11_2.jpg',
          name: 'sample',
          comment:
            'This is an amazing dress and totally out of budget. Need to sell my only kidney to purchase this one as other kidney is already sold for my iphone...lol',
          rating: 5,
          images: ['assets/images/products/1.jpg']
        }
      ],
      sizing: { small: 10, okay: 80, large: 5 },
      buyer_guarantee:
        'Return all products within 30 days of delivery if they are not up to your satisfaction',
      sponsored: []
    }
  ],
  rewards: {
    points: 100,
    since: new Date(),
    available: [
      { discount: 5, code: 'ABCDEF', expire: new Date(), expired: false }
    ],
    used: [{ discount: 10, code: 'XEFGSD', expire: new Date(), expired: true }],
    redeem: [
      { discount: 5, points: 200 },
      { discount: 10, points: 600 },
      { discount: 15, points: 1000 }
    ]
  },
  rewards_tab: [
    { title: 'Dashboard' },
    { title: 'Redeem' },
    { title: 'Information' }
  ],
  notifications_tab: [
    { title: 'All' }
  ],
  item_tab: [
    { title: 'Overview' },
    { title: 'Related Items' },
    { title: 'Product Reviews' }
  ],
  tabs: [
    { title: 'Popular' },
    { title: 'Recently Viewed' },
    { title: 'Fashion' },
    { title: 'Tops' },
    { title: 'Shoes' },
    { title: 'Automotive' },
    { title: 'Bottoms' },
    { title: 'Watches' },
    { title: 'Wallets & Bags' },
    { title: 'Accessories' },
    { title: 'Gadgets' },
    { title: 'Hobbies' },
    { title: 'Phone Upgrades' },
    { title: 'Home Decor' }
  ],
  trending: [
    'jacket',
    'drone',
    'shoes for men',
    'car accessories',
    'blazer for men',
    'watches men',
    'sports shoes for men',
    'earphone bluetooth',
    'jackets for men',
    'memory card'
  ]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

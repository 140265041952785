import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../../service-for-all.service';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
@Component({
  selector: 'app-single-grapevine-model',
  templateUrl: './single-grapevine-model.component.html',
  styleUrls: ['./single-grapevine-model.component.scss'],
})
export class SingleGrapevineModelComponent implements OnInit {
    grapevineInfo: any = [];
    user_id: any = '';
    is_sending: string = 'off';
    loginInfo: any = [];
    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        public storage: Storage,
        public serviceForAllService: ServiceForAllService,
        public popoverController: PopoverController,
        public webservice: ServiceForAllService,
        private router: Router,
        public alertCtrl: AlertController
    ) {
        this.storage.get('user').then((val) => {
            if (val) {
                this.loginInfo = val;
            }
        });
    }

    ngOnInit() {
        this.is_sending = 'off';
        this.grapevineInfo = this.navParams.data.grapevineInfo;
        console.log("grapevineInfo1:", this.grapevineInfo);
        this.grapevineInfo['tmp_amount'] = 0;
        console.log("grapevineInfo2:", this.grapevineInfo);

        this.storage.get('user').then((val) => {
            console.log('userData:', val);
            if (val != null) {
                this.loginInfo = val;
                this.user_id = this.loginInfo.user_id;
                //   this.viewedStory(this.index);
            }
        });

    }

    async closeModal() {
        const onClosedData: string = "Wrapped Up!";
        await this.modalController.dismiss(onClosedData);
    }

    private increment() {
        this.grapevineInfo.tmp_amount++;
    }

    private decrement() {
        if (this.grapevineInfo.tmp_amount > 0) {
            this.grapevineInfo.tmp_amount--;
        }
    }

    goGrapeMap() {
        this.modalController.dismiss({
            goto_page : 'grapevine_map',
            grapevineInfo : this.grapevineInfo,
        });
    }

    validate_before_contribution() {
        if(this.loginInfo.user_id == this.grapevineInfo.authorInfo.user_id){
            this.serviceForAllService.presentAlert("You can not send grapevine to yourself.");
            return false;
        }
        if (this.grapevineInfo.tmp_amount == undefined) {
            this.grapevineInfo.tmp_amount = 0;
        }
        let owner = this.grapevineInfo.authorInfo;
        console.log("owner:", owner);

        if (this.grapevineInfo.tmp_amount <= 0) {
            this.serviceForAllService.presentToast("Please select a amount.");
            return false;
        }

        if (this.loginInfo.default_card_id == '') {
            this.serviceForAllService.presentAlert("You have not set default payment method.");
            return false;
        }
        this.confirmSending();
    }

    async confirmSending() {
        // let anonymous = (this.anonymous == true) ? 'yes' : 'no';

       
        let sendData = {
            token: this.loginInfo.token,
            grapevine_id: this.grapevineInfo.grapevine_id,
            amount: this.grapevineInfo.tmp_amount,
            receiver_user_id: this.grapevineInfo.author,
            receiver_bank_id: this.grapevineInfo.authorInfo.default_bank_id,
            sender_card_id: this.loginInfo.default_card_id,
            anonymous: "",
            description: "",
            entry_type : 'grapevine'
        }
        console.log("sendData:", sendData);
        const alert = await this.alertCtrl.create({
            header: 'Confirm',
            message: 'Are you sure you want to send $' + this.grapevineInfo.tmp_amount + '?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Yes',
                    handler: () => {

                        this.is_sending = 'on';
                        this.serviceForAllService.send_contribution(sendData).subscribe((response) => {
                            console.log('response:', response);
                            this.is_sending = 'off';
                            if (response['status'] == 'ok') {
                                this.grapevineInfo.received_amount = parseFloat(this.grapevineInfo.received_amount) + parseFloat(this.grapevineInfo.tmp_amount);
                                this.grapevineInfo.tmp_amount = 0;
                                if (response['grapevineInfo']) {
                                    this.grapevineInfo = response['grapevineInfo'];
                                }
                                // this.serviceForAllService.presentToast(response['msg']);
                                this.closing_model();
                                // this.serviceForAllService.success_alert();
                                // this.router.navigate(['/your-geo']);
                            } else {
                                this.serviceForAllService.presentAlert(response['msg']);
                            }
                        }, (err) => {
                            this.is_sending = 'off';
                            console.log("err:", err);
                            this.serviceForAllService.presentAlert(err.error.msg);
                            if (err.error.error_code == 'token_expired') {
                            }
                        });

                    }
                }
            ]
        });
        await alert.present();
    }

    closing_model(){
        this.modalController.dismiss({
            sent : 'yes',
            goto_page : 'grapevine_map',
            grapevineInfo : this.grapevineInfo,
        });
    }
}
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { SocialServicesService } from '../../../services/social-services.service';
import { Storage } from '@ionic/storage';
import { Events, NavController, NavParams, ToastController, ModalController, MenuController, LoadingController, AlertController, ActionSheetController } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
// import { Camera, CameraOptions,  } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions, CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { VideoEditor, CreateThumbnailOptions } from '@ionic-native/video-editor/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { VideoPopupPage } from '../../../video-popup/video-popup.page';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceForAllService } from '../../../service-for-all.service';
import { CapturePhotoPage } from '../../../capture-photo/capture-photo.page';
import * as $ from 'jQuery';
import { exit } from 'process';
var url = "https://app.tagster.com/";
@Component({
    selector: 'app-add-new-story',
    templateUrl: './add-new-story.page.html',
    styleUrls: ['./add-new-story.page.scss'],
})
export class AddNewStoryPage implements OnInit {
    base64: any = '';
    base64Video: any;
    imageURI: any;
    videoFile: any;
    videoFileName: any;

    loginInfo: any = [];
    story_content: any = '';
    attachment_id: any = '';
    video_file_url: any = '';// 'https://tagster.com/wp-content/uploads/2021/03/63696112543__337C6D2D-F4F5-47A5-BD62-D8A9104D0F3C-1.mov';
    is_spinner: string = 'off';
    attachment_type: any = 'text';
    video_thumbnail_url: any = '';
    _thumbnail_id: any = '';
    constructor(
        private ref: ChangeDetectorRef,
        // private navParams: NavParams,
        public navCtrl: NavController,
        private platform: Platform,
        public storage: Storage,
        private route: ActivatedRoute,
        private router: Router,
        public socialServices: SocialServicesService,
        public toastController: ToastController,
        public modalController: ModalController,
        public menu: MenuController,
        public events: Events,
        public loadingCtrl: LoadingController,
        public alertCtrl: AlertController,
        private actionSheetController: ActionSheetController,
        private camera: Camera,
        public mediaCapture: MediaCapture,
        private file: File,
        private transfer: FileTransfer,
        public videoEditor: VideoEditor,
        private photoViewer: PhotoViewer,
        private cdRef: ChangeDetectorRef,
        // private androidPermissions: AndroidPermissions,
        private streamingMedia: StreamingMedia,
        private filePath: FilePath,
        private sanitizer: DomSanitizer,
        public serviceForAllService: ServiceForAllService,
    ) { }

    ionViewWillEnter() {
        this.storage.get('user').then((user) => {
            if (user) {
                this.loginInfo = user;
                console.log("loginInfo:", this.loginInfo);
            } else {
                this.serviceForAllService.presentToast("Token is expired. Please login again");
            }
        });
    }
    async closeModal() {
        if (this.attachment_type == 'video') {
            let alert = await this.alertCtrl.create({
                header: 'Confirm',
                message: "Are you sure want to leave this page?",
                buttons: [{
                    text: 'Leave',
                    handler: () => {
                        console.log("Delete Upload file/media");
                        this.delete_media_attachments();
                        this.modalController.dismiss();
                    }
                }, {
                    text: 'Stay',
                    role: 'cancel',
                    handler: () => {

                    }
                }],
                cssClass: 'comment-alert'
            });
            await alert.present();
        } else {
            this.modalController.dismiss();
        }
    }

    delete_media_attachments() {
        let sendData = {
            token: this.loginInfo.token,
            attachment_id: this.attachment_id,
            _thumbnail_id: this._thumbnail_id,
        }
        console.log("sendData:", sendData);
        this.serviceForAllService.showLoader("Removing");
        this.serviceForAllService.sendData('delete_attachment', sendData).subscribe((response) => {
            console.log("response:", response);
            this.serviceForAllService.dismissLoading();
            if (response['status'] == 'ok') {
                this.attachment_type = 'text';
                this.video_file_url = '';
                this.attachment_id = '';
                this._thumbnail_id = '';
                this.video_thumbnail_url = '';
                this.serviceForAllService.presentToast(response['msg']);
                this.cdRef.detectChanges();
            } else {
                this.serviceForAllService.presentToast(response['msg']);
            }
        }, (err) => {
            console.log("err:", err);
            this.serviceForAllService.dismissLoading();
            this.serviceForAllService.presentToast(err.error.msg);
        });
    }

    ngOnInit() {
    }

    create_new_story() {
        console.log("attachment_type:", this.attachment_type);

        if (this.attachment_type == 'image') {
            if (this.attachment_id == '' && this.base64 == '') {
                this.serviceForAllService.presentToast("Please select a attachment.");
                return false;
            }
            let sendData = {
                token: this.loginInfo.token,
                story_content: this.story_content.trim(),
                attachment_id: this.attachment_id,
                base64: this.base64,
                attachment_type: this.attachment_type,
            }
            console.log("sendData:", sendData);
            this.serviceForAllService.showLoader();
            this.serviceForAllService.create_new_story(sendData).subscribe((response) => {
                this.serviceForAllService.dismissLoading();
                console.log("response:", response);
                if (response['status'] == "ok") {
                    this.events.publish('story:created', 1);
                    this.modalController.dismiss();
                    this.navCtrl.navigateBack('/social-media-dashboard');
                } else {
                    this.serviceForAllService.presentToast(response['msg']);
                }
            }, (err) => {
                console.log("Err:", err);
                this.serviceForAllService.dismissLoading();
                this.serviceForAllService.presentToast(err.error.msg);
            });
        }
        else {
            if (this.attachment_id == '' && this.video_file_url == '') {
                this.serviceForAllService.presentToast("Please select a attachment.");
                return false;
            }
            let SendData = {
                token: this.loginInfo.token,
                story_content: this.story_content,
                attachment_id: this.attachment_id,
                video_file_url: this.video_file_url,
                _thumbnail_id: this._thumbnail_id,
                attachment_type: this.attachment_type,
            };
            console.log("SendData(497):", SendData);
            this.serviceForAllService.showLoader();
            this.serviceForAllService.create_new_story(SendData).subscribe((response) => {
                console.log("response:", response);
                this.serviceForAllService.dismissLoading();
                if (response['status'] == 'ok') {
                    this.story_content = '';
                    this.events.publish('story:created', 1);
                    this.modalController.dismiss();
                } else {
                    this.serviceForAllService.presentToast(response['msg']);
                }
            }, (err) => {
                this.serviceForAllService.dismissLoading();
                this.serviceForAllService.presentToast(err.error.msg);
            });
        }
    }

    async remove_story_media() {
        console.log('hellooooo');
        if (this.attachment_type == 'video') {
            let alert = await this.alertCtrl.create({
                header: 'Confirm',
                message: "Are you sure want to remove?",
                buttons: [{
                    text: 'Remove',
                    handler: () => {
                        this.delete_media_attachments();
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {

                    }
                }],
                cssClass: 'comment-alert'
            });
            await alert.present();
        } else {
            this.base64 = '';
            this.attachment_type = 'text';
            this.cdRef.detectChanges();
        }
    }

    playVideo(url) {
        console.log('Video url: ' + url);
        this.platform.ready().then(() => {
            if (this.platform.is('android')) {
                console.log('I m android');
                this.open_video_modal_popup(url);
            } else {
                let options: StreamingVideoOptions = {
                    successCallback: () => { console.log('Video played') },
                    errorCallback: (e) => { console.log('Error streaming', e) },
                    // orientation: 'landscape',
                    shouldAutoClose: true,
                    controls: true
                };
                this.streamingMedia.playVideo(url, options);
            }
        });
    }
    async open_video_modal_popup(url) {
        let video_thumbnail = url + '#t=0,2';
        const modal = await this.modalController.create({
            component: VideoPopupPage,
            componentProps: {
                url: url,
                video_thumbnail: video_thumbnail
            },
            cssClass: 'video_popup_modal_css'
        });
        // modal.onDidDismiss().then((res) => {
        //   this.modal_response = res;
        //   console.log("modal_response: "+JSON.stringify(this.modal_response));
        // });
        return await modal.present();
    }

    // PWA
    // async upldPic(upload_type) {
    //     console.log('actionSheetController=>');
    //     const actionSheet = await this.actionSheetController.create({
    //         header: "Select Image Source",
    //         buttons: [
    //         // {
    //         //     text: 'Use Camera',
    //         //     handler: () => {
    //         //         this.start_camera(upload_type);
    //         //     }
    //         // },
    //         {
    //             text: 'Load from Library',
    //             handler: () => {
    //                 this.selectPhoto(upload_type);
    //             }
    //         },
    //         {
    //             text: 'Cancel',
    //             role: 'cancel'
    //         }]
    //     });
    //     await actionSheet.present();
    // }

    selectPhoto(upload_type) {
        $('input[type="file"]').trigger('click');
    }
    select_file_from_library(event) {
        console.log("event.target.files[0]:", event.target.files[0]);
        this.uploadAll(event.target.files[0], 'FileSystem');
    }

    async start_camera(upload_type) {
        console.log('upload_type', upload_type);
        const modal = await this.modalController.create({
            component: CapturePhotoPage,
            componentProps: { upload_type: upload_type },
            cssClass: 'capture_photo_modal'
        });

        modal.onDidDismiss().then((res) => {
            console.log("res:", res);
            this.base64 = res.data.base64;
            this.attachment_type = 'image';
            //   this.modal_response = res;
            // this.GetUserImage();
            //   console.log("modal_response: " + JSON.stringify(this.modal_response));
        });

        return await modal.present();
    }



    async uploadAll(webPath: string, type: string) {
        console.log("type: " + type);
        this.serviceForAllService.showLoader('Uploading...');
        let date = new Date(),
            time = date.getTime(),
            fileName = time + ".jpg";
        const blob = '';

        const formData = new FormData();
        if (type == 'Camera') {
            const blob = await fetch(webPath).then(r => r.blob());
            formData.append('file', blob, fileName);

        } else if (type == 'Record') {
            const blob = await fetch(webPath).then(r => r.blob());
            formData.append('file', blob, time + '.webm');

        } else {
            const blob = webPath;
            formData.append('file', blob);
            console.log('formData', formData);
        }

        // formData.append('upload_type', this.upload_type);
        formData.append('token', this.loginInfo.token);

        let file: any = [];
        file = formData.get('file');
        let file_type = file.type;
        let video_file_name = file.name;
        formData.append('file_name', video_file_name);
        console.log("file_name =", video_file_name);
        console.log("file_type.split('/')[0]:", file_type.split('/')[0]);
        if (file_type.split('/')[0] == 'video') {
            formData.append('upload_type', 'video');
            console.log("formData:", formData);
            this.serviceForAllService.upload_video(formData).subscribe(res => {
                console.log("res:", res);
                this.serviceForAllService.dismissLoading();
                // this.video_attachment_url = this.serviceForAllService.wpUrl + d.post_attachment_url;
                // this.video_file_name = video_file_name;
                this.cdRef.detectChanges();
            }, (err) => {
                this.serviceForAllService.dismissLoading();
                console.log("err", err);
                if (err.error_code == 'token_expired') {
                    this.serviceForAllService.presentToast('Token is expired. Please login again.');

                }
            });
        } else if (file_type.split('/')[0] == 'image') {
            console.log("file_type.split('/')[0]:->Image:", file_type.split('/')[0]);
            this.serviceForAllService.upload_image(formData).subscribe(res => {
                this.serviceForAllService.dismissLoading();
                console.log("res:", res);
                if (res['status'] == 'ok') {
                    this.attachment_id = res['attachment_id'];
                    this.attachment_type = 'image';
                    this.base64 = res['file_path'];
                }
            }, (err) => {
                console.log("err:", err);
                this.serviceForAllService.dismissLoading();
                if (err.error_code == 'user_expire') {
                    this.serviceForAllService.presentToast('Please login again token expired.');
                    this.storage.remove('user');
                    this.router.navigate(['/signin']);
                }
            });
        } else {
            console.log("Something went wrong");
        }
    }

}

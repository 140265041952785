import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { VideoPopupPage } from '../video-popup/video-popup.page';
import { Storage } from '@ionic/storage';
import { IonSlides } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { PopoverController } from '@ionic/angular';
import { StoryViewersPage } from '../social-media/pages/story-viewers/story-viewers.page';

@Component({
  selector: 'app-gallery-custom-modal',
  templateUrl: './gallery-custom-modal.page.html',
  styleUrls: ['./gallery-custom-modal.page.scss'],
})
export class GalleryCustomModalPage implements OnInit {
  @ViewChild('slides', {static: true}) slides: IonSlides;
  
  loading:any;
  slideOpts:any = {
    initialSlide: 0,
    speed: 1000
  };
  stories:any=[];
  index:number;
  user:any;
  user_id:any = '';

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    private streamingMedia: StreamingMedia,
    public storage: Storage,
    public serviceForAllService: ServiceForAllService,
    public popoverController: PopoverController,
  ) { 
    
  }
 
  ngOnInit() { 
    
    console.table(this.navParams);
    this.index = this.navParams.data.index;
    this.stories = this.navParams.data.stories;

    console.log(this.stories);
    console.log(this.stories[this.index]);

    console.log('this.index: ', this.index);
    this.slideOpts = {
      initialSlide: this.index,
      speed: 1000
    }

    this.storage.get('user').then((val) => {
      console.log('userData: ', val);
      if (val != null) {
        this.user = val;
        this.user_id = val.user_id;
        this.viewedStory(this.index);
      }
    });

  }
 
  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  playVideo(url, video_thumbnail) {
    console.log('Video url: ' + url);
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        console.log('I m android');
        this.open_video_modal_popup(url, video_thumbnail);
      } else {
        let options: StreamingVideoOptions = {
          successCallback: () => { console.log('Video played') },
          errorCallback: (e) => { console.log('Error streaming', e) },
          // orientation: 'landscape',
          shouldAutoClose: true,
          controls: true
        };
        this.streamingMedia.playVideo(url, options);
      }
    });
  }

  async open_video_modal_popup(url, video_thumbnail) {
    const modal = await this.modalController.create({
      component: VideoPopupPage,
      componentProps: {
        url: url,
        video_thumbnail: video_thumbnail
      },
      cssClass: 'video_popup_modal_css'
    });

    // modal.onDidDismiss().then((res) => {
    //   this.modal_response = res;
    //   console.log("modal_response: "+JSON.stringify(this.modal_response));
    // });

    return await modal.present();
  }

  slideChanged(e: any) {
    this.slides.getActiveIndex().then((index: number) => {
      console.log(index);
      this.viewedStory(index);
    });
  }

  viewedStory(i){
    if(this.stories[i].author != this.user_id){
      console.log("other user");
      this.makeStoryViewed(this.stories[i].id);
    }
    else{
      console.log("same user");
    }
  }

  makeStoryViewed(story_id){
    this.serviceForAllService.setStatus(this.user.token, story_id)
      .subscribe(res => { console.log("viewed"); });
  }

  async presentPopover(ev: any, view_data) {
    if(view_data && view_data.length>0){
      const popover = await this.popoverController.create({
        component: StoryViewersPage,
        componentProps: { view_data : view_data },
        event: ev,
        translucent: true,
        cssClass: 'viewers-popup'
      });
  
      // popover.onDidDismiss().then((dataReturned) => {
      //   console.log(dataReturned);
      //   if (dataReturned !== null) {
      //     let d = dataReturned.data;
  
      //   }
      // });

      // let ev = {
      //   target : {
      //     getBoundingClientRect : () => {
      //       return {
      //         top: 100
      //       };
      //     }
      //   }
      // };
  
      return await popover.present();
    }
  }

}


 
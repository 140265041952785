import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { element } from 'protractor';
import { PopoverController, NavParams, Events } from '@ionic/angular';
import * as $ from 'jquery';
import { ServiceForAllService } from '../../service-for-all.service';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
    advanceFilters:any = [];
    className: string ="";
  
    //New 
    // all_fields : any = [];// For showing on top

    tag_handle_keywords : string = '';
    tag_handles : any = [];

    name_keywords : string = '';
    names : any = [];

    country_spinner : string = 'off';
    country_lists : any = [];
    country_keywords : string = '';
    countries  : any = [];

    state_spinner : string = 'off';
    states : any = []; // selected states
    state_keywords : string = '';
    state_lists : any = []; //main Variable

    city_spinner : string = 'off';
    cities : any = [];
    city_keywords : string = '';
    city_lists : any = [];

    zip_keywords : string = '';
    zips : any = [];

    special_categories : any = [];
    special_category_lists : any = '';
    special_category_keywords : string = '';
    constructor(
        public popoverCtrl: PopoverController,
        private navParams: NavParams,
        public events: Events,
        public cd: ChangeDetectorRef,
        public zone: NgZone,
        public serviceForAllService : ServiceForAllService,
        public storage: Storage,
    ) { 
        this.storage.get('countries').then((response) => {

            console.log('Storage->countries: ', response);
            if (response != null) {
                response.forEach((element, index) => {
                    // console.log(index+":", element);
                    response[index].is_searched = false;
                });
                this.country_lists = response;
            }else{
                this.getCountries();
            }
          });

        console.log("this.navParams.data:", this.navParams.data);
        if(this.navParams.data.advanceFilters){
            // this.advanceFilters = JSON.parse(this.navParams.data.advanceFilters);
            this.advanceFilters = this.navParams.data.advanceFilters;
            console.log("PopOver->advanceFilters1: ", this.advanceFilters);
        }else{
            this.advanceFilters = [];
            console.log("PopOver->advanceFilters2: ", this.advanceFilters);
        }
        // this.events.subscribe('addClassCustom', (val) => {
        //     this.className = val;
        //     $('#ekey').val(this.className);
        //     console.log('this.className: ', this.className);
        // });
       
    
  }


  ngOnInit() {}

    add_filters(field_name, value, item){
        value = value.toLowerCase();
        if(!value || value == undefined || value == ''){
            return false;
        }
        let row = {
            key     : field_name,
            value   : value,
        };

        if(field_name == 'countries'){
            row['value']   = item.name;
            row['flag']    = item.emoji;
            row['country_code'] = value;
        }
        if(field_name == 'states'){
            row['value']   = item.name;
            row['state_code'] = value; item.iso2;
        }

        this.advanceFilters.push(row);
        document.querySelectorAll("."+field_name+"").forEach(element=>{ (element as HTMLInputElement).value = ''; })
        
        console.log("advanceFilters:", this.advanceFilters);
    }

    remove_fields(index){
        this.advanceFilters.splice(index, 1);
    }


    searchStart(){
        console.log("filters:", this.advanceFilters);
        this.popoverCtrl.dismiss({ advanceFilters: this.advanceFilters });
    }


    cancel(){
        this.advanceFilters = [];
        this.popoverCtrl.dismiss({ advanceFilters: this.advanceFilters });
    }


    getCountries(){
        let sendData = { }
        this.country_spinner = 'on';
        this.serviceForAllService.get_countries(sendData).subscribe((response) => {
            console.log("response:", response);
            this.country_spinner = 'off';
            if(response['status'] == 'ok'){
                this.countries = response['countries'];
                console.log("countries:", this.countries);

                this.countries.forEach((element, index) => {
                    console.log(index+":", element);
                    this.countries[index].is_searched = false;
                });
                console.log("countries:", this.countries);
            }else{
                this.serviceForAllService.presentToast(response['msg']);
            }
        },(err) => {
            this.country_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);
        
        })
    }

    search_country(){
        console.log("country_keywords:", this.country_keywords);
        this.country_lists.forEach((element, index) => {
            let is_matched = element.name.toLowerCase().includes(this.country_keywords.toLowerCase());
            this.country_lists[index].is_searched = is_matched;  
        });
    }

    get_states(){
        let sendData = {
            state_keywords : this.state_keywords,
        }
        console.log("SendData", sendData);
        this.state_spinner = 'on';
        this.serviceForAllService.get_states(sendData).subscribe((response) => {
            console.log("response:", response);
            this.state_spinner = 'off';
            if(response['status'] == 'ok'){
                this.state_lists = response['states'];
                console.log("states:", this.states);
            }else{
                this.serviceForAllService.presentToast(response['msg']);
            }
        },(err) => {
            this.state_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);
        
        })  
    }


    get_cities(){
        console.log("city_keywords:", this.city_keywords);
        let sendData = {
            city_keywords : this.city_keywords,
        }
        console.log("SendData", sendData);
        this.city_spinner = 'on';
        this.serviceForAllService.get_cities(sendData).subscribe((response) => {
            console.log("response:", response);
            this.city_spinner = 'off';
            if(response['status'] == 'ok'){
                this.city_lists = response['cities'];
                console.log("city_lists:", this.city_lists);
            }else{
                this.serviceForAllService.presentToast(response['msg']);
            }
        },(err) => {
            this.city_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);
        
        })        
    }

    clear_all(){
        this.advanceFilters = [];
    }


}

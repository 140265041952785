import { Component, OnInit,ChangeDetectorRef, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController, MenuController, NavController,ToastController, ActionSheetController, LoadingController, AlertController, Events,Platform } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import * as $ from 'jquery';
import { cordovaWarn } from '@ionic-native/core/decorators/common';
@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.page.html',
  styleUrls: ['./country-list.page.scss'],
})
export class CountryListPage implements OnInit {

    loginInfo : any = [];
    country_list : any = [];
    country_spinner : string = 'off';
    search_box : string = '';
    selected_country : any = [];
    constructor(
        public serviceForAllService: ServiceForAllService, 
        public storage: Storage,
        private camera: Camera,
        public router: Router,
        public toastController: ToastController,
        public events: Events,
        public zone: NgZone,
        public cdr: ChangeDetectorRef,
        public alertController: AlertController,
        public activatedRoute: ActivatedRoute,
        public modalController: ModalController,
    ) {
        this.getCountries();
    }

    ngOnInit() {
    }

    ionViewWillEnter() {
       
        this.storage.get('user').then((user) => {
            if(user){
                this.loginInfo = user;
            }
        });
    }

    getCountries(){
       
        this.storage.get('countries').then((response) => {
            console.log('Storage->countries: ', response);
            if (response != null) {
                this.country_list = response;
                this.country_list.forEach(element => {
                    element['selected'] = false;
                    element['is_match'] = true;
                });
                // console.log("country_list:", this.country_list);
            }else{
                let sendData = {
                    token           : this.loginInfo.token,
                }
                this.country_spinner = 'on';
                this.serviceForAllService.get_countries(sendData).subscribe((response) => {
                    console.log("response:", response);
                    this.country_spinner = 'off';
                    if(response['status'] == 'ok'){
                        this.country_list = response['countries'];
                        this.storage.set('countries', this.country_list);
                        
                        this.country_list.forEach(element => {
                            element['selected'] = false;
                            element['is_match'] = true;
                        });
                        console.log("country_list:", this.country_list);
                    }else{
                        this.serviceForAllService.presentToast(response['msg']);
                    }
                },(err) => {
                    this.country_spinner = 'off';
                    console.log("err:", err);
                    this.serviceForAllService.presentAlert(err.error.msg);
                
                })
            }
        });
    }

    select_now(index){
        console.log("search_box:", this.search_box);
        let row = this.country_list[index];
        console.log("Row:", row);

        this.country_list.forEach(element => {
            element['selected'] = false;
        });
        this.country_list[index].selected = true;
        
        this.selected_country = row;
    }

    filter_countries(){
        // console.log("search_box1:", this.search_box);
        this.search_box = this.search_box.toLowerCase();

        this.country_list.forEach(element => {
            let name = element['name'].toLowerCase();
            let iso2 = element['iso2'].toLowerCase();
            let iso3 = element['iso3'].toLowerCase();
            let phonecode = element['phonecode'].toLowerCase(); //phonecode

            if(name.search(this.search_box) != -1){
                element['is_match'] = true;
               
            }else if(iso2.search(this.search_box) != -1){
                element['is_match'] = true;
            }
            else if(iso3.search(this.search_box) != -1){
                element['is_match'] = true;
            }
            else if(phonecode.search(this.search_box) != -1){
                element['is_match'] = true;
            }
            else{
                element['is_match'] = false;
            }
            console.log("Element-- ::", element);
        });
    }

    send_country_data(){
        if(this.selected_country.id){
            this.modalController.dismiss({
                'dismissed'         : true,
                'selected_country'  : this.selected_country,
            });
        }else{
            this.serviceForAllService.presentToast("Please choose a country.");
            return false;
        }
    }

    closeModel(){
        this.modalController.dismiss({'dismissed' : true});
    }

    checkEmpty(){
        let empty : boolean = true;
        this.country_list.forEach(element => {
            if (element.is_match == true){
                empty = false;
            }
        })
        return empty;
    }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { now } from '../../../../../node_modules/moment';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { ServiceForAllService } from '../../../service-for-all.service';
import { Storage } from '@ionic/storage';
import { PopoverController } from '@ionic/angular';

import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-story-viewers',
  templateUrl: './story-viewers.page.html',
  styleUrls: ['./story-viewers.page.scss'],
})
export class StoryViewersPage implements OnInit {
    viewers : any = [];
    story_id : any = '';
    storyInfo : any = [];
    is_downloading : string = 'off';
    loginInfo : any = [];
    is_deleting : string = 'off';
    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        private file: File,
        private transfer: FileTransfer,
        private photoLibrary: PhotoLibrary,
        public serviceForAllService : ServiceForAllService,
        public storage : Storage,
        public router : Router,
        public popoverController : PopoverController,
    ) {

    }

    ngOnInit() {
        console.log(this.navParams);

        this.loginInfo = this.navParams.data.loginInfo;
        console.log("loginInfo:", this.loginInfo);

        this.viewers = this.navParams.data.viewers;
        console.log("viewers:",this.viewers);
        
        this.story_id = this.navParams.data.story_id;
        console.log("storyID:", this.story_id);

        this.storyInfo = this.navParams.data.storyInfo;
        console.log("storyInfo:", this.storyInfo);
    }

    async closeModal() {
        const onClosedData: string = "Wrapped Up!";
        
        await this.popoverController.dismiss(onClosedData);
        // await this.modalController.dismiss(onClosedData);
        // await this.

    }

    download_media(){
        if(this.storyInfo.attachment_type == 'image' || this.storyInfo.attachment_type == 'video'){
            this.is_downloading = 'on';
            let filename = this.storyInfo.attachment_url.split('/').pop().split('#')[0].split('?')[0];
            if(filename==''){
                filename = now();
            }
            const fileTransfer: FileTransferObject = this.transfer.create();
            fileTransfer.download(this.storyInfo.attachment_url, this.file.dataDirectory + filename).then((entry) => {
                
                console.log("entry:", entry);
                let local_path = entry.toURL();
                this.is_downloading = 'off';
                this.saveMedia(local_path, this.storyInfo.attachment_type);
            }, (err) => { 
                console.log("err:", err);
            });
        }
    }


    saveMedia(local_path, media_type){
        console.log("saveMedia->local_path:", local_path);
        const options = {};
        this.photoLibrary.requestAuthorization().then(() => {
            this.is_downloading = 'off';

            if(media_type == 'video'){
                this.photoLibrary.saveVideo(local_path, "My Albums");
            }
            else{
                this.photoLibrary.saveImage(local_path, "My Albums", options);
            }
        }).catch((err) => {
            console.log("err:", err);
            console.log('Permissions weren\'t granted');
        });
    }

    delete_story(){
        let sendData = {
            token : this.loginInfo.token,
            story_id : this.storyInfo.story_id,
        }
        console.log("sendData", sendData);
        this.is_deleting = 'on';
       
        this.serviceForAllService.delete_story(sendData).subscribe(response => {
            console.log("response:", response);
            this.is_deleting = 'off';
            if(response['status'] == 'ok'){
                this.serviceForAllService.presentToast(response['msg']);
                this.closeModal();
            }else{
                this.serviceForAllService.presentToast(response['msg']);
            }
        },(err) => {
            console.log("err:", err);
            this.is_deleting = 'off';
            this.serviceForAllService.presentAlert(err.error.msg);
            if (err.error_code == "token_expired") {
                // this.closeModal();
                this.storage.clear();
                this.router.navigate(["/login"]);
            }
        });
    }
}

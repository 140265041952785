import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from '../../service-for-all.service';
import { PopoverController, ModalController, AlertController, ActionSheetController } from '@ionic/angular';

@Component({
  selector: 'app-social-profile-menu',
  templateUrl: './social-profile-menu.page.html',
  styleUrls: ['./social-profile-menu.page.scss'],
})
export class SocialProfileMenuPage implements OnInit {
    loginInfo: any = [];
    login_user_id: any;
    user_id: any = '';
    userInfo: any = [];
    do_action: any = '';
    is_highlights_loading: string = 'on';
    highlights: any = [];
    feeds: any = [];
    is_feed_loading: string = 'on';
    slideOpts: any = {
        initialSlide: 0,
        speed: 1000
    };
    menu_status: any = 'hidden';
    total_followers: any = 0;
    total_followings: any = 0;
    public headerHexColor = "#51688F";
    constructor(
        private route: ActivatedRoute,
        public storage: Storage,
        public serviceForAllService: ServiceForAllService,
        public ngZone: NgZone,
        public changeDetectorRef: ChangeDetectorRef,
        public actionSheetController: ActionSheetController,
        public router: Router,
        public alertController: AlertController,
        public modalController: ModalController,
        public popoverController: PopoverController,
    ) {
        console.log("loginInfo1:", this.loginInfo);
    }

    ngOnInit() {
        console.log("loginInfo2:", this.loginInfo);

    }

    goto_chating() {
        this.closeModal();
        this.router.navigate(['messages', {
            receverid: this.userInfo.user_id,
            receverName: this.userInfo.first_name + ' ' + this.userInfo.last_name,
            receverImage: this.userInfo.user_avatar,
            receverDTag: this.userInfo.default_tag,
            receverLang: this.userInfo.default_language,
            chatType: 'social_chat'
        }]);
    }

    async closeModal() {
        await this.popoverController.dismiss({

        });
    }

    send_follow_request(item) {
        let sendData = {
            token: this.loginInfo.token,
            second_user_id: item.user_id,
            status: 'pending',
        };
        this.serviceForAllService.showLoader();
        this.serviceForAllService.sendData('send_follow_request', sendData).subscribe(response => {
            console.log("response:", response);
            this.serviceForAllService.dismissLoading();
            if (response['status'] == 'ok') {
                this.userInfo.is_following = response['is_following'];
            } else {
                this.serviceForAllService.presentToast(response['msg']);
            }
            this.closeModal();
        }, (err) => {
            console.log("err:", err);
            this.serviceForAllService.dismissLoading();
            this.serviceForAllService.presentAlert(err.error.msg);
            if (err.error_code == "token_expired") {
                this.storage.clear();
                this.router.navigate(["/login"]);
            }
        });
    }
    
    async cancel_unfollow(item, status) {
        const alert = await this.alertController.create({
            header: status.charAt(0).toUpperCase() + status.slice(1),
            // action_type.charAt(0).toUpperCase()+action_type.slice(1),
            // subHeader: 'Subtitle',
            cssClass: 'alertCancel',
            message: '<img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
            buttons: [{
                text: '',
                handler: () => {
                    this.ngZone.run(() => {
                        let sendData = {
                            token: this.loginInfo.token,
                            second_user_id: item.user_id,
                            status: status,
                        };
                        this.serviceForAllService.showLoader();
                        this.serviceForAllService.sendData('cancel_unfollow', sendData).subscribe(response => {
                            console.log("response:", response);
                            this.serviceForAllService.dismissLoading();
                            if (response['status'] == 'ok') {
                                this.closeModal();
                                this.userInfo.is_following = response['is_following'];
                            } else {
                                this.serviceForAllService.presentToast(response['msg']);
                            }
                        }, (err) => {
                            console.log("err:", err);
                            this.serviceForAllService.dismissLoading();
                            this.serviceForAllService.presentAlert(err.error.msg);
                            if (err.error_code == "token_expired") {
                                this.storage.clear();
                                this.router.navigate(["/login"]);
                            }
                        });
                    });
                }
            },
            {
                text: '',
                role: 'cancel',
                handler: () => {

                }
            }],
        });
        await alert.present();
    }

    async do_block_unblock(action_type) {
        // action_type means -- block/unblock

        const alert = await this.alertController.create({
            header: action_type.charAt(0).toUpperCase() + action_type.slice(1),
            // subHeader: 'Subtitle',
            cssClass: 'alertCancel',
            message: ' <img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
            buttons: [{
                text: '',
                handler: () => {
                    this.ngZone.run(() => {
                        let sendData = {
                            token: this.loginInfo.token,
                            block_user_id: this.user_id,
                            action_type: action_type
                        }
                        this.serviceForAllService.showLoader();
                        this.serviceForAllService.do_block_unblock(sendData).subscribe((response) => {
                            this.serviceForAllService.dismissLoading();
                            console.log("response:", response);
                            if (response['status'] == 'ok') {
                                this.closeModal();
                                this.userInfo.is_blocked = response['is_blocked'];
                            }
                            this.serviceForAllService.presentToast(response['msg']);
                        }, (err) => {
                            this.serviceForAllService.dismissLoading();
                            console.log("err:", err);
                            this.serviceForAllService.presentToast(err.error.msg);
                            if (err.error_code == "token_expired") {
                                this.storage.clear();
                                this.router.navigate(["/login"]);
                            }
                        });
                    });
                }
            },
            {
                text: '',
                role: 'cancel',
                handler: () => {

                }
            }],
        });
        await alert.present();
    }

    goto_page(page_slug) {
        this.closeModal();
        this.router.navigate([page_slug]);
    }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
const routes: Routes = [
  //   { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)},
  { path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule) },
  { path: 'register', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'forgot', loadChildren: './forgot/forgot.module#ForgotPageModule' },
  { path: 'billing', loadChildren: './billing/billing.module#BillingPageModule' },
  { path: 'billing/:type', loadChildren: './billing/billing.module#BillingPageModule' },
  { path: 'editmyaccount', loadChildren: './editmyaccount/editmyaccount.module#EditmyaccountPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'home/:user_id', loadChildren: './home/home.module#HomePageModule' },
  { path: 'managebankaccounts', loadChildren: './managebankaccounts/managebankaccounts.module#ManagebankaccountsPageModule' },
  { path: 'bankinginformation', loadChildren: './bankinginformation/bankinginformation.module#BankinginformationPageModule' },
  { path: 'changepassword', loadChildren: './changepassword/changepassword.module#ChangepasswordPageModule' },
  { path: 'home2', loadChildren: './home2/home2.module#Home2PageModule' },
  //   { path: 'choosetype', loadChildren: './choosetype/choosetype.module#ChoosetypePageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'mytransaction', loadChildren: './mytransaction/mytransaction.module#MytransactionPageModule' },
  { path: 'pay', loadChildren: './pay/pay.module#PayPageModule' },
  {
    path: 'pay/:user_id/:type/:amount/:username/:extra_data',
    loadChildren: () =>
      import('./pay/pay.module').then(m => m.PayPageModule)
  },
  { path: 'thankyou', loadChildren: './thankyou/thankyou.module#ThankyouPageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'affiliate', loadChildren: './affiliate/affiliate.module#AffiliatePageModule' },
  { path: 'chooserequest', loadChildren: './chooserequest/chooserequest.module#ChooserequestPageModule' },
  { path: 'togged-history', loadChildren: './togged-history/togged-history.module#ToggedHistoryPageModule' },
  { path: 'setting', loadChildren: './setting/setting.module#SettingPageModule' },
  { path: 'tag-user', loadChildren: './tag-user/tag-user.module#TagUserPageModule' },

  // All Tab URL
  {
    path: 'tabs/dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'tabs/dashboard/chooserequest',
    loadChildren: () =>
      import('./chooserequest/chooserequest.module').then(m => m.ChooserequestPageModule)
  },
  {
    path: 'tabs/dashboard/users/:user_id',
    loadChildren: () =>
      import('./home2/home2.module').then(m => m.Home2PageModule)
  },
  {
    path: 'tabs/dashboard/users/:user_id',
    loadChildren: () =>
      import('./home2/home2.module').then(m => m.Home2PageModule)
  },

  {
    path: 'tabs/dashboard/users',
    loadChildren: () =>
      import('./home2/home2.module').then(m => m.Home2PageModule)
  },
  {
    path: 'tabs/dashboard/users/action/:type',
    loadChildren: () =>
      import('./home2/home2.module').then(m => m.Home2PageModule)
  },
  {
    path: 'tabs/dashboard/users/:user_id/:type/:username',
    loadChildren: () =>
      import('./requestfunds/requestfunds.module').then(m => m.RequestfundsPageModule)
  },
  {
    path: 'tabs/dashboard/mytransaction',
    loadChildren: () =>
      import('./mytransaction/mytransaction.module').then(m => m.MytransactionPageModule)
  }
  ,
  {
    path: 'tabs/dashboard/pay/:user_id/:type/:amount/:username',
    loadChildren: () =>
      import('./pay/pay.module').then(m => m.PayPageModule)
  },
  {
    path: 'tabs/dashboard/pay/:user_id/:type/:amount/:username/:request_id',
    loadChildren: () =>
      import('./pay/pay.module').then(m => m.PayPageModule)
  },
  {
    path: 'tabs/requestfunds',
    loadChildren: () =>
      import('./requestfunds/requestfunds.module').then(m => m.RequestfundsPageModule)
  },
  {
    path: 'myaccount',
    loadChildren: () =>
      import('./myaccount/myaccount.module').then(m => m.MyaccountPageModule)
  },
  { path: 'aboutus', loadChildren: './aboutus/aboutus.module#AboutusPageModule' },
  { path: 'previouslysendrequest', loadChildren: './previouslysendrequest/previouslysendrequest.module#PreviouslysendrequestPageModule' },
  { path: 'sendreceipt', loadChildren: './sendreceipt/sendreceipt.module#SendreceiptPageModule' },
  { path: 'receivedrequests', loadChildren: './receivedrequests/receivedrequests.module#ReceivedrequestsPageModule' },
  { path: 'receivedrequestsingle/:request_id/:type', loadChildren: './receivedrequestsingle/receivedrequestsingle.module#ReceivedrequestsinglePageModule' },
  { path: 'messages', loadChildren: './messages/messages.module#MessagesPageModule' },
  { path: 'messages/:activetab', loadChildren: './messages/messages.module#MessagesPageModule' },

  { path: 'receipts', loadChildren: './receipts/receipts.module#ReceiptsPageModule' },

  { path: 'generatereceipt', loadChildren: './generatereceipt/generatereceipt.module#GeneratereceiptPageModule' },
  { path: 'scanreceipt', loadChildren: './scanreceipt/scanreceipt.module#ScanreceiptPageModule' },
  { path: 'generaterecipt2', loadChildren: './generaterecipt2/generaterecipt2.module#Generaterecipt2PageModule' },
  { path: 'viewallreceipts', loadChildren: './viewallreceipts/viewallreceipts.module#ViewallreceiptsPageModule' },
  { path: 'viewallreceipts/:view_type', loadChildren: './viewallreceipts/viewallreceipts.module#ViewallreceiptsPageModule' },
  { path: 'reciptaddusers', loadChildren: './reciptaddusers/reciptaddusers.module#ReciptaddusersPageModule' },
  { path: 'payevent', loadChildren: './payevent/payevent.module#PayeventPageModule' },
  { path: 'scanuserlist', loadChildren: './scanuserlist/scanuserlist.module#ScanuserlistPageModule' },
  { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
  { path: 'cardinfo/:card_id', loadChildren: './cardinfo/cardinfo.module#CardinfoPageModule' },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule' },
  { path: 'mytransactiondetails/:id/:type', loadChildren: './mytransactiondetails/mytransactiondetails.module#MytransactiondetailsPageModule' },
  { path: 'allusers', loadChildren: './allusers/allusers.module#AllusersPageModule' },
  { path: 'managekyc', loadChildren: './managekyc/managekyc.module#ManagekycPageModule' },

  //   { path: 'landing', loadChildren: './landing/landing.module#LandingPageModule'},
  { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule), canLoad: [AuthGuard] },

  { path: 'touch-login', loadChildren: './touch-login/touch-login.module#TouchLoginPageModule' },
  { path: 'verify-email', loadChildren: './verify-email/verify-email.module#VerifyEmailPageModule' },
  { path: 'createtag', loadChildren: './createtag/createtag.module#CreatetagPageModule' },
  { path: 'tags', loadChildren: './tags/tags.module#TagsPageModule' },
  { path: 'news-feed', loadChildren: './news-feed/news-feed.module#NewsFeedPageModule' },
  { path: 'camera', loadChildren: './camera/camera.module#CameraPageModule' },
  //   { path: 'addpost', loadChildren: () => import('./addpost/addpost.module').then( m => m.AddpostPageModule) },
  {
    path: 'actions',
    loadChildren: () => import('./actions/actions.module').then(m => m.ActionsPageModule)
  },
  {
    path: 'followers',
    loadChildren: () => import('./followers/followers.module').then(m => m.FollowersPageModule), canLoad: [AuthGuard]
  },
  {
    path: 'following',
    loadChildren: () => import('./following/following.module').then(m => m.FollowingPageModule)
  },
  {
    path: 'report-as-abuse',
    loadChildren: () => import('./report-as-abuse/report-as-abuse.module').then(m => m.ReportAsAbusePageModule)
  },
  {
    path: 'video-popup',
    loadChildren: () => import('./video-popup/video-popup.module').then(m => m.VideoPopupPageModule)
  },
  { path: 'sendfeedback', loadChildren: './sendfeedback/sendfeedback.module#SendfeedbackPageModule' },
  { path: 'touch-setting', loadChildren: './touch-setting/touch-setting.module#TouchSettingPageModule' },
  {
    path: 'friend-request',
    loadChildren: () => import('./friend-request/friend-request.module').then(m => m.FriendRequestPageModule)
  },
  { path: 'my-friends', loadChildren: './my-friends/my-friends.module#MyFriendsPageModule' },
  { path: 'gallery-custom-modal', loadChildren: './gallery-custom-modal/gallery-custom-modal.module#GalleryCustomModalPageModule' },
  { path: 'pending-received-tag', loadChildren: './pending-received-tag/pending-received-tag.module#PendingReceivedTagPageModule' },
  { path: 'pending-received-tag/:rid', loadChildren: './pending-received-tag/pending-received-tag.module#PendingReceivedTagPageModule' },
  { path: 'tagmenus', loadChildren: './tagmenus/tagmenus.module#TagmenusPageModule' },
  { path: 'sent-tags', loadChildren: './sent-tags/sent-tags.module#SentTagsPageModule' },
  { path: 'custom-splash', loadChildren: './custom-splash/custom-splash.module#CustomSplashPageModule' },
  { path: 'accepted-received-tag', loadChildren: './accepted-received-tag/accepted-received-tag.module#AcceptedReceivedTagPageModule' },
  { path: 'accepted-received-tag/:rid/:title/:type', loadChildren: './accepted-received-tag/accepted-received-tag.module#AcceptedReceivedTagPageModule' },
  { path: 'received-tags', loadChildren: './received-tags/received-tags.module#ReceivedTagsPageModule' },
  { path: 'drawer', loadChildren: './drawer/drawer.module#DrawerPageModule' },
  { path: 'faceid', loadChildren: './faceid/faceid.module#FaceidPageModule' },
  { path: 'welldone', loadChildren: './welldone/welldone.module#WelldonePageModule' },
  { path: 'suggested', loadChildren: './suggested/suggested.module#SuggestedPageModule' },
  { path: 'verify-phone', loadChildren: './verify-phone/verify-phone.module#VerifyPhonePageModule' },
  { path: 'reactions', loadChildren: './reactions/reactions.module#ReactionsPageModule' },
  { path: 'financial-settings', loadChildren: './financial-settings/financial-settings.module#FinancialSettingsPageModule' },
  { path: 'add-banks-cards', loadChildren: './add-banks-cards/add-banks-cards.module#AddBanksCardsPageModule' },
  { path: 'select-users', loadChildren: './select-users/select-users.module#SelectUsersPageModule' },
  { path: 'check-pass', loadChildren: './check-pass/check-pass.module#CheckPassPageModule' },
  { path: 'verify-code', loadChildren: './verify-code/verify-code.module#VerifyCodePageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule' },
  { path: 'store-request', loadChildren: './store-request/store-request.module#StoreRequestPageModule' },
  { path: 'authpopup', loadChildren: './authpopup/authpopup.module#AuthpopupPageModule' },
  { path: 'twofactorauth', loadChildren: './twofactorauth/twofactorauth.module#TwofactorauthPageModule' },

  //[START]=> Shoping 
  { path: 'shop', loadChildren: './shopping/pages/shop/shop.module#ShopPageModule' },
  { path: 'product-list', loadChildren: './shopping/pages/product-list/product-list.module#ProductListPageModule' },
  { path: 'product-details', loadChildren: './shopping/pages/product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'product-detail/:pid', loadChildren: './shopping/pages/product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'cart', loadChildren: './shopping/pages/cart/cart.module#CartPageModule' },
  { path: 'checkout', loadChildren: './shopping/pages/checkout/checkout.module#CheckoutPageModule' },
  { path: 'new-address', loadChildren: './shopping/pages/new-address/new-address.module#NewAddressPageModule' },
  { path: 'new-address/:id', loadChildren: './shopping/pages/new-address/new-address.module#NewAddressPageModule' },
  { path: 'favorites', loadChildren: './shopping/pages/favorites/favorites.module#FavoritesPageModule' },
  { path: 'orders', loadChildren: './shopping/pages/orders/orders.module#OrdersPageModule' },
  { path: 'order-info', loadChildren: './shopping/pages/order-info/order-info.module#OrderInfoPageModule' },
  //[END]=> Shoping 

  //[START]=> Social Media
  { path: 'social-user-profile/:user_id', loadChildren: './social-media/pages/social-user-profile/social-user-profile.module#SocialUserProfilePageModule' },
  { path: 'social-media-dashboard', loadChildren: './social-media/pages/social-media-dashboard/social-media-dashboard.module#SocialMediaDashboardPageModule' },
  { path: 'social-media-dashboard/:slug', loadChildren: './social-media/pages/social-media-dashboard/social-media-dashboard.module#SocialMediaDashboardPageModule' },
  { path: 'social-add-highlights', loadChildren: './social-media/pages/social-add-highlights/social-add-highlights.module#SocialAddHighlightsPageModule' },
  { path: 'social-cover-highlights', loadChildren: './social-media/pages/social-cover-highlights/social-cover-highlights.module#SocialCoverHighlightsPageModule' },
  { path: 'follow-lists/:user_id', loadChildren: './social-media/pages/follow-lists/follow-lists.module#FollowListsPageModule' },
  { path: 'add-new-story', loadChildren: './social-media/pages/add-new-story/add-new-story.module#AddNewStoryPageModule' },
  { path: 'add-new-post', loadChildren: './social-media/pages/add-new-post/add-new-post.module#AddNewPostPageModule' },
  { path: 'edit-post/:post_id', loadChildren: './social-media/pages/edit-post/edit-post.module#EditPostPageModule' },
  //   { path: 'feed-details', loadChildren: './social-media/pages/feed-details/feed-details.module#FeedDetailsPageModule' },
  { path: 'feed-details/:post_id', loadChildren: './social-media/pages/feed-details/feed-details.module#FeedDetailsPageModule' },
  { path: 'story-viewers', loadChildren: './social-media/pages/story-viewers/story-viewers.module#StoryViewersPageModule' },
  { path: 'view-stories', loadChildren: './social-media/pages/view-stories/view-stories.module#ViewStoriesPageModule' },
  { path: 'editcomment', loadChildren: () => import('./social-media/pages/editcomment/editcomment.module').then(m => m.EditcommentPageModule) },
  { path: 'replycomment', loadChildren: () => import('./social-media/pages/replycomment/replycomment.module').then(m => m.ReplycommentPageModule) },
  //[END]=> Social Media

  { path: 'feeds', loadChildren: './feeds/feeds.module#FeedsPageModule' },
  { path: 'new-message', loadChildren: './new-message/new-message.module#NewMessagePageModule' },
  { path: 'new-message/:activetab', loadChildren: './new-message/new-message.module#NewMessagePageModule' },
  { path: 'search-listing', loadChildren: './search-listing/search-listing.module#SearchListingPageModule' },
  { path: 'select-user', loadChildren: './select-user/select-user.module#SelectUserPageModule' },
  { path: 'video-call', loadChildren: './video-call/video-call.module#VideoCallPageModule' },
  { path: 'kindness', loadChildren: './kindness/kindness.module#KindnessPageModule' },
  { path: 'tagged-history', loadChildren: './tagged-history/tagged-history.module#TaggedHistoryPageModule' },
  { path: 'feedback', loadChildren: './feedback/feedback.module#FeedbackPageModule' },
  { path: 'block-user-list', loadChildren: './block-user-list/block-user-list.module#BlockUserListPageModule' },
  { path: 'your-geo', loadChildren: './your-geo/your-geo.module#YourGeoPageModule' },
  { path: 'grapevine-model', loadChildren: './grapevine-model/grapevine-model.module#GrapevineModelPageModule' },
  { path: 'story-archive', loadChildren: './social-media/pages/story-archive/story-archive.module#StoryArchivePageModule' },
  { path: 'social-search', loadChildren: './social-media/pages/social-search/social-search.module#SocialSearchPageModule' },
  { path: 'activity', loadChildren: './social-media/pages/activity/activity.module#ActivityPageModule' },
  //   { path: 'social-notifications', loadChildren: './social-media/pages/social-notifications/social-notifications.module#SocialNotificationsPageModule' },
  { path: 'authpopupemail', loadChildren: './authpopupemail/authpopupemail.module#AuthpopupemailPageModule' },
  { path: 'other-account', loadChildren: './other-account/other-account.module#OtherAccountPageModule' },
  { path: 'other-account/:user_id', loadChildren: './other-account/other-account.module#OtherAccountPageModule' },
  { path: 'grapevine-map', loadChildren: './grapevine-map/grapevine-map.module#GrapevineMapPageModule' },
  { path: 'language', loadChildren: './language/language.module#LanguagePageModule' },
  { path: 'language-request', loadChildren: './language-request/language-request.module#LanguageRequestPageModule' },
  { path: 'mute-notification', loadChildren: './social-media/pages/mute-notification/mute-notification.module#MuteNotificationPageModule' },

  { path: 'gopremium', loadChildren: './subscription/pages/gopremium/gopremium.module#GopremiumPageModule' },
  { path: 'plans', loadChildren: './subscription/pages/plans/plans.module#PlansPageModule' },
  { path: 'choose-a-plan', loadChildren: './subscription/pages/choose-a-plan/choose-a-plan.module#ChooseAPlanPageModule' },
  { path: 'p-verified', loadChildren: './subscription/pages/p-verified/p-verified.module#PVerifiedPageModule' },
  { path: 'user-verify-front', loadChildren: './subscription/pages/user-verify-front/user-verify-front.module#UserVerifyFrontPageModule' },
  { path: 'select-payment-method', loadChildren: './subscription/pages/select-payment-method/select-payment-method.module#SelectPaymentMethodPageModule' },
  { path: 'suggested-users', loadChildren: './suggested-users/suggested-users.module#SuggestedUsersPageModule' },
  { path: 'page-informations/', loadChildren: './page-informations/page-informations.module#PageInformationsPageModule' },
  { path: 'page-informations/:page_name', loadChildren: './page-informations/page-informations.module#PageInformationsPageModule' },
  { path: 'subscription-informations', loadChildren: './subscription/pages/subscription-informations/subscription-informations.module#SubscriptionInformationsPageModule' },
  { path: 'select-location', loadChildren: './social-media/pages/select-location/select-location.module#SelectLocationPageModule' },
  { path: 'welcome-premium/:plantype', loadChildren: './subscription/pages/welcome-premium/welcome-premium.module#WelcomePremiumPageModule' },
  { path: 'setup-notification', loadChildren: './setup-notification/setup-notification.module#SetupNotificationPageModule' },
  { path: 'social-profile-links', loadChildren: './social-media/pages/social-profile-links/social-profile-links.module#SocialProfileLinksPageModule' },
  { path: 'confirm-account', loadChildren: './confirm-account/confirm-account.module#ConfirmAccountPageModule' },
  { path: 'vip-request', loadChildren: './vip-request/vip-request.module#VipRequestPageModule' },
  { path: 'manage-wishlists', loadChildren: './manage-wishlists/manage-wishlists.module#ManageWishlistsPageModule' },
  { path: 'update-cards', loadChildren: './update-cards/update-cards.module#UpdateCardsPageModule' },
  { path: 'wallet', loadChildren: './wallet/wallet.module#WalletPageModule' },
  { path: 'walletrequest', loadChildren: './walletrequest/walletrequest.module#WalletrequestPageModule' },
  { path: 'edit-card', loadChildren: './edit-card/edit-card.module#EditCardPageModule' },
  { path: 'privacy-rights', loadChildren: './privacy-rights/privacy-rights.module#PrivacyRightsPageModule' },
  { path: 'signin-signup', loadChildren: './signin-signup/signin-signup.module#SigninSignupPageModule' },
  { path: 'feedbackmodal', loadChildren: './feedbackmodal/feedbackmodal.module#FeedbackmodalPageModule' },
  { path: 'referfriendmodal', loadChildren: './referfriendmodal/referfriendmodal.module#ReferfriendmodalPageModule' },
  { path: 'productmodal', loadChildren: './productmodal/productmodal.module#ProductmodalPageModule' },
  { path: 'grapevine', loadChildren: './grapevine/grapevine.module#GrapevinePageModule' },
  { path: 'feedsmodal', loadChildren: './feedsmodal/feedsmodal.module#FeedsmodalPageModule' },
  { path: 'profilegallerymodal', loadChildren: './profilegallerymodal/profilegallerymodal.module#ProfilegallerymodalPageModule' },
  { path: 'profile-settings', loadChildren: './profile-settings/profile-settings.module#ProfileSettingsPageModule' },
  { path: 'company-settings', loadChildren: './company-settings/company-settings.module#CompanySettingsPageModule' },
  { path: 'gopremium-modal', loadChildren: './gopremium-modal/gopremium-modal.module#GopremiumModalPageModule' },
  { path: 'selecttypeid', loadChildren: './selecttypeid/selecttypeid.module#SelecttypeidPageModule' },
  { path: 'frontofcard', loadChildren: './frontofcard/frontofcard.module#FrontofcardPageModule' },
  { path: 'contactus-modal', loadChildren: './contactus-modal/contactus-modal.module#ContactusModalPageModule' },
  { path: 'country-list', loadChildren: './country-list/country-list.module#CountryListPageModule' },
  { path: 'capture-photo', loadChildren: './capture-photo/capture-photo.module#CapturePhotoPageModule' },
  { path: 'social-profile-setting', loadChildren: './social-media/pages/social-profile-setting/social-profile-setting.module#SocialProfileSettingPageModule' },
  { path: 'friend-list', loadChildren: './social-media/pages/friend-list/friend-list.module#FriendListPageModule' },
  { path: 'social-story-settings/:user_id', loadChildren: './social-media/pages/social-story-settings/social-story-settings.module#SocialStorySettingsPageModule' },
  { path: 'social-profile-menu', loadChildren: './custom-popover/social-profile-menu/social-profile-menu.module#SocialProfileMenuPageModule' },
  { path: 'create-grapevine', loadChildren: './create-grapevine/create-grapevine.module#CreateGrapevinePageModule' },
  { path: 'add-hide-story', loadChildren: './add-hide-story/add-hide-story.module#AddHideStoryPageModule' },
  { path: 'chat', loadChildren: './chat/chat.module#ChatPageModule' },





];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewChild, ViewChildren, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {  IonInput, ModalController, NavParams, ActionSheetController } from '@ionic/angular';
import { ToastController, MenuController, Events, NavController } from '../../../../../node_modules/@ionic/angular';
import { ServiceForAllService } from '../../../service-for-all.service';
import { Storage } from '@ionic/storage';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';

@Component({
  selector: 'app-user-verify-front',
  templateUrl: './user-verify-front.page.html',
  styleUrls: ['./user-verify-front.page.scss'],
})
export class UserVerifyFrontPage implements OnInit {
  image_type: any = '';
  imageURI: any;
  loginInfo : any = [];

  image_attachment: any = [];
  image_attachment_id : any = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public serviceForAllService: ServiceForAllService,
    public toastController: ToastController,
    public storage: Storage, 
    public menu: MenuController,
    public events: Events,
    public navCtrl: NavController,
    public zone: NgZone,
    public modalCtrl: ModalController,
    private navParams: NavParams,
    private actionSheetController: ActionSheetController,
    private camera: Camera,
    private file: File,
    private transfer: FileTransfer,
  ) {

    this.image_type = this.navParams.get('image_type');

    console.log("image_type:: ", this.image_type);

    this.storage.get('user').then(user => {
        console.log("loginInfo:", this.loginInfo);
        if(user && user!=null){
            this.loginInfo = user;
        }
    })

   }

  ngOnInit() {
  }

  async upload_post_media() {
    let buttons = [];
    buttons.push({
        text: 'Image from Library',
        handler: () => {
            this.getImage(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
    });
    buttons.push({
        text: 'Capture Image',
        handler: () => {
            this.getImage(this.camera.PictureSourceType.CAMERA);
        }
    });

    const actionSheet = await this.actionSheetController.create({
        header: "Select Media source",
        buttons: buttons
    });
    await actionSheet.present();
  }

  getImage(sourceType) {

        const options: CameraOptions = {
            quality: 40,
            destinationType: this.camera.DestinationType.DATA_URL,
            sourceType: sourceType,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            correctOrientation: true,
        }
        this.camera.getPicture(options).then((imageData) => {
            this.imageURI = imageData;
            let base64Image = 'data:image/jpeg;base64,' + this.imageURI;
            console.log("base64Image:", base64Image);
            this.upload_image_on_server(base64Image);
        }, (err) => {
            console.log(err);
        });
    }

    upload_image_on_server(base64Image) {
        const fileTransfer: FileTransferObject = this.transfer.create();
        let header: Headers = new Headers();
        header.append('Authorization', 'Bearer ' + this.loginInfo.token);
        let options: FileUploadOptions = {
            fileKey: 'file',
            fileName: this.loginInfo.user_id+"_"+Date.now()+".jpg",
            chunkedMode: false,
            mimeType: "image/jpeg",
            params: { 'post_type': 'image_verification', 'user_id': this.loginInfo.user_id },
            headers: { 'Authorization': 'Bearer ' + this.loginInfo.token }
        }
        let url = 'https://app.tagster.com/wp-json/wp/v2/media?token=' + this.loginInfo.token;
        console.log("base64Image:", base64Image);
        console.log("url:", url);
        console.log("options:",options);

        this.serviceForAllService.showLoader("Uploading image..");

        fileTransfer.upload(base64Image, url, options).then((data) => {
            console.log("data:", data);
            this.serviceForAllService.dismissLoading();
            let response : any = JSON.parse(data.response);
            console.log("response:", response);
            
            this.image_attachment_id = response.id;
            this.image_attachment = response;

            console.log("image_attachment:", this.image_attachment);
        },(err) => {
            this.serviceForAllService.dismissLoading();
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);
        });
    
        fileTransfer.onProgress((progressEvent) => {
            if (progressEvent.lengthComputable){
                var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100); 
                console.log("P: ", perc);
                //$('.loading-content').html(perc+'% uploaded...');
            }
        });
    }

    removeImage(){
        this.image_attachment_id = "";
        this.image_attachment = [];
    }

    done(){
        this.modalCtrl.dismiss({
            image_attachment_id: this.image_attachment_id,
            image_attachment_url:this.image_attachment.source_url,
            image_type : this.image_type
        });
    }

}

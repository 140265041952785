import { Component, OnInit, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController, MenuController, NavController, LoadingController, AlertController, Events, ToastController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { PasswordValidator } from '../validators/password.validator';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-authpopupemail',
  templateUrl: './authpopupemail.page.html',
  styleUrls: ['./authpopupemail.page.scss'],
})
export class AuthpopupemailPage implements OnInit {
    email_address:any = '';
    twofactdata:any = [];
    isDataLoaded:any = false;

  constructor(
    public events: Events,
    public navCtrl: NavController,
    public storage: Storage,
    public router: Router,
    public serviceForAllService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public zone: NgZone,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.GetTwoFactorData();
  }

  GetTwoFactorData(){
    this.isDataLoaded = false;

    this.storage.get('user').then((val) => {
        if (val != null) {
            this.serviceForAllService.GetTwoFactorData(val.token)
            .subscribe(res => {
              let r:any = [];
              r = res;
              this.twofactdata = r.twofactdata;
              this.isDataLoaded = true;
            },(err)=>{
                this.isDataLoaded = true;
            })
        }else{ this.isDataLoaded = true; }
    },(err)=>{
        this.isDataLoaded = true;
    });
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }


    async removeEmail() {
        this.presentToast("You can not remove the email. You can update it from your profie page.");
        // const alert = await this.alertCtrl.create({
        //     header: 'Confirmation',
        //     message: 'Are you sure you want to remove the phone number?',
        //     buttons: [
        //     {
        //         text: 'Cancel',
        //         role: 'cancel',
        //         cssClass: 'secondary',
        //         handler: (blah) => {
        //             console.log('Confirm Cancel: blah');
        //         }
        //     }, {
        //         text: 'Yes',
        //         handler: () => {
                
        //             this.deletePhone();
                
        //         }
        //     }
        //     ]
        // });
        
        // await alert.present();
    }

    deletePhone(){
        this.storage.get('user').then((val) => {
            if (val != null) {
                this.serviceForAllService.showLoader("Removing...");
                this.serviceForAllService.deletePhoneNumber(val.token).subscribe((result) => {
                  this.serviceForAllService.dismissLoading();
                  this.presentToast("Phone number removed. Please update from your profile page.");
                  this.GetTwoFactorData();
                },(err)=>{
                    let msg = err.error.errormsg;
                    if(msg==''){
                      this.serviceForAllService.presentToast("something went wrong, please try again later.");
                    }else{
                      this.serviceForAllService.presentToast(msg);
                    }
                    this.serviceForAllService.dismissLoading();
                });
            }
        })
    }

}

import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-reactions',
  templateUrl: './reactions.page.html',
  styleUrls: ['./reactions.page.scss'],
})
export class ReactionsPage implements OnInit {

  isAddMore : boolean = false;

  constructor(public popoverCtrl: PopoverController) { }

    ngOnInit() {
    }

    ionViewDidLoad() {
        console.log('ionViewDidLoad ReactionsPage');
    }

    share(icon){

        console.log(icon);
        this.popoverCtrl.dismiss({smiley: icon});
    }

    addEmoji(event) {   
      console.log(event.data);
      this.popoverCtrl.dismiss({smiley: event.data});
  }

  addMore(){
    this.isAddMore = true;
  } 

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-shell',
  templateUrl: './image-shell.component.html',
  styleUrls: ['./image-shell.component.scss'],
})
export class ImageShellComponent implements OnInit {

  constructor() { 
    console.log("image shell");
  }

  ngOnInit() {}

}

import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, LoadingController, AlertController, Events, NavParams, ModalController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Router, ActivatedRoute } from '@angular/router';;

@Component({
  selector: 'app-generaterecipt2',
  templateUrl: './generaterecipt2.page.html',
  styleUrls: ['./generaterecipt2.page.scss'],
})
export class Generaterecipt2Page implements OnInit {

  seg_type: any = "users";
  result: any;
  userRes: any;
  data_res: any;
  event_id:any;
  favresult: any;
  user: any;
  type: any;
  userready: boolean = false;
  favready: boolean = false;
  userpage: any = 1;
  selected_users: any = [];
  users_name:any=[];

  selected_users_2: any = [];
  users_name_2:any=[];

  username:any='';
  constructor(public events: Events,
    public navCtrl: NavController,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    navParams: NavParams,
    public serviceForAllService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController) {
    this.selected_users = navParams.get('selected_users');
    console.log(this.selected_users);
    this.storage.get('user').then((val) => {
      console.log('userData: ', val);
      if (val != null) {
        this.user = val;
        // this.serviceForAllService.SaveAutoConfiqure(this.user.token);
        this.GetEventbyID();
        this.get_users(this.username);
      }else{
        // this.storage.clear();
        this.router.navigate(['/login']);
      }
    },err=>{
      // this.storage.clear();
      this.router.navigate(['/login']);
    });

  }

  ngOnInit() {
  }


  get_users(username) {
    this.userready=false;
    this.username=username;
    this.userpage=1;
    this.serviceForAllService.getAllUser(this.user.token, this.userpage,this.username).subscribe((result) => {
      this.userRes = result;
      this.result = this.userRes.users;
      this.userready = true;
      this.result.forEach((u) => {
        this.users_name[u.user_id]=u.full_name;
      });
    }, (err) => {
      console.log(err);
    });
  }


  loadMore(event) {
    this.userpage++;
    this.serviceForAllService.getAllUser(this.user.token, this.userpage,this.username).subscribe((result) => {
      console.log(result);
      this.data_res = result;
      this.result = [...this.result, ...this.data_res.users];
      if(this.data_res.users.length==0){
        this.userpage--;
      }
      this.result.forEach((u) => {
        this.users_name[u.user_id]=u.full_name;
      });
      event.target.complete();
    }, (err) => {
      console.log(err);
      event.target.complete();
    });
  }

  get_fav() {
    this.serviceForAllService.getfavorites(this.user.token).subscribe((result) => {
      this.favresult = result;
      this.favready = true;
    }, (err) => {
      console.log(err);
    });
  }
 
  insertUserToArray(ev) {
    console.log('Segment changed', ev);
    if (ev.detail.checked == false) {
      //this.selected_users.splice(ev.detail.value, 1); 
      delete this.selected_users[ev.detail.value];
      console.log(this.selected_users);

    }else{
      console.log(this.selected_users);
    }
  }


  ModalDismiss() {
   
    this.modalCtrl.dismiss({
      selected_users: this.selected_users,
      users_name:this.users_name
    });
  }

   GetEventbyID() {
    this.serviceForAllService.getData('GetEventbyID/?token=' + this.user.token + '&event_id=' + this.event_id).subscribe(data => {
      let rs: any = [];
      rs = data;
      if (rs.status = 'ok') {
        
        this.selected_users_2 = rs.user_ids_sel;
      }
    }, (err) => {

      //this.paynowReady = true;
      if (err.error.error_code == "user_expire") {
        // this.storage.clear();
        this.router.navigate(['/login']);
      }
      this.serviceForAllService.presentAlert(err.error.errormsg);

    })
  }


  


}


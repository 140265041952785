import { Component, OnInit, Input, ChangeDetectorRef, NgZone } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Events, ModalController, IonContent, AlertController, MenuController, NavController } from '@ionic/angular';
import { SigninSignupPage } from '../../signin-signup/signin-signup.page';
import { ServiceForAllService } from '../../service-for-all.service';
import { FeedbackPage } from '../../feedback/feedback.page';
import { LanguagePage } from '../../language/language.page';
import { ContactusModalPage } from '../../contactus-modal/contactus-modal.page';
import { AddNewStoryPage } from '../../social-media/pages/add-new-story/add-new-story.page';
import * as firebase from 'firebase';
import { AboutusPage } from 'src/app/aboutus/aboutus.page';
import { ContactUsPage } from 'src/app/contact-us/contact-us.page';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() tab_name: any;
    @Input() login_user_id: any;
    @Input() app_type: any;
    @Input() url: any;
    loginInfo: any = [];
    user_menu: string = "off";
    activity_menu: string = "off";
    search_user: string = "off";
    public buttonClicked: boolean = false;
    isGuestUser: any = '';
    onlineRef: any;

    notification_spinner: string = 'on';
    weekNotifications: any = [];
    monthNotifications: any = [];

    //Filter
    searchQuery: any;
    advanceFilters: any = [];
    name_keywords: string = '';

    tag_handle_keywords: string = '';
    tag_handles: any = [];

    names: any = [];
    className: string = "";

    country_spinner: string = 'off';
    country_lists: any = [];
    country_keywords: string = '';
    countries: any = [];

    state_spinner: string = 'off';
    states: any = []; // selected states
    state_keywords: string = '';
    state_lists: any = []; //main Variable

    city_spinner: string = 'off';
    cities: any = [];
    city_keywords: string = '';
    city_lists: any = [];

    zip_keywords: string = '';
    zips: any = [];

    special_categories: any = [];
    special_category_lists: any = '';
    special_category_keywords: string = '';

    //
    constructor(
        public storage: Storage,
        public router: Router,
        public events: Events,
        public alertController: AlertController,
        public modalController: ModalController,
        public menu: MenuController,
        public serviceForAllService: ServiceForAllService,
        public ref: ChangeDetectorRef,
        private navCtrl: NavController,
        public ngZone: NgZone,
        public alertCtrl: AlertController,

    ) {

        // this.menu.open();
        this.storage.get('countries').then((response) => {
            console.log('response==>>>>>>>', response)
            if (response != null) {
                response.forEach((element, index) => {
                    response[index].is_searched = false;
                });
                this.country_lists = response;
            } else {
                this.getCountries();
            }
        });


        this.events.subscribe('refLanguage', (user) => {
            this.loginInfo = user;
        });


        this.events.subscribe('profile_updated', (check) => {
            // this.storage.get('user').then((val) => {
            //     console.log('header[userData]:==', val);
            //     if (val != null) {
            //         this.loginInfo = val;
            //         this.login_user_id = this.loginInfo.user_id;
            //         this.ref.detectChanges();
            //     }

            // });
            this.loginInfo.user_avatar = check.user_avatar;
        });

        console.log('constructor->login_user_id1:', this.login_user_id);
        console.log('constructor->tab_name:', this.tab_name);
        this.storage.get('user').then((val) => {
            console.log('header->userData: ', val);
            if (val != null) {
                this.loginInfo = val;
                this.login_user_id = this.loginInfo.user_id;
            }
        });

    }
    public onButtonClick() {
        this.buttonClicked = !this.buttonClicked;
        console.log('this.buttonClicked', this.buttonClicked)
    }

    ngOnInit() {
        console.log('url', this.url)
        console.log('ngOnInit->login_user_id1:', this.login_user_id);
        console.log('ngOnInit->tab_name:', this.tab_name);

        setTimeout(() => {
            this.storage.get('user').then(user => {
                if (user) {
                    this.loginInfo = user;
                    this.login_user_id = user.user_id;
                    this.get_notifications();
                    this.ref.detectChanges();
                }
            });
        }, 4000)

        this.storage.get('user').then(user => {
            if (user) {
                this.login_user_id = user.user_id;
                this.ref.detectChanges();
            }
        });
    }

    get_notifications() {
        let sendData = {
            token: this.loginInfo.token,
        }

        this.notification_spinner = 'on';
        console.log("sendData:", sendData);
        this.serviceForAllService.sendData('get_activity_notification', sendData).subscribe((response: any) => {
            console.log('activity:response:', response);
            this.notification_spinner = 'off';
            if (response['status'] == 'ok') {
                this.weekNotifications = response.weekNotifications;
                this.monthNotifications = response.monthNotifications;
            }
            // this.cdRef.detectChanges();
        }, (err) => {
            this.notification_spinner = 'off';
            console.log("err:", err);
        });
    }

    async open_signin_signup_model(page_type) {
        const modal = await this.modalController.create({
            component: SigninSignupPage,
            cssClass: 'loginmodal',
            componentProps: {
                page_type: page_type,
            }
        });
        return await modal.present();
    }

    async open_feedback_model() {
        this.show_hide_user_menu();
        const modal = await this.modalController.create({
            component: FeedbackPage,
            backdropDismiss: false,
            cssClass: 'feedsmodal',
            componentProps: {}
        });
        return await modal.present();
    }

    async open_contactus_model() {
        this.show_hide_user_menu();
        const modal = await this.modalController.create({
            component: ContactusModalPage,
            backdropDismiss: false,
            cssClass: 'Contactusmodal',
            componentProps: {}
        });
        return await modal.present();
    }

    async open_language_model() {
        this.show_hide_user_menu();
        const modal = await this.modalController.create({
            component: LanguagePage,
            backdropDismiss: false,
            cssClass: 'languagemodal',
            componentProps: {}
        });
        return await modal.present();
    }

    goto_page(page_slug) {
        if (this.user_menu == 'on') {
            this.user_menu = 'off';
        } else {
            // this.user_menu = 'on';
        }
        this.router.navigate([page_slug]);
    }

    goto_page_message(){
        this.router.navigate(['/message-chat']);
    }

    goto_social_profile() {
        this.router.navigate(['/social-user-profile/' + this.login_user_id]);
    }

    show_hide_user_menu() {
        if (this.activity_menu == 'on') {
            this.activity_menu = 'off';
        }
        if (this.search_user == 'on') {
            this.search_user = 'off';
        }
        if (this.user_menu == 'on') {
            this.user_menu = 'off';
        } else {
            this.user_menu = 'on';
        }
    }

    show_hide_activity_menu() {
        if (this.user_menu == 'on') {
            this.user_menu = 'off';
        }
        if (this.search_user == 'on') {
            this.search_user = 'off';
        }
        if (this.activity_menu == 'on') {
            this.activity_menu = 'off';
        } else {
            this.activity_menu = 'on';
        }
    }

    show_hide_user_search() {
        if (this.user_menu == 'on') {
            this.user_menu = 'off';
        }

        if (this.activity_menu == 'on') {
            this.activity_menu = 'off';
        }

        if (this.search_user == 'on') {
            this.search_user = 'off';
        } else {
            this.search_user = 'on';
        }

    }

    async DoLogout() {
        console.log("DoLogout");
        if (this.isGuestUser == 1) {
            this.storage.remove('guestuser');
            this.events.publish('isGuest', 0);
            this.router.navigateByUrl('/landing');
        } else {
            const alert = await this.alertController.create({
                header: "Logout",
                // subHeader: 'Subtitle',
                cssClass: 'alertCancel',
                message: '<img src="assets/question.png" class="question" /> <img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
                buttons: [{
                    text: '',
                    handler: () => {
                        console.log("Logout confirmed");
                        this.storage.get('user').then((val) => {
                            if (val != null) {
                                let user_id = val.user_id;
                                this.serviceForAllService.removeToken(user_id);

                                //checking on firebase
                                this.onlineRef = firebase.database().ref('online_users/' + val.user_id);
                                if (this.onlineRef) {
                                    // this.onlineRef.remove();

                                    this.onlineRef.once('value', (res: any) => {
                                        if (res.val() == null || res.val().online_status == 'online') {
                                            this.onlineRef.update({ 'online_status': 'offline' });
                                        }
                                    });
                                }
                            }

                        });

                        this.serviceForAllService.firebaseSignOut();
                        this.storage.remove('guestuser');
                        this.storage.remove('user');
                        this.storage.remove('cart');
                        this.storage.remove('cid');
                        this.storage.remove('user_profile');
                        // this.storage.clear();
                        this.menu.enable(false);
                        this.events.publish('userCheck:created', 'userNotLogin');
                        this.navCtrl.navigateRoot('/landing')
                    }
                }, {
                    text: '',
                    role: 'cancel',
                    handler: () => {
                        console.log("Logout cancelled");
                    }
                }],
            });
            await alert.present();
        }
    }

    async add_new_story() {
        console.log("add_new_story");
        const modal = await this.modalController.create({
            component: AddNewStoryPage,
            componentProps: {

            },
            cssClass: 'video_popup_modal_css'
        });

        // modal.onDidDismiss().then((res) => {
        //   this.modal_response = res;
        //   console.log("modal_response: "+JSON.stringify(this.modal_response));
        // });

        return await modal.present();
    }

    add_post() {
        this.router.navigate(['/add-new-post']);
    }

    async aboutUs() {
        console.log("aboutUs");
        const modal = await this.modalController.create({
            component: AboutusPage,
            componentProps: {

            },
            cssClass: 'video_popup_modal_css'
        });
        return await modal.present();
    }

    async contactUs() {
        console.log("aboutUs");
        const modal = await this.modalController.create({
            component: ContactUsPage,
            componentProps: {

            },
            cssClass: 'video_popup_modal_css'
        });
        return await modal.present();
    }


    add_grapevine() {
        this.router.navigate(['/create-grapevine']);
    }

    home() {
        console.log('Hello')
        this.router.navigate(['/landing']);
    }

    abourUs() {
        this.router.navigate(['/aboutus']);
    }

    feeds() {
        this.router.navigate(['/feeds']);
    }
    getSocial() {
        this.router.navigate(['/social-media-dashboard']);
    }
    goto_wallet() {
        this.router.navigate(['/wallet']);
    }
    storeRequest() {
        this.router.navigate(['/store-request']);
    }

    goto_activity() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                event_type: 'activity'
            }
        };
        this.router.navigate(['activity'], navigationExtras);
    }

    goto_user_profile(item) {
        this.router.navigate(['/social-user-profile/' + item.userInfo.user_id]);
    }

    async handle_the_follow_request(item, status) {

        let i = this.weekNotifications.findIndex(element => element.id == item.id);
        let j = this.monthNotifications.findIndex(element => element.id == item.id);

        const alert = await this.alertCtrl.create({
            header: (status == 'accepted') ? 'Accept' : 'Reject',
            // subHeader: 'Subtitle',
            cssClass: 'alertCancel',
            message: '<img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
            buttons: [{
                text: '',
                handler: () => {
                    this.ngZone.run(() => {
                        let sendData = {
                            token: this.loginInfo.token,
                            second_user_id: item.userInfo.user_id,
                            status: status,
                        };
                        this.serviceForAllService.showLoader();
                        this.serviceForAllService.sendData('handle_the_follow_request', sendData).subscribe(response => {
                            console.log("response:", response);
                            if (response['status'] == 'ok') {
                                this.serviceForAllService.dismissLoading();
                                if (status == 'accepted') {
                                    if (i > -1) {
                                        this.weekNotifications[i].userInfo.is_follower = response['is_follower'];
                                    }
                                    if (j > -1) {
                                        this.monthNotifications[j].userInfo.is_follower = response['is_follower'];
                                    }
                                } else if (status == 'rejected') {
                                    if (i > -1) {
                                        this.weekNotifications.splice(i, 1);
                                    }
                                    if (j > -1) {
                                        this.weekNotifications.splice(j, 1);
                                    }
                                }
                            } else {
                                this.serviceForAllService.presentToast(response['msg']);
                            }
                        }, (err) => {
                            console.log("err:", err);
                            this.serviceForAllService.dismissLoading();
                            this.serviceForAllService.presentAlert(err.error.msg);
                            if (err.error_code == "token_expired") {
                                this.storage.clear();
                                this.router.navigate(["/login"]);
                            }
                        });
                    });
                }
            },
            {
                text: '',
                role: 'cancel',
                handler: () => {

                }
            }],
        });
        await alert.present();
    }

    send_follow_request(index, item) {
        let sendData = {
            token: this.loginInfo.token,
            second_user_id: item.userInfo.user_id,
            status: 'pending',
        };
        this.serviceForAllService.showLoader();
        this.serviceForAllService.sendData('send_follow_request', sendData).subscribe(response => {
            console.log("response:", response);
            if (response['status'] == 'ok') {
                this.serviceForAllService.dismissLoading();
                let i = this.weekNotifications.findIndex(element => element.id == item.id);
                let j = this.monthNotifications.findIndex(element => element.id == item.id);
                if (i > -1) {
                    this.weekNotifications[index].userInfo.is_following = response['is_following'];
                }
                if (j > -1) {
                    this.monthNotifications[index].userInfo.is_following = response['is_following'];
                }
            } else {
                this.serviceForAllService.presentToast(response['msg']);
            }
        }, (err) => {
            console.log("err:", err);
            this.serviceForAllService.dismissLoading();
            this.serviceForAllService.presentAlert(err.error.msg);
            if (err.error_code == "token_expired") {
                this.storage.clear();
                this.router.navigate(["/login"]);
            }
        });
    }

    async cancel_unfollow(index, status, item) {
        const alert = await this.alertCtrl.create({
            header: status.charAt(0).toUpperCase() + status.slice(1),
            // subHeader: 'Subtitle',
            cssClass: 'alertCancel',
            message: '<img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
            buttons: [{
                text: '',
                handler: () => {
                    this.ngZone.run(() => {
                        let sendData = {
                            token: this.loginInfo.token,
                            second_user_id: item.userInfo.user_id,
                            status: status,
                        };
                        this.serviceForAllService.showLoader();
                        this.serviceForAllService.sendData('cancel_unfollow', sendData).subscribe(response => {
                            console.log("response:", response);
                            if (response['status'] == 'ok') {
                                this.serviceForAllService.dismissLoading();
                                let i = this.weekNotifications.findIndex(element => element.id == item.id);
                                let j = this.monthNotifications.findIndex(element => element.id == item.id);
                                if (i > -1) {
                                    this.weekNotifications[index].userInfo.is_following = response['is_following'];
                                }
                                if (j > -1) {
                                    this.monthNotifications[index].userInfo.is_following = response['is_following'];
                                }
                            } else {
                                this.serviceForAllService.presentToast(response['msg']);
                            }
                        }, (err) => {
                            console.log("err:", err);
                            this.serviceForAllService.dismissLoading();
                            this.serviceForAllService.presentAlert(err.error.msg);
                            if (err.error_code == "token_expired") {
                                this.storage.clear();
                                this.router.navigate(["/login"]);
                            }
                        });
                    });
                }
            },
            {
                text: '',
                role: 'cancel',
                handler: () => {

                }
            }],
        });
        await alert.present();
    }


    gotoAllUser() {
        if (this.searchQuery == null || this.searchQuery == undefined || this.searchQuery.trim() == "" ) {
            return false;
        }

        let kw = this.searchQuery;
        this.searchQuery = '';

        let searchData = {
            searchKeyword: kw
        };

        let navigationExtras: NavigationExtras = {
            queryParams: searchData
        };
        this.router.navigate(['/feeds'], navigationExtras);
    }

    add_filters(field_name, value, item) {
        console.log('field_name', field_name);
        console.log('value', value);
        console.log('item', item)
        value = value.toLowerCase();
        if (!value || value == undefined || value == '') {
            return false;
        }
        let row = {
            key: field_name,
            value: value,
        };

        if (field_name == 'countries') {
            row['value'] = item.name;
            row['flag'] = item.emoji;
            row['country_code'] = value;
        }
        if (field_name == 'states') {
            row['value'] = item.name;
            row['state_code'] = value; item.iso2;
        }

        this.advanceFilters.push(row);
        document.querySelectorAll("." + field_name + "").forEach(element => { (element as HTMLInputElement).value = ''; })

        console.log("advanceFilters:", this.advanceFilters);
    }

    remove_fields(index) {
        console.log('index', index)
        this.advanceFilters.splice(index, 1);
    }


    search_country() {
        console.log("country_keywords:", this.country_keywords);
        this.country_lists.forEach((element, index) => {
            let is_matched = element.name.toLowerCase().includes(this.country_keywords.toLowerCase());
            this.country_lists[index].is_searched = is_matched;
        });
    }

    getCountries() {
        let sendData = {}
        this.country_spinner = 'on';
        this.serviceForAllService.get_countries(sendData).subscribe((response) => {
            console.log("response:", response);
            this.country_spinner = 'off';
            if (response['status'] == 'ok') {
                this.countries = response['countries'];
                this.countries.forEach((element, index) => {
                    console.log(index + ":", element);
                    this.countries[index].is_searched = false;
                });
            } else {
                this.serviceForAllService.presentToast(response['msg']);
            }
        }, (err) => {
            this.country_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);

        })
    }

    // advanceFilters
    get_states() {
        let sendData = {
            state_keywords: this.state_keywords,
            country_code: this.advanceFilters[0]['country_code']

        }
        console.log("SendData", sendData);
        this.state_spinner = 'on';
        this.serviceForAllService.get_states(sendData).subscribe((response) => {
            console.log("response:", response);
            this.state_spinner = 'off';
            if (response['status'] == 'ok') {
                this.state_lists = response['states'];
                console.log("states:", this.states);
            } else {
                this.serviceForAllService.presentToast(response['msg']);
            }
        }, (err) => {
            this.state_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);

        })
    }

    get_cities() {
        console.log("city_keywords:", this.city_keywords);
        let sendData = {
            country_code: this.advanceFilters[0]['country_code'],
            state_code: this.advanceFilters[0]['name'],
            city_keywords: this.city_keywords,
        }
        console.log("SendData", sendData);
        this.city_spinner = 'on';
        this.city_lists = []
        this.serviceForAllService.get_cities(sendData).subscribe((response) => {
            console.log("response:", response);
            this.city_spinner = 'off';
            if (response['status'] == 'ok') {
                this.city_lists = response['cities'];
                console.log("city_lists:", this.city_lists);
            } else {
                this.serviceForAllService.presentToast(response['msg']);
            }
        }, (err) => {
            this.city_spinner = 'off';
            console.log("err:", err);
            this.serviceForAllService.presentAlert(err.error.msg);

        })
    }

    cancel() {
        this.advanceFilters = [];
        this.search_user = 'off';
        // this.popoverCtrl.dismiss({ advanceFilters: this.advanceFilters });
    }

    searchStart() {
        console.log('this.searchQuery', this.searchQuery);
        if (this.searchQuery == null || this.searchQuery == undefined || this.searchQuery.trim() == ""){
            return false;
            
        }
        let kw = this.searchQuery;
        this.searchQuery = '';
        this.tag_handle_keywords = '';
        this.name_keywords = '';
        this.country_keywords = '';
        this.state_keywords = '';
        this.city_keywords = '';
        this.zip_keywords = '';
        this.special_category_lists = '';
        this.special_category_keywords = '';
        console.log("filters:", this.advanceFilters);
        this.search_user = 'off';
        let navigationExtras: NavigationExtras = {
            queryParams: {
                searchKeyword: kw,
                advanceFilters: JSON.stringify(this.advanceFilters)
            }
        };
        this.router.navigate(['/feeds'], navigationExtras);
        // this.popoverCtrl.dismiss({ advanceFilters: this.advanceFilters });
    }

    clear_all() {
        this.tag_handle_keywords = '';
        this.name_keywords = '';
        this.country_keywords = '';
        this.state_keywords = '';
        this.city_keywords = '';
        this.zip_keywords = '';
        this.special_category_lists = '';
        this.special_category_keywords = '';
        this.advanceFilters = [];
    }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { NewMessagePage } from './new-message.page';
import { Ionic4EmojiPickerModule } from 'ionic4-emoji-picker';
import { TranslateModule} from '@ngx-translate/core';
import { ComponentsModule } from '../components/components.module';
const routes: Routes = [
  {
    path: '',
    component: NewMessagePage
  }
];

@NgModule({
  imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ComponentsModule,
        Ionic4EmojiPickerModule,
        RouterModule.forChild(routes),
        TranslateModule,
  ],
  declarations: [NewMessagePage]
})
export class NewMessagePageModule {}

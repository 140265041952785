import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(
        public storage: Storage,
        public router: Router
    ) {

    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
            
        return this.storage.get('user').then(res => {
            console.log("canLoad->loginInfo:", res);
            
            const navigation = this.router.getCurrentNavigation();
            let url = '/';
            if (navigation) {
                url = navigation.extractedUrl.toString();
                console.log("url:", url);
                let is_match = url.search("access_token");
                console.log("is_match:", is_match);

                if(is_match >= 0) {
                    if (res != null) {
                        this.router.navigate(['/dashboard']);
                    } else {
                        return true;
                    }
                }else {
                   
                    if (res != null) {
                        this.router.navigate(['/dashboard']); 
                    } else {
                        return true;
                    }
                }
            }else{
                this.storage.remove('user');
                this.router.navigate(['/dashboard']);
            }
        });
    }
}

import { Component, OnInit, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController, NavParams, NavController, LoadingController, AlertController, Events, ToastController } from '@ionic/angular';
import { ServiceForAllService } from '../service-for-all.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { PasswordValidator } from '../validators/password.validator';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.page.html',
  styleUrls: ['./confirm-account.page.scss'],
})
export class ConfirmAccountPage implements OnInit {
    loginInfo : any = [];
    card:any = [];  
    plan_id:any;

  constructor(
    public events: Events,
    public navCtrl: NavController,
    public storage: Storage,
    public router: Router,
    public serviceForAllService: ServiceForAllService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public zone: NgZone,
    public modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
    this.card = this.navParams.get('card');
    this.plan_id = this.navParams.get('plan_id');

    console.log("card:: ", this.card);

    this.storage.get('user').then((val) => {
        if (val != null) {
            this.loginInfo = val;
            console.log("loginInfo:", this.loginInfo);
        }
    });
  }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.storage.get('user').then((val) => {
        if (val != null) {
            this.loginInfo = val;
            console.log("loginInfo:", this.loginInfo);
        }
    });
}


  async confirmPay__(card_id){
    const alert = await this.alertCtrl.create({
        header: 'Continue payment with the selected card?',
        // subHeader: 'Subtitle',
        cssClass: 'alertCancel', 
        message: '<img src="assets/question.png" class="question" /> <img src="assets/w-check.png" /> <img src="assets/w-cancel.png"/>',
        buttons: [{
            text: '',
            handler: () => {
                // this.subscribeMembership(card_id);
            }
        },
        {
            text: '',
            role: 'cancel',
            handler: () => {

            }
        }],
    });
    await alert.present();
}



confirmPay(card_id){

    console.log("card_id::: ", card_id);

    if(card_id && card_id!=''){
        this.storage.get('user').then((val) => {
            if (val != null) {
                this.loginInfo = val;
                console.log("loginInfo:", this.loginInfo);

                this.serviceForAllService.showLoader();
                let checkoutData = {
                    planid: this.plan_id,
                    token: this.loginInfo.token,
                    userid: this.loginInfo.user_id,
                    card_id: card_id,
                    action_type: 'custom'
                };

                console.log(checkoutData);
                this.serviceForAllService.SubscribePlan(checkoutData).subscribe((res) => {
                    this.serviceForAllService.dismissLoading();

                    this.events.publish('membershipRef', '1'); 
                    this.router.navigate(['/welcome-premium/paid']);

                    this.modalCtrl.dismiss();
                },(err)=>{
                    console.log(err);
                    this.serviceForAllService.dismissLoading();
                });

            }
        });
    }
    else{
        this.serviceForAllService.presentToast("Please to a card to make payment");
    }

    

  }

}
